import React, { useRef } from 'react';
import { MDBToast, MDBBtn } from 'mdb-react-ui-kit';
import { connect } from 'react-redux';

/**
 * This is a push notification that fades out automatically after a few seconds
 * Can also be manually X'd out by the user
 * Receives a string as props, and displays that string
 * Situated at bottom right of the screen
 */

const AlreadyPostedToast = (props) => {
    const ref = useRef();

    return (
        <>
            <MDBBtn id="already-posted-ref" className="d-none" color="primary" ref={ref}>Toast</MDBBtn>
            <MDBToast
                color={props.userInfo.userSettings.theme === 'default' ? 'light' : 'dark'}
                autohide
                position='bottom-right'
                delay={2000}
                appendToBody
                triggerRef={ref}
                headerContent={<i className="fas fa-image fa-lg text-success d-block me-auto"></i>}
                bodyContent={
                    <h5>That image has already been posted.</h5>
                }
                className="w-max-content max-w-100"
            />
        </>
    );
}
  
const mapStateToProps = (state) => {
    return {
        ...state
    }
  }
  
  export default connect(mapStateToProps, {  })(AlreadyPostedToast);
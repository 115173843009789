import React from 'react';
import { connect } from 'react-redux';
import { motion } from 'framer-motion';
import t from '../../../utilities/transitions';
import { 
    MDBCardFooter,
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBTooltip,
    MDBCardText,
    MDBCardTitle,
    MDBCardHeader,
    MDBSpinner,
    MDBBadge
} from 'mdb-react-ui-kit';
import axios from 'axios';
import h from '../../../utilities/helpers';
import Spinner from '../../../components/Spinner';
import Remove from '../../image/comments/Remove';
import Report from '../../image/comments/Report';
import { update_profile_comment, route } from '../../../redux/actions';
import { Link } from 'react-router-dom';

const itemsPerPage = 40;

class CommentPage extends React.Component{
    constructor(props){
        super();
        /**
         * this.page: Number - The page of the comments that the user is on
         */
        this.page = props.profileCommentsPage;
        this.state = {
            /**
             * commentsLoaded: Boolean - Whether the initial comment section load has completed
             * actionFormOpened: false | Number - The comment_id of the comment that has the report dropdown open, if any
             * commentsReported: Array - List of comments that have already been reported
             * commentsRestoring: Array - List of comments that are in the process of being restored
             * commentHover: Number - comment_id of quoted comment that the user is hovering over with their mouse
             * tooltipComment: JSX - Content of comment with comment_id state.commentHover
             */
            tooltipComment: <></>,
            commentHover: 0,
            commentsLoaded: false,
            commentsReported: [],
            commentsRestoring: [],
            actionFormOpened: 0
        }
    }

    componentDidMount(){
        this.setState({
            ...this.state,
            commentsLoaded: true
        });
    }

    /**
     * 
     * @param {Number} commentID - ref Comments.comment_id
     * 
     * Opens the report/remove form for the comment that was clicked
     */
    openActionForm = commentID => this.setState({
        ...this.state,
        actionFormOpened: (this.state.actionFormOpened === commentID) ? false : commentID
    });


    /**
     * 
     * @param {Number} commentID - ref Comments.comment_id
     * @param {Boolean} removed - Whether the comment has been removed
     * 
     * 
     * @returns JSX list of replies to that comment
     */
    renderCommentReplies = (commentID, removed) => {
        const commentReplies = this.props.profileComments.filter(comment => comment.comment_text.split(`##${commentID}`).length > 1);
        if (commentReplies.length) return (
            <>
                <p className={removed ? 'text-light' : ''}>Replies:</p>
                <p className="d-flex flex-wrap">{commentReplies.sort((a, b) => a.comment_id - b.comment_id).map(reply => (
                    <MDBTooltip 
                        tag="span" 
                        wrapperProps={{
                            className: "text-primary links-generic-light text-decoration-none cursor-pointer fw-bold me-2 text-nowrap d-block"
                        }}
                        title={this.state.tooltipComment}
                        onShow={() => this.renderTooltipComment(Number(reply.comment_id))}
                        className="comment-tooltips ms-2"
                        id={`tooltip-comment-${reply.comment_id}`}
                    ><Link to={`/comment/${reply.comment_id}`} onClick={e => this.clickQuotedComment(e, reply.comment_id)}>##</Link><span style={{cursor: 'text'}}>{reply.comment_id}</span></MDBTooltip>
                ))}</p>
            </>
        );
        else return <></>
    }

    /**
     * 
     * @param {Number} commentID - ref Comments.comment_id
     * 
     * Restores a comment that was removed
     * Updates in application state
     * Emits a socket event to update everyone else on the page
     */
    restoreComment = commentID => {
        if (this.state.commentsRestoring.indexOf(commentID) === -1) this.setState({
            ...this.state,
            commentsRestoring: [
                ...this.state.commentsRestoring,
                commentID
            ]
        }, () => axios.post('/support/restore/comment', {
            commentID: commentID,
            user: this.props.profileInfo.username
        }).then(res => {
            this.props.update_profile_comment(res.data.comment);
            this.props.socket.emit('mod-action');
            this.setState({
                ...this.state,
                commentsRestoring: this.state.commentsRestoring.filter(comment => comment !== commentID)
            });
        }).catch(err => this.setState({
            ...this.state,
            commentsRestoring: this.state.commentsRestoring.filter(comment => comment !== commentID)
        }, () => {
            console.log(err);
            alert('An error occurred. Please try again later.');
        })));
    }

    /**
     * 
     * @param {Number} postNumber - ref Comments.comment_id
     * 
     * Renders a comment that was quoted with the double hashes (##)
     * If the comment is in the comment section, pull the comment data from state and render
     * Else, render a spinner while the comment data is fetched from the server
     */
    renderTooltipComment = postNumber => {
        let comment = this.props.profileComments.find(comment => comment.comment_id === postNumber);
        if (comment) this.setState({
            ...this.state,
            commentHover: postNumber,
            tooltipComment: <>
                {comment.removed && !h.checkJanny(this.props.userInfo) ?
                <motion.div transition={t.transition} exit={t.fade_out_minimize} animate={t.normalize} initial={t.fade_out_minimize}>
                    <MDBCard className={`mx-auto ${(comment.removed) ? 'card-removed' : ''}`}>
                        <MDBCardBody className="d-flex">
                            <MDBCardText className={`messages text-break ${(comment.removed) ? 'text-light' : ''}`}>Comment Removed</MDBCardText>
                        </MDBCardBody>
                    </MDBCard>
                </motion.div> :
                <motion.div transition={t.transition} exit={t.fade_out_minimize} animate={t.normalize} initial={t.fade_out_minimize}>
                    <MDBCard className={`mx-auto ${(comment.removed) ? 'card-removed' : ''}`}>
                        <MDBCardHeader className="d-flex align-items-end">
                            <MDBCardTitle style={{maxWidth: '80%'}} className={`mb-0 ${(comment.removed) ? 'text-light' : ''}`}>
                            {comment.badge ? this.getBadge(comment) : 
                            <>
                                <span className={comment.removed ? "text-light" : "text-secondary"}>{comment.name}</span> <span className="text-nowrap me-2">(ID: <span className="text-light" style={{backgroundColor: `var(${comment.poster_css_color})`}}>{comment.poster_id}</span>)</span>
                            </>
                            }
                            <span className="mb-0 text-nowrap">{h.makeDateHR(new Date(comment.timestamp))} at {h.getTimeHR(new Date(comment.timestamp))}</span>
                            </MDBCardTitle>
                            <MDBCardTitle className={`ms-2 mb-0 text-pkmn ${(comment.removed) ? 'text-light' : ''}`}>#{comment.comment_id}</MDBCardTitle>    
                        </MDBCardHeader>
                        <MDBCardBody className="d-flex">
                            {comment.avatar ? 
                            <div style={{cursor: 'pointer'}} className="d-flex justify-content-center align-items-center square-8 mx-2">
                                <div className="fit-images" style={{backgroundImage: `url("/api/image-id/${comment.avatar}")`}}></div>
                            </div> : <></>}
                            <MDBCardText className={`messages text-break ${(comment.removed) ? 'text-light' : ''}`}>{this.parseText_displayOnly(comment.comment_text)}</MDBCardText>
                        </MDBCardBody>
                    </MDBCard>
                </motion.div>}
            </>
        });
        else this.setState({
            ...this.state,
            commentHover: postNumber,
            tooltipComment: <>
                <motion.div transition={t.transition} exit={t.fade_out_minimize} animate={t.normalize} initial={t.fade_out_minimize}>
                    <MDBCard className="mx-auto">
                        <MDBCardBody className="d-flex justify-content-center align-items-center">
                            <MDBSpinner color="success" grow size="lg"></MDBSpinner>
                        </MDBCardBody>
                    </MDBCard>
                </motion.div>
            </>
        }, () => axios.get(`/api/comment/${postNumber}`).then(res => {
            comment = res.data.comment;
            if (this.state.commentHover === postNumber) this.setState({
                ...this.state,
                tooltipComment: <>
                    {comment.removed && !h.checkJanny(this.props.userInfo) ?
                    <motion.div transition={t.transition} exit={t.fade_out_minimize} animate={t.normalize} initial={t.fade_out_minimize}>
                        <MDBCard className={`mx-auto ${(comment.removed) ? 'card-removed' : ''}`}>
                            <MDBCardBody className="d-flex">
                                <h5 className="text-center text-light p-0">Comment removed</h5>
                            </MDBCardBody>
                        </MDBCard>
                    </motion.div> :
                    <motion.div transition={t.transition} exit={t.fade_out_minimize} animate={t.normalize} initial={t.fade_out_minimize}>
                        <MDBCard className={`mx-auto ${(comment.removed) ? 'card-removed' : ''}`}>
                            <MDBCardHeader className="d-flex align-items-end">
                                <MDBCardTitle className={`mb-0 ${(comment.removed) ? 'text-light' : ''}`}>
                                {comment.badge ? this.getBadge(comment) : 
                                <>
                                    <span className={comment.removed ? "text-light" : "text-secondary"}>{comment.name}</span> (ID: <span className="text-light" style={{backgroundColor: `var(${comment.poster_css_color})`}}>{comment.poster_id}</span>)
                                </>
                                }
                                <span className="mb-0 ms-2">{h.makeDateHR(new Date(comment.timestamp))} at {h.getTimeHR(new Date(comment.timestamp))}</span>
                                </MDBCardTitle>
                                <MDBCardTitle className={`ms-2 mb-0 text-pkmn ${(comment.removed) ? 'text-light' : ''}`}>#{comment.comment_id}</MDBCardTitle>    
                            </MDBCardHeader>
                            <MDBCardBody className="d-flex">
                                {comment.avatar ? 
                                <div style={{cursor: 'pointer'}} className="d-flex justify-content-center align-items-center square-8 mx-2">
                                    <div className="fit-images" style={{backgroundImage: `url("/api/image-id/${comment.avatar}")`}}></div>
                                </div> : <></>}
                                <MDBCardText className={`messages text-break ${(comment.removed) ? 'text-light' : ''}`}>{this.parseText_displayOnly(comment.comment_text)}</MDBCardText>
                            </MDBCardBody>
                        </MDBCard>
                    </motion.div>}
                </>
            })
        }).catch(err => {
            console.log(err);
            if (this.state.commentHover === postNumber){
                if (err.response.status === 404) this.setState({
                    ...this.state,
                    tooltipComment: <>
                        <motion.div transition={t.transition} exit={t.fade_out_minimize} animate={t.normalize} initial={t.fade_out_minimize}>
                            <MDBCard className="mx-auto">
                                <MDBCardBody className="d-flex justify-content-center align-items-center">
                                    <h5 className="text-center">Comment does not exist</h5>
                                </MDBCardBody>
                            </MDBCard>
                        </motion.div>
                    </>
                });
                else this.setState({
                    ...this.state,
                    tooltipComment: <>
                        <motion.div transition={t.transition} exit={t.fade_out_minimize} animate={t.normalize} initial={t.fade_out_minimize}>
                            <MDBCard className="mx-auto">
                                <MDBCardBody className="d-flex justify-content-center align-items-center">
                                    <h5 className="text-center">An error occurred while fetching the comment</h5>
                                </MDBCardBody>
                            </MDBCard>
                        </motion.div>
                    </>
                });
            }
        }));
    }

    /**
     * 
     * @param {Event} e - Javascript click event 
     * @param {String} user - The username of the user that was clicked
     * 
     * Hit when a user is clicked
     * Override default behavior, route using redux route method
     */
    goToProfile = (e, user) => {
        e.preventDefault();
        this.props.route(`/u/${user}`);
    }

    /**
     * 
     * @param {Object} comment - Comments document
     * @returns The user's name, along with a badge to indicate their role
     */
    getBadge = comment => {
        switch(comment.badge){
            case 'Chadmin':
                return (
                    <span className="name-chadmin me-2">
                        <Link style={{color: 'var(--mdb-yellow)'}} onClick={e => this.goToProfile(e, comment.name)} to={`/u/${comment.name}`}>
                            {comment.name}
                        </Link>
                        <MDBBadge className="ms-2 badge-chadmin">
                            <div className="d-flex align-items-center">
                                <div style={{height: '1.6em', width: '1.6em'}} className="d-flex justify-content-center align-items-center">
                                    <div className="fit-images" style={{backgroundImage: `url("/assets/images/meltrans.png")`}}></div>
                                </div>
                                <p className="m-0">Chadmin</p>
                                {comment.oldfag ?
                                <div className="position-relative">
                                    <MDBBadge className="badge-oldfag" color='danger' notification pill>
                                        Oldfag
                                    </MDBBadge>
                                </div> : <></>}
                            </div>
                        </MDBBadge>
                        
                    </span>
                );
            case 'Janny':
                return (
                    <span className="name-janny me-2">
                        <Link style={{color: 'var(--mdb-green)'}} onClick={e => this.goToProfile(e, comment.name)} to={`/u/${comment.name}`}>
                            {comment.name}
                        </Link>
                        <MDBBadge className="ms-2 badge-janny">
                            <div className="d-flex align-items-center">
                                <div style={{height: '1.6em', width: '1.6em'}} className="d-flex justify-content-center align-items-center">
                                    <div className="fit-images" style={{backgroundImage: `url("/assets/images/thomastrans.png")`}}></div>
                                </div>
                                <p className="m-0">Janny</p>
                                {comment.oldfag ?
                                <div className="position-relative">
                                    <MDBBadge className="badge-oldfag" color='danger' notification pill>
                                        Oldfag
                                    </MDBBadge>
                                </div> : <></>}
                            </div>
                        </MDBBadge>
                    </span>
                );
            case 'Verified':
                return (
                    <span className="name-verified me-2">
                        <Link style={{color: 'rgb(28, 202, 255)'}} onClick={e => this.goToProfile(e, comment.name)} to={`/u/${comment.name}`}>
                            {comment.name}
                        </Link>
                        <MDBBadge className="ms-2 badge-verified">
                            <div className="d-flex align-items-center">
                                <div style={{height: '1.6em', width: '1.6em'}} className="d-flex justify-content-center align-items-center">
                                    <div className="fit-images" style={{backgroundImage: `url("/assets/images/verifiedlogotrans.png")`}}></div>
                                </div>
                                <p className="m-0">Verified</p>
                                {comment.oldfag ?
                                <div className="position-relative">
                                    <MDBBadge className="badge-oldfag" color='danger' notification pill>
                                        Oldfag
                                    </MDBBadge>
                                </div> : <></>}
                            </div>
                        </MDBBadge>
                    </span>
                );
            default:
                console.log('oob badge', comment.badge);
        }
    }

    /**
     * 
     * @param {Event} e - Javascript click event or similar
     * @param {Number} commentID - ref Comments.comment_id
     * 
     * Fired when the user clicks on a quoted comment
     * If the comment is not in the comment section, route to /comment/commentID
     * If the comment is in the comment section and in the document, scroll to it
     * If the comment is in the comment section and not in the document, switch to the appropriate page and scroll to it
     */
    clickQuotedComment = (e, commentID) => {
        e.preventDefault();
        window.location.hash = `#comment-${commentID}`;
        commentID = Number(commentID);
        document.getElementById(`tooltip-comment-${commentID}`).classList.add('d-none');
        const comment = this.props.profileComments.find(c => c.comment_id === commentID);
        console.log(comment);
        if (comment) this.props.goToComment(comment, this.page);
        else this.props.route(`/comment/${commentID}`);
    }

    /**
     * 
     * @param {String} text - The text of a comment
     * 
     * Parses the string for double hashes (##) which are used to quote other comments
     * Replaces them with links to other comments and tooltips with previews
     * 
     * @returns JSX array with parsed comment
     */
    parseText = text => {
        let returnArray = [];
        text.split('##').forEach(segment => {
            if (segment){
                let postNumber = '';
                let stop = false;
                segment.split('').forEach(character => {
                    if (!stop && h.isNumeric(character)) postNumber += character;
                    else stop = true;
                });
                if (postNumber){
                    returnArray.push(
                        <MDBTooltip 
                            tag="span" 
                            wrapperProps={{
                                className: "text-primary links-generic-light text-decoration-none cursor-pointer fw-bold"
                            }}
                            title={this.state.tooltipComment}
                            onShow={() => this.renderTooltipComment(Number(postNumber))}
                            className="comment-tooltips ms-2"
                            id={`tooltip-comment-${postNumber}`}
                        ><Link to={`/comment/${postNumber}`} onClick={e => this.clickQuotedComment(e, postNumber)}>##</Link><span style={{cursor: 'text'}}>{postNumber}</span></MDBTooltip>);
                    returnArray.push(
                        <span>{segment.split(postNumber).filter((piece, i) => i !== 0).join(postNumber)}</span>
                    );
                } else returnArray.push(
                    <span>{segment}</span>
                );
            }
        });
        return returnArray;
    }

    /**
     * 
     * @param {String} text - The text of a comment
     * 
     * Performs the same task as parseComment, but without the links or tooltips. Visual effects only.
     * 
     * @returns JSX array with parsed comment
     */
    parseText_displayOnly = text => {
        let returnArray = [];
        text.split('##').forEach(segment => {
            if (segment){
                let postNumber = '';
                let stop = false;
                segment.split('').forEach(character => {
                    if (!stop && h.isNumeric(character)) postNumber += character;
                    else stop = true;
                });
                if (postNumber){
                    returnArray.push(
                        <span className="text-primary links-generic-light text-decoration-none cursor-pointer fw-bold">{'##' + postNumber}</span>);
                    returnArray.push(
                        <span>{segment.split(postNumber).filter((piece, i) => i !== 0).join(postNumber)}</span>
                    );
                } else returnArray.push(
                    <span>{segment}</span>
                );
            }
        });
        return returnArray;
    }

    /**
     * 
     * @param {Number} commentID - ref Comments.comment_id
     * 
     * Adds the comment to the list of comments that have already been reported
     */
    setCommentReported = commentID => this.setState({
        ...this.state,
        commentsReported: [
            ...this.state.commentsReported,
            commentID
        ]
    });

    open = comment =>  window.open(`/comment/${comment.comment_id}`);

    render(){
        return (
            <motion.div className="pb-4" style={{overflowX: 'hidden'}} transition={t.transition} exit={this.props.contentExit} animate={t.normalize} initial={this.props.contentEnter}>
                {this.props.profileComments.length ?
                <>
                    {this.props.profileComments.sort((a, b) => b.comment_id - a.comment_id).filter((comment, i) => {
                        if ((i >= (this.page * itemsPerPage) - 1 - itemsPerPage) && (i < (this.page * itemsPerPage))) return true;
                        else return false;
                    }).map((comment, c) => (
                        <motion.div key={comment.comment_id} id={`comment-${comment.comment_id}`} transition={t.transition} exit={t.normalize} animate={t.normalize} initial={this.state.commentsLoaded || this.props.profileComments.length === 1 ? t.fade_out_right : t.normalize}>
                            <MDBCard className={`${c ? '' : 'mt-2'} mb-4 shadow-3-strong mx-2 ${(comment.removed) ? 'card-removed' : ''}`}>
                                <MDBCardHeader className="d-flex align-items-end">
                                    <MDBCardTitle style={{maxWidth: '80%'}} className={`mb-0 ${(comment.removed) ? 'text-light' : ''}`}>
                                    {comment.badge ? this.getBadge(comment) : 
                                    <>
                                        <span className={comment.removed ? "text-light" : "text-secondary"}>{comment.name}</span> <span className="text-nowrap me-2">(ID: <span className="text-light" style={{backgroundColor: `var(${comment.poster_css_color})`}}>{comment.poster_id}</span>)</span>
                                    </>
                                    }
                                    <span className="mb-0 text-nowrap">{h.makeDateHR(new Date(comment.timestamp))} at {h.getTimeHR(new Date(comment.timestamp))}</span>
                                    </MDBCardTitle>
                                    <MDBCardTitle onClick={() => this.props.clickCommentID(comment.comment_id)} className={`ms-2 mb-0 text-pkmn links-generic-light cursor-pointer ${(comment.removed) ? 'text-light' : ''}`}>#{comment.comment_id}</MDBCardTitle>    
                                </MDBCardHeader>
                                <MDBCardBody className="d-flex">
                                    {comment.avatar ? 
                                    <div style={{cursor: 'pointer'}} onClick={() => window.open(`/image/${comment.avatar}`)} className="d-flex justify-content-center align-items-center square-8 mx-2">
                                        <div className="fit-images" style={{backgroundImage: `url("/api/image-id/${comment.avatar}")`}}></div>
                                    </div> : <></>}
                                    <MDBCardText className={`messages text-break ${(comment.removed) ? 'text-light' : ''}`}>{this.parseText(comment.comment_text)}</MDBCardText>
                                </MDBCardBody>
                                <MDBCardFooter>
                                    {!comment.removed ?
                                    <div className="d-flex justify-content-between max-w-100 align-items-start">
                                        <div style={{maxWidth: '85%'}}>{this.renderCommentReplies(comment.comment_id)}</div>
                                        {h.checkJanny(this.props.userInfo) ?
                                        <div>
                                            <MDBBtn size="sm" onClick={() => this.openActionForm(comment.comment_id)} color="dark" className="d-block ms-auto">Remove</MDBBtn>
                                            <Remove profile={true} commentID={comment.comment_id} socket={this.props.socket} show={this.state.actionFormOpened === comment.comment_id} />
                                        </div> :
                                        <div>
                                            {this.state.commentsReported.indexOf(comment.comment_id) !== -1 ? 
                                            <motion.h5 transition={t.transition} exit={t.fade_out_minimize} animate={t.normalize} initial={t.fade_out_minimize} className="text-end text-danger">Comment Reported</motion.h5> :
                                            <>
                                                <MDBBtn size="sm" onClick={() => this.openActionForm(comment.comment_id)} color="dark" className="d-block ms-auto">Report</MDBBtn>
                                                <Report profile={true} setCommentReported={this.setCommentReported} commentID={comment.comment_id} show={this.state.actionFormOpened === comment.comment_id} />
                                            </>}
                                        </div>}
                                    </div> : 
                                    <>
                                        {h.checkJanny(this.props.userInfo) ?
                                        <div className="d-flex justify-content-between max-w-100 align-items-start">
                                            <div style={{maxWidth: '85%'}}>
                                                <h5 className="text-light messages">Removed: {comment.removed_reason === 'other' ? comment.removed_details : h.getRemovedReason(comment.removed_reason)}</h5>
                                                <div>{this.renderCommentReplies(comment.comment_id, true)}</div>
                                            </div>
                                            {this.state.commentsRestoring.indexOf(comment.comment_id) === -1 ?
                                            <MDBBtn size="sm" onClick={() => this.restoreComment(comment.comment_id)} style={{backgroundColor: 'var(--mdb-green)'}}>Restore Comment</MDBBtn> :
                                            <MDBBtn disabled size="sm" onClick={this.restoreComment} style={{backgroundColor: 'var(--mdb-green)'}}><Spinner size="sm" className="me-2"></Spinner>Restoring</MDBBtn>}
                                        </div> : <></>}
                                    </>}
                                </MDBCardFooter>
                            </MDBCard>
                        </motion.div>
                    ))}
                </> :
                <h4 className="text-center mt-4 display-6">Nobody has left any comments for {this.props.profileInfo.username}</h4>}
                
            </motion.div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
  }
  
  export default connect(mapStateToProps, { update_profile_comment, route })(CommentPage);
import React from 'react';
import { connect } from 'react-redux';
import { motion } from 'framer-motion';
import t from '../utilities/transitions';
import './about/about.css';
import { Link } from 'react-router-dom';
import { route } from '../redux/actions';

class About extends React.Component{
    constructor(){
        super();
        this.state = {

        }
    }

    openLink = (e, link) => {
        e.preventDefault();
        window.open(link, '_blank').focus();
    }

    routeRegular = (e, route) => {
        e.preventDefault();
        this.props.route(route);
    }

    render(){
        return (
            <motion.div className="min-h-100 container py-4" transition={t.transition} exit={t.fade_out} animate={t.normalize} initial={t.fade_out}>
                <h1 className="display-6 text-center">Rules</h1>
                <hr></hr>
                <ol>
                    <li><span className="fw-bold">No Porn.</span> Porn is defined as images of a sexual nature that have no artistic or other value. Lewd images that have comedic or other artistic value are allowed but should be marked "NSFW" in the upload options. Nude, barely clothed, or suggestive images of children are not allowed in any context.</li>
                    <li><span className="fw-bold">No Advertising.</span> There is no place to advertise your product on NanaImg.</li>
                    <li><span className="fw-bold">Do not Spam.</span> Spam involves making automated requests or flooding comment sections with repetitive or stupid messages.</li>
                    <li><span className="fw-bold">No Illegal Content.</span> Content that you put onto NanaImg must abide by all U.S. laws, especially copyright laws and laws against threats of violence.</li>
                    <li><span className="fw-bold">Do Not Post Copyrighted Content Unless You Are The Owner.</span> Valid DMCA requests will be fulfilled. Valid subpoenas will also be fulfilled, though the data NanaImg collects from you is not likely to result in your identification (see Privacy)</li>
                </ol>
                <h2 className="display-6 text-center">Privacy</h2>
                <hr></hr>
                <p className="text-center">NanaImg strives to provide users with easy-to-use services while maintaining the users' privacy. Nevertheless, the owner of NanaImg does not want the service to be used as a vehicle for heinous acts such as the posting of child pornography or the making of terroristic threats. To provide a balance between these two goals, the following measures are put in place:</p>
                <ul>
                    <li><span className="fw-bold">EXIF data is automatically removed from all images.</span> EXIF data is metadata attached to some images that can be used to determine where a picture was taken, the device that was used to take the picture, and other information. This data is immediately stripped from all images that are uploaded and never stored anywhere. The library used to do this is called <Link className="text-primary links-generic-light text-decoration-none cursor-pointer" to="https://github.com/joshbuddy/exif-be-gone" onClick={e => this.openLink(e, "https://github.com/joshbuddy/exif-be-gone")}>Exif be gone</Link></li>
                    <li><span className="fw-bold">NanaImg does not use Google Analytics or any other SEO scripts or software.</span> SEO, or Search Engine Optimization, scripts and software are used by most websites to track users and determine their demographics and other information for the purpose of targeting them for ads, and to determine which changes need to be made to the site in order to make it rank higher in various search engines.</li>
                    <li><span className="fw-bold">NanaImg does not use cookies to store any information other than session info and user settings.</span> Cookies are used by NanaImg only to keep track of who is logged in, and user preferences such as site theme and post badges. They do not track any personally identifying information, and the information that is stored is not shared with anyone without a subpoena.</li>
                    <li><span className="fw-bold">Request headers are retained for 24 hours after posting an image or comment.</span> Request headers contain minimal information about a user, such as their IP address, timestamp, browser, and operating system. These will be stored for 24 hours following the post of an image or comment, and will never be shared with anyone without a subpoena, or unless the user posts child pornography or terroristic threats (threatening to shoot up or bomb a public place, for instance). They will be primarily used to blacklist ips that abuse the site. They are automatically purged after 24 hours.</li>
                </ul>
                <h2 className="display-6 text-center">Navigation</h2>
                <hr></hr>
                <ul>
                    <li>Find any image on NanaImg by going to <span className="fw-bold">https://NanaImg.net/image/(image number)</span> or <span className="fw-bold">https://NanaImg.net/images/(image number)</span></li>
                    <li>Find any comment on NanaImg by going to <span className="fw-bold">https://NanaImg.net/comment/(comment number)</span> or <span className="fw-bold">https://NanaImg.net/comments/(comment number)</span></li>
                    <li>Find any user on NanaImg by username by going to <span className="fw-bold">https://NanaImg.net/u/(username)</span> or <span className="fw-bold">https://NanaImg.net/user/(username)</span></li>
                    <li>Find any user on NanaImg by user number by going to <span className="fw-bold">https://NanaImg.net/n/(user number)</span> or <span className="fw-bold">https://NanaImg.net/number/(user number)</span></li>
                    <li>NanaImg is a <Link className="text-primary links-generic-light text-decoration-none cursor-pointer" to="https://en.wikipedia.org/wiki/Single-page_application" onClick={e => this.openLink(e, "https://en.wikipedia.org/wiki/Single-page_application")}>Single Page Application</Link>, and image uploads take place in the background. This means that after clicking the Submit button, you can navigate to other pages on the site, and the upload will continue in the background.</li>
                    <li>NanaImg is a <Link className="text-primary links-generic-light text-decoration-none cursor-pointer" to="https://en.wikipedia.org/wiki/Progressive_web_app" onClick={e => this.openLink(e, "https://en.wikipedia.org/wiki/Progressive_web_app")}>Progressive Web App.</Link> This means that it can be installed as a mobile app without downloading it from any app store. When you visit the site on a mobile device, a service worker will cache all of the site files (this takes a few seconds on a good connection), then allow you to install the app. In most mobile browsers, you will receive a message at the bottom of the screen saying "Add NanaImg to Home Screen" or something similar. If this does not happen, tap the three dots in your mobile browser  <i className="fas fa-ellipsis-v"></i> then tap <span className="fw-bold">Install App</span></li>
                </ul>
                <h2 className="display-6 text-center">Legal</h2>
                <hr></hr>
                <p>If your copyrighted material is posted on NanaImg without your consent and you would like for it to be removed, please visit our <Link className="text-primary links-generic-light text-decoration-none cursor-pointer" to='/contact' onClick={e => this.routeRegular(e, '/contact')}>Contact Page</Link> and fill out the form, specifying "DMCA" as the subject, and properly identifying yourself and providing links to all infringing content, and provide proof that you are the owner of said content.</p>
            </motion.div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
  }
  
  export default connect(mapStateToProps, { route })(About);
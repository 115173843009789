import React from 'react';
import { connect } from 'react-redux';
import { motion } from 'framer-motion';
import Spinner from '../components/Spinner';
import { withRouter } from 'react-router-dom';
import t from '../utilities/transitions';
import { route, set_series } from '../redux/actions';
import {
    MDBContainer,
    MDBTooltip
} from 'mdb-react-ui-kit';
import axios from 'axios';

class Series extends React.Component{
    constructor(props){
        super();
        this.state = {
            /**
             * loaded: Boolean - Whether the initial data has loaded from the server
             * bbCopied: Array of Images._id - Images that have had their bbCode copied to the clipboard
             * directLinkCopied: Array of Images._id - Images that have had their direct link copied to the clipboard
             */
            loaded: props.series._id && props.series._id === props.match.params.id,
            bbCopied: [],
            directLinkCopied: []
        }
    }

    /**
     * Load the series
     * Set into state
     */
    componentDidMount(){
        if (!this.state.loaded) axios.get(`/api/series/${this.props.match.params.id}`).then(res => {
            this.props.set_series(res.data.series);
            this.setState({
                ...this.state,
                loaded: true
            });
        }).catch(err => {
            console.log(err, err.response, err.response.status);
            if (err.response.status === 404) this.props.route('/not-found');
            else {
                console.log(err);
                alert('An error occurred. Please refresh the page and try again.');
            }
        });
    }

    /**
     * 
     * @param {String} id ref Images._id
     * 
     * Copies the direct link to clipboard, tallies that image
     */
    copyDirectLink = id => {
        navigator.clipboard.writeText(document.getElementById(`direct-link-${id}`).textContent);
        this.setState({
            ...this.state,
            directLinkCopied: [
                ...this.state.directLinkCopied,
                id
            ]
        });
    }

    /**
     * 
     * @param {String} id ref Images._id
     * 
     * Copies the bb code to clipboard, tallies that image
     */
    copyBBCode = id => {
        navigator.clipboard.writeText(document.getElementById(`bb-code-${id}`).textContent);
        this.setState({
            ...this.state,
            bbCopied: [
                ...this.state.bbCopied,
                id
            ]
        });
    }

    render(){
        return (
            <motion.div transition={t.transition} exit={t.fade_out} animate={t.normalize} initial={t.fade_out}>
                {this.state.loaded ?
                <MDBContainer fluid>
                    <div className="row justify-content-center">
                        {this.props.series._id ?
                        <>
                            {this.props.series.images.map(image => (
                                <div className="col-12 col-md-4 col-lg-3 mt-5">
                                    <h5 className="text-center text-default text-pkmn m-0">#{image.image_id}</h5>
                                    <div className="w-100 d-flex justify-content-center">
                                        <div style={{display: 'inline-block', position: 'relative'}} onClick={() => this.props.route(`/image/${image.image_id}`)} className="border border-dark cursor-pointer w-75">
                                            <div style={{marginTop: '100%'}}></div>
                                            <div className="fit-images position-absolute top-0 bottom-0 start-0 end-0" style={{backgroundImage: `url("${process.env.REACT_APP_IMAGE_HOST}/img/${image.thumbnail}")`}}></div>
                                        </div>
                                    </div>
                                    <p className="text-center mb-1">Direct Link</p>
                                    <MDBTooltip wrapperClass="d-block mx-auto text-unset max-w-100" wrapperProps={{ color: 'success', size: 'lg', onClick: () => this.copyDirectLink(image.image_id) }} title={this.state.directLinkCopied.indexOf(image.image_id) !== -1 ? 'Copied' : 'Click to Copy'}>
                                        <span className="text-nowrap text-overflow-ellipsis max-w-100 d-block" id={`direct-link-${image.image_id}`}>{process.env.REACT_APP_IMAGE_HOST}/img/{image.filename}</span>
                                    </MDBTooltip>
                                    <p className="text-center mb-1 mt-2">BBcode</p>
                                    <MDBTooltip wrapperClass="d-block mx-auto text-unset max-w-100" wrapperProps={{ color: 'light', size: 'lg', onClick: () => this.copyBBCode(image.image_id) }} title={this.state.bbCopied.indexOf(image.image_id) !== -1 ? 'Copied' : 'Click to Copy'}>
                                        <span className="text-nowrap text-overflow-ellipsis max-w-100 d-block" id={`bb-code-${image.image_id}`}>[img]{process.env.REACT_APP_IMAGE_HOST}/img/{image.filename}[/img]</span>
                                    </MDBTooltip>
                                    <hr></hr>
                                </div>
                            ))}
                        </> : 
                        <>
                        </>}                        
                    </div>
                </MDBContainer> : 
                <div className="d-flex justify-content-center mt-5">
                    <Spinner color="indigo" size="xl"/>
                </div>}
            </motion.div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
}

export default withRouter(connect(mapStateToProps, { route, set_series })(Series));
import React from 'react';
import {
    MDBNavbarItem,
    MDBNavbarLink
} from 'mdb-react-ui-kit';

/**
 * 
 * @param {Function} logout - The logout function 
 * @param {Boolean} loggingOut - Whether the user is in the process of logging out
 * @returns The logout button on mobile devices (Appears as an option in the nav hamburger as the desktop dropdown is hidden)
 */
const LogoutButtonMobile = ({ logout }) => (
    <span className="nav-mobile">
        <MDBNavbarItem onClick={() => logout(true)} className="nav-item nav-items-collapse">
            <MDBNavbarLink id="nav-logout" className={`nav-link nav-main-items text-danger`}>Logout</MDBNavbarLink>
        </MDBNavbarItem>
    </span>
);

export default LogoutButtonMobile;
import React from 'react';
import { motion } from 'framer-motion';
import { connect } from 'react-redux';
import Report from './Report';
import Remove from './Remove';
import {
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBCardHeader,
    MDBCardFooter,
    MDBBtn,
    MDBBadge,
    MDBTooltip,
    MDBSpinner,
    MDBTypography,
    MDBPopover,
    MDBPopoverBody,
    MDBInput
} from 'mdb-react-ui-kit';
import { previous_comments, next_comments, update_comment, set_comment_page, route } from '../../../redux/actions';
import axios from 'axios';
import h from '../../../utilities/helpers';
import t from '../../../utilities/transitions';
import Spinner from '../../../components/Spinner';
import { Link } from 'react-router-dom';

class CommentSection extends React.Component{
    constructor(props){
        super();
        /**
         * this.commentPage: Number - The page of the comments that the user is on
         * this.history: Array - Comment page navigation history
         * this.mostRecentPage: Number - The last entry in this.history
         */
        this.commentPage = Number(props.imageInfo.commentPage);
        this.history = props.imageInfo.commentStack;
        this.mostRecentPage = this.history[this.history.length - 1];
        this.state = {
            /**
             * commentsLoaded: Boolean - Whether the initial comment section load has completed
             * actionFormOpened: false | Number - The comment_id of the comment that has the report dropdown open, if any
             * commentsReported: Array - List of comments that have already been reported
             * commentsRestoring: Array - List of comments that are in the process of being restored
             * commentHover: Number - comment_id of quoted comment that the user is hovering over with their mouse
             * tooltipComment: JSX - Content of comment with comment_id state.commentHover
             * removeCommentSectionFormOpen: Boolean - Whether the Disable Comments form is opened
             * modalShown: Boolean - Whether the Open Comments modal is shown
             * contentExit: Object - framer-motion exit animation for the comments
             * leftButtonExit: Object - framer-motion exit for the left comment nav button
             * rightButtonExit: Object - framer-motion exit for the right comment nav button
             * popoverOpen: Boolean indicating whether the page select popover is open
             */
            commentsLoaded: false,
            actionFormOpened: false,
            commentsReported: [],
            commentsRestoring: [],
            commentHover: 0,
            tooltipComment: <></>,
            removeCommentSectionFormOpen: false,
            modalShown: false,
            contentExit: this.getInitialContentExit(this.mostRecentPage, this.commentPage),
            leftButtonExit: this.getInitialLeftButtonExit(this.mostRecentPage, this.commentPage),
            rightButtonExit: this.getInitialRightButtonExit(this.mostRecentPage, this.commentPage, props),
            popoverOpen: false
        }
    }

    componentDidMount(){
        this.setState({
            ...this.state,
            commentsLoaded: true
        });
    }

    /**
     * 
     * @param {Event} e - Javascript mouse event
     * 
     * Hit when the user clicks or taps outside of the Page Select popover
     * Closes the popover, and removes the event listener that triggered it
     */
    closePopover = e => {
        if (!(e.target && (e.target.id === 'page-number' || e.target.classList.contains('page-popover-triggers')))) this.setState({
            ...this.state,
            popoverOpen: false
        }, () => document.body.removeEventListener('mousedown', this.closePopover));
    } 

    /**
     * Hit when the user taps or clicks the page number
     * Opens the page selector
     * Adds an event listener to close the page selector when the user clicks outside it
     * 
     */
    showPopover = () => this.setState({
        ...this.state,
        popoverOpen: true
    }, () => setTimeout(() => document.body.addEventListener('mousedown', this.closePopover), 500));

    getInitialContentExit = (mostRecentPage, commentPage) => {
        /**
         * If going back, fade out left
         * If coming from another endpoint or going to another end point, fade in
         * If going forward, fade out right
         */
        if (mostRecentPage){
            if (mostRecentPage > commentPage) return t.fade_out_left;
            else return t.fade_out_right;
        } else return t.fade_out;
    }

    getInitialLeftButtonExit = (mostRecentPage, commentPage) => {
        /**
         * If going back, bob left
         * If coming from another endpoint or going to another end point, minimize
         * If going forward, remain stationary
         */
        if (mostRecentPage){
            if (mostRecentPage > commentPage) return t.bob_left;
            else if (mostRecentPage === 1) return t.fade_out_minimize;
            else return t.normalize;
        } else return t.fade_out_minimize;   
    }

    getInitialRightButtonExit = (mostRecentPage, commentPage, props) => {
        /**
         * If going back, bob left
         * If coming from another endpoint or going to another end point, minimize
         * If going forward, remain stationary
         */
        if (mostRecentPage){
            if (mostRecentPage < commentPage) return t.bob_right;
            else if (mostRecentPage === Math.ceil(props.imageInfo.comments.length / 50)) return t.fade_out_minimize;
            else return t.normalize;
        } else return t.fade_out_minimize;   
    }

    /**
     * 
     * @param {Event} e - Javascript click event 
     * @param {String} user - The username of the user that was clicked
     * 
     * Hit when a user is clicked
     * Override default behavior, route using redux route method
     */
    goToProfile = (e, user) => {
        e.preventDefault();
        this.props.route(`/u/${user}`);
    }

    /**
     * 
     * @param {Object} comment - Comments document
     * @returns The user's name, along with a badge to indicate their role
     */
    getBadge = comment => {
        switch(comment.badge){
            case 'Chadmin':
                return (
                    <span className="name-chadmin me-2">
                        <Link style={{color: 'var(--mdb-yellow)'}} onClick={e => this.goToProfile(e, comment.name)} to={`/u/${comment.name}`}>
                            {comment.name}
                        </Link>
                        <MDBBadge className="ms-2 badge-chadmin">
                            <div className="d-flex align-items-center">
                                <div style={{height: '1.6em', width: '1.6em'}} className="d-flex justify-content-center align-items-center">
                                    <div className="fit-images" style={{backgroundImage: `url("/assets/images/meltrans.png")`}}></div>
                                </div>
                                <p className="m-0">Chadmin</p>
                                {comment.oldfag ?
                                <div className="position-relative">
                                    <MDBBadge className="badge-oldfag" color='danger' notification pill>
                                        Oldfag
                                    </MDBBadge>
                                </div> : <></>}
                            </div>
                        </MDBBadge>
                        
                    </span>
                );
            case 'Janny':
                return (
                    <span className="name-janny me-2">
                        <Link style={{color: 'var(--mdb-green)'}} onClick={e => this.goToProfile(e, comment.name)} to={`/u/${comment.name}`}>
                            {comment.name}
                        </Link>
                        <MDBBadge className="ms-2 badge-janny">
                            <div className="d-flex align-items-center">
                                <div style={{height: '1.6em', width: '1.6em'}} className="d-flex justify-content-center align-items-center">
                                    <div className="fit-images" style={{backgroundImage: `url("/assets/images/thomastrans.png")`}}></div>
                                </div>
                                <p className="m-0">Janny</p>
                                {comment.oldfag ?
                                <div className="position-relative">
                                    <MDBBadge className="badge-oldfag" color='danger' notification pill>
                                        Oldfag
                                    </MDBBadge>
                                </div> : <></>}
                            </div>
                        </MDBBadge>
                    </span>
                );
            case 'Verified':
                return (
                    <span className="name-verified me-2">
                        <Link style={{color: 'rgb(28, 202, 255)'}} onClick={e => this.goToProfile(e, comment.name)} to={`/u/${comment.name}`}>
                            {comment.name}
                        </Link>
                        <MDBBadge className="ms-2 badge-verified">
                            <div className="d-flex align-items-center">
                                <div style={{height: '1.6em', width: '1.6em'}} className="d-flex justify-content-center align-items-center">
                                    <div className="fit-images" style={{backgroundImage: `url("/assets/images/verifiedlogotrans.png")`}}></div>
                                </div>
                                <p className="m-0">Verified</p>
                                {comment.oldfag ?
                                <div className="position-relative">
                                    <MDBBadge className="badge-oldfag" color='danger' notification pill>
                                        Oldfag
                                    </MDBBadge>
                                </div> : <></>}
                            </div>
                        </MDBBadge>
                    </span>
                );
            default:
                console.log('oob badge', comment.badge);
        }
    }

    /**
     * 
     * @param {Number} commentID - ref Comments.comment_id
     * 
     * Opens the report/remove form for the comment that was clicked
     */
    openActionForm = commentID => this.setState({
        ...this.state,
        actionFormOpened: (this.state.actionFormOpened === commentID) ? false : commentID
    });

    /**
     * 
     * @param {Number} commentID - ref Comments.comment_id
     * 
     * Adds the comment to the list of comments that have already been reported
     */
    setCommentReported = commentID => this.setState({
        ...this.state,
        commentsReported: [
            ...this.state.commentsReported,
            commentID
        ]
    });

    /**
     * 
     * @param {Number} commentID - ref Comments.comment_id
     * 
     * Restores a comment that was removed
     * Updates in application state
     * Emits a socket event to update everyone else on the page
     */
    restoreComment = commentID => {
        if (this.state.commentsRestoring.indexOf(commentID) === -1) this.setState({
            ...this.state,
            commentsRestoring: [
                ...this.state.commentsRestoring,
                commentID
            ]
        }, () => axios.post('/support/restore/comment', {
            commentID: commentID,
            imageID: this.props.imageInfo.image_id
        }).then(res => {
            this.props.update_comment(res.data.comment);
            this.props.socket.emit('mod-action');
            this.setState({
                ...this.state,
                commentsRestoring: this.state.commentsRestoring.filter(comment => comment !== commentID)
            });
        }).catch(err => this.setState({
            ...this.state,
            commentsRestoring: this.state.commentsRestoring.filter(comment => comment !== commentID)
        }, () => {
            console.log(err);
            alert('An error occurred. Please try again later.');
        })));
    }

    /**
     * 
     * @param {Number} postNumber - ref Comments.comment_id
     * 
     * Renders a comment that was quoted with the double hashes (##)
     * If the comment is in the comment section, pull the comment data from state and render
     * Else, render a spinner while the comment data is fetched from the server
     */
    renderTooltipComment = postNumber => {
        let comment = this.props.imageInfo.comments.find(comment => comment.comment_id === postNumber);
        if (comment) this.setState({
            ...this.state,
            commentHover: postNumber,
            tooltipComment: <>
                {comment.removed && !h.checkJanny(this.props.userInfo) ?
                <motion.div transition={t.transition} exit={t.fade_out_minimize} animate={t.normalize} initial={t.fade_out_minimize}>
                    <MDBCard className={`mx-auto ${(comment.removed) ? 'card-removed' : ''}`}>
                        <MDBCardBody className="d-flex">
                            <MDBCardText className={`messages text-break ${(comment.removed) ? 'text-light' : ''}`}>Comment Removed</MDBCardText>
                        </MDBCardBody>
                    </MDBCard>
                </motion.div> :
                <motion.div transition={t.transition} exit={t.fade_out_minimize} animate={t.normalize} initial={t.fade_out_minimize}>
                    <MDBCard className={`mx-auto ${(comment.removed) ? 'card-removed' : ''}`}>
                        <MDBCardHeader className="d-flex align-items-end">
                            <MDBCardTitle style={{maxWidth: '80%'}} className={`mb-0 ${(comment.removed) ? 'text-light' : ''}`}>
                            {comment.badge ? this.getBadge(comment) : 
                            <>
                                <span className={comment.removed ? "text-light" : "text-secondary"}>{comment.name}</span> <span className="text-nowrap me-2">(ID: <span className="text-light" style={{backgroundColor: `var(${comment.poster_css_color})`}}>{comment.poster_id}</span>)</span>
                            </>
                            }
                            <span className="mb-0 text-nowrap">{h.makeDateHR(new Date(comment.timestamp))} at {h.getTimeHR(new Date(comment.timestamp))}</span>
                            </MDBCardTitle>
                            <MDBCardTitle className={`ms-2 mb-0 text-pkmn ${(comment.removed) ? 'text-light' : ''}`}>#{comment.comment_id}</MDBCardTitle>    
                        </MDBCardHeader>
                        <MDBCardBody className="d-flex">
                            {comment.avatar ? 
                            <div style={{cursor: 'pointer'}} className="d-flex justify-content-center align-items-center square-8 mx-2">
                                <div className="fit-images" style={{backgroundImage: `url("/api/image-id/${comment.avatar}")`}}></div>
                            </div> : <></>}
                            <MDBCardText className={`messages text-break ${(comment.removed) ? 'text-light' : ''}`}>{this.parseComment_displayOnly(comment.comment_text)}</MDBCardText>
                        </MDBCardBody>
                    </MDBCard>
                </motion.div>}
            </>
        });
        else this.setState({
            ...this.state,
            commentHover: postNumber,
            tooltipComment: <>
                <motion.div transition={t.transition} exit={t.fade_out_minimize} animate={t.normalize} initial={t.fade_out_minimize}>
                    <MDBCard className="mx-auto">
                        <MDBCardBody className="d-flex justify-content-center align-items-center">
                            <MDBSpinner color="success" grow size="lg"></MDBSpinner>
                        </MDBCardBody>
                    </MDBCard>
                </motion.div>
            </>
        }, () => axios.get(`/api/comment/${postNumber}`).then(res => {
            comment = res.data.comment;
            if (this.state.commentHover === postNumber) this.setState({
                ...this.state,
                tooltipComment: <>
                    {comment.removed && !h.checkJanny(this.props.userInfo) ?
                    <motion.div transition={t.transition} exit={t.fade_out_minimize} animate={t.normalize} initial={t.fade_out_minimize}>
                        <MDBCard className={`mx-auto ${(comment.removed) ? 'card-removed' : ''}`}>
                            <MDBCardBody className="d-flex">
                                <h5 className="text-center text-light p-0">Comment removed</h5>
                            </MDBCardBody>
                        </MDBCard>
                    </motion.div> :
                    <motion.div transition={t.transition} exit={t.fade_out_minimize} animate={t.normalize} initial={t.fade_out_minimize}>
                        <MDBCard className={`mx-auto ${(comment.removed) ? 'card-removed' : ''}`}>
                            <MDBCardHeader className="d-flex align-items-end">
                                <MDBCardTitle style={{maxWidth: '80%'}} className={`mb-0 ${(comment.removed) ? 'text-light' : ''}`}>
                                {comment.badge ? this.getBadge(comment) : 
                                <>
                                    <span className={comment.removed ? "text-light" : "text-secondary"}>{comment.name}</span> <span className="text-nowrap me-2">(ID: <span className="text-light" style={{backgroundColor: `var(${comment.poster_css_color})`}}>{comment.poster_id}</span>)</span>
                                </>
                                }
                                <span className="mb-0 text-nowrap">{h.makeDateHR(new Date(comment.timestamp))} at {h.getTimeHR(new Date(comment.timestamp))}</span>
                                </MDBCardTitle>
                                <MDBCardTitle className={`ms-2 mb-0 text-pkmn ${(comment.removed) ? 'text-light' : ''}`}>#{comment.comment_id}</MDBCardTitle>    
                            </MDBCardHeader>
                            <MDBCardBody className="d-flex">
                                {comment.avatar ? 
                                <div style={{cursor: 'pointer'}} className="d-flex justify-content-center align-items-center square-8 mx-2">
                                    <div className="fit-images" style={{backgroundImage: `url("/api/image-id/${comment.avatar}")`}}></div>
                                </div> : <></>}
                                <MDBCardText className={`messages text-break ${(comment.removed) ? 'text-light' : ''}`}>{this.parseComment_displayOnly(comment.comment_text)}</MDBCardText>
                            </MDBCardBody>
                        </MDBCard>
                    </motion.div>}
                </>
            })
        }).catch(err => {
            console.log(err);
            if (this.state.commentHover === postNumber){
                if (err.response.status === 404) this.setState({
                    ...this.state,
                    tooltipComment: <>
                        <motion.div transition={t.transition} exit={t.fade_out_minimize} animate={t.normalize} initial={t.fade_out_minimize}>
                            <MDBCard className="mx-auto">
                                <MDBCardBody className="d-flex justify-content-center align-items-center">
                                    <h5 className="text-center">Comment does not exist</h5>
                                </MDBCardBody>
                            </MDBCard>
                        </motion.div>
                    </>
                });
                else this.setState({
                    ...this.state,
                    tooltipComment: <>
                        <motion.div transition={t.transition} exit={t.fade_out_minimize} animate={t.normalize} initial={t.fade_out_minimize}>
                            <MDBCard className="mx-auto">
                                <MDBCardBody className="d-flex justify-content-center align-items-center">
                                    <h5 className="text-center">An error occurred while fetching the comment</h5>
                                </MDBCardBody>
                            </MDBCard>
                        </motion.div>
                    </>
                });
            }
        }));
    }

    /**
     * 
     * @param {Number} commentID - ref Comments.comment_id
     * 
     * Hit when the user clicks on a quoted comment
     * If it is already in the document, scroll to it
     * Else, wait and try again (could happen due to framer-motion transitions)
     */
    scrollToComment = commentID => setTimeout(() => {
        const comment = document.getElementById(`comment-${commentID}`);
        if (comment) comment.scrollIntoView();
        else this.scrollToComment(commentID);
    }, 400);

    /**
     * 
     * @param {Event} e - Javascript click event or similar
     * @param {Number} commentID - ref Comments.comment_id
     * 
     * Fired when the user clicks on a quoted comment
     * If the comment is not in the comment section, route to /comment/commentID
     * If the comment is in the comment section and in the document, scroll to it
     * If the comment is in the comment section and not in the document, switch to the appropriate page and scroll to it
     */
    clickQuotedComment = (e, commentID) => {
        e.preventDefault();
        window.location.hash = `#comment-${commentID}`;
        commentID = Number(commentID);
        document.getElementById(`tooltip-comment-${commentID}`).classList.add('d-none');
        const comment = this.props.imageInfo.comments.find(c => c.comment_id === commentID);
        if (!comment) this.props.route(`/comment/${commentID}`);
        else {
            const element = document.getElementById(`comment-${commentID}`);
            if (element) element.scrollIntoView();
            else {
                const index = this.props.imageInfo.comments.indexOf(comment) + 1;
                const page = Math.ceil(index / 50);
                const maxPages = Math.ceil(this.props.imageInfo.comments.length / 50);
                this.setState({
                    ...this.state,
                    rightButtonExit: (this.commentPage > page) ? t.normalize : (page === maxPages ? t.fade_out_minimize : t.bob_right),
                    leftButtonExit: (this.commentPage > page) ? (page === 1 ? t.fade_out_minimize : t.bob_left) : t.normalize,
                    contentExit: (this.commentPage > page) ? t.fade_out_right : t.fade_out_left
                }, () => {
                    this.props.set_comment_page(page);
                    this.scrollToComment(commentID);
                });
            }
        }
    }

    /**
     * 
     * @param {String} text - The text of a comment
     * 
     * Parses the string for double hashes (##) which are used to quote other comments
     * Replaces them with links to other comments and tooltips with previews
     * 
     * @returns JSX array with parsed comment
     */
    parseComment = text => {
        let returnArray = [];
        text.split('##').forEach(segment => {
            if (segment){
                let postNumber = '';
                let stop = false;
                segment.split('').forEach(character => {
                    if (!stop && h.isNumeric(character)) postNumber += character;
                    else stop = true;
                });
                if (postNumber){
                    returnArray.push(
                        <MDBTooltip 
                            tag="span" 
                            wrapperProps={{
                                className: "text-primary links-generic-light text-decoration-none cursor-pointer fw-bold"
                            }}
                            title={this.state.tooltipComment}
                            onShow={() => this.renderTooltipComment(Number(postNumber))}
                            className="comment-tooltips ms-2"
                            id={`tooltip-comment-${postNumber}`}
                        ><Link to={`/comment/${postNumber}`} onClick={(e) => this.clickQuotedComment(e, postNumber)}>##</Link><span style={{cursor: 'text'}}>{postNumber}</span></MDBTooltip>);
                    returnArray.push(
                        <span>
                            {segment.split(postNumber).filter((piece, i) => i !== 0).join(postNumber)}
                        </span>
                    );
                } else returnArray.push(<span>{segment}</span>);
            }
        });
        return returnArray;
    }

    /**
     * 
     * @param {String} text - The text of a comment
     * 
     * Performs the same task as parseComment, but without the links or tooltips. Visual effects only.
     * 
     * @returns JSX array with parsed comment
     */
    parseComment_displayOnly = text => {
        let returnArray = [];
        text.split('##').forEach(segment => {
            if (segment){
                let postNumber = '';
                let stop = false;
                segment.split('').forEach(character => {
                    if (!stop && h.isNumeric(character)) postNumber += character;
                    else stop = true;
                });
                if (postNumber){
                    returnArray.push(
                        <span className="text-primary links-generic-light text-decoration-none cursor-pointer fw-bold">{'##' + postNumber}</span>);
                    returnArray.push(
                        <span>{segment.split(postNumber).filter((piece, i) => i !== 0).join(postNumber)}</span>
                    );
                } else returnArray.push(
                    <span>{segment}</span>
                );
            }
        });
        return returnArray;
    }

    /**
     * 
     * @param {Number} commentID - ref Comments.comment_id
     * @param {Boolean} removed - Whether the comment has been removed
     * 
     * 
     * @returns JSX list of replies to that comment
     */
    renderCommentReplies = (commentID, removed) => {
        const commentReplies = this.props.imageInfo.comments.filter(comment => comment.comment_text.split(`##${commentID}`).length > 1);
        if (commentReplies.length) return (
            <>
                <p className={removed ? 'text-light' : ''}>Replies:</p>
                <p className="d-flex flex-wrap">{commentReplies.sort((a, b) => a.comment_id - b.comment_id).map(reply => (
                    <MDBTooltip 
                        tag="span" 
                        wrapperProps={{
                            className: "text-primary links-generic-light text-decoration-none cursor-pointer fw-bold me-2 text-nowrap d-block"
                        }}
                        title={this.state.tooltipComment}
                        onShow={() => this.renderTooltipComment(Number(reply.comment_id))}
                        className="comment-tooltips ms-2"
                        id={`tooltip-comment-${reply.comment_id}`}
                    ><Link to={`/comment/${reply.commentID}`} onClick={(e) => this.clickQuotedComment(e, reply.comment_id)}>##</Link><span style={{cursor: 'text'}}>{reply.comment_id}</span></MDBTooltip>
                ))}</p>
            </>
        );
        else return <></>
    }

    /**
     * Fired when the user clicks the left nav button in the comment section
     */
    back = () => {
        this.setState({
            ...this.state,
            rightButtonExit: (this.commentPage === Math.ceil(this.props.imageInfo.comments.length / 50)) ? t.fade_out_minimize : t.normalize,
            leftButtonExit: (this.commentPage > 2) ? t.bob_left : t.fade_out_minimize,
            contentExit: t.fade_out_right
        }, () => this.props.previous_comments());
    }

    /**
     * Fired when the user enters an invalid number in the page selector
     * Navigates to page 1
     */
    first = () => {
        this.setState({
            ...this.state,
            rightButtonExit: (this.commentPage === Math.ceil(this.props.imageInfo.comments.length / 50)) ? t.fade_out_minimize : t.normalize,
            leftButtonExit: t.fade_out_minimize,
            contentExit: t.fade_out_right
        }, () => this.props.set_comment_page(1));
    }

    /**
     * Fired when the user enters a page number that is higher than the highest page in the page selector
     * Navigates to the highest page
     */
    last = () => {
        this.setState({
            ...this.state,
            rightButtonExit: t.fade_out_minimize,
            leftButtonExit: (this.commentPage === 1) ? t.fade_out_minimize : t.normalize,
            contentExit: t.fade_out_left
        }, () => this.props.set_comment_page(Math.ceil(this.props.imageInfo.comments.length / 50)));
    }


    /**
     * Fired when the user clicks the right nav button in the comment section
     */    
    next = () => {
        this.setState({
            ...this.state,
            rightButtonExit: (this.commentPage === Math.ceil(this.props.imageInfo.comments.length / 50) - 1) ? t.fade_out_minimize : t.bob_right,
            leftButtonExit: (this.commentPage === 1) ? t.fade_out_minimize : t.normalize,
            contentExit: t.fade_out_left
        }, () => this.props.next_comments());
    }

    /**
     * 
     * @param {Number} index - Index of the page selector popover
     * 
     * Fired when the user clicks the Go button in the page selector
     * Navigates to the page that was entered in the page selector input
     */
    go = (index) => {
        let pageNumber = document.getElementById(`page-number-${index}`).value;
        const maxPages = Math.ceil(this.props.imageInfo.comments.length / 50);
        if (h.isNumeric(pageNumber)){
            pageNumber = Number(pageNumber);
            if (pageNumber > maxPages) pageNumber = maxPages;
        } else pageNumber = 1;
        if (this.commentPage !== pageNumber) this.setState({
            ...this.state,
            rightButtonExit: (this.commentPage > pageNumber) ? t.normalize : (pageNumber === maxPages ? t.fade_out_minimize : t.bob_right),
            leftButtonExit: (this.commentPage > pageNumber) ? (pageNumber === 1 ? t.fade_out_minimize : t.bob_left) : t.normalize,
            contentExit: (this.commentPage > pageNumber) ? t.fade_out_right : t.fade_out_left
        }, () => this.props.set_comment_page(pageNumber));
    }

    pressEnter = (e, index) => {
        /**
         * Submit the form if the user presses the enter key while in one of the inputs
         */
        if (e.key === 'Enter') this.go(index);
    }

    /**
     * 
     * @param {Event} e Click or similar event
     * @param {String} link - Navigation path
     * 
     * Used to override default/native behavior and use the redux function to route
     */
    clickLink = (e, link) => {
        e.preventDefault();
        this.props.route(link);
    }

    render(){
        return (
            <>
                {this.props.imageInfo.comments.length > 50 ?
                <div className="d-flex align-items-center image-comment-navs mt-4">
                    {this.commentPage !== 1 ?
                    <motion.span transition={t.transition} exit={this.state.leftButtonExit} animate={t.normalize} initial={this.state.leftButtonExit} >
                        <MDBBtn size="lg" onClick={this.back} style={{background: 'var(--mdb-indigo)'}} className="d-block"><i className="fas fa-chevron-left"></i></MDBBtn>
                    </motion.span>
                     : 
                    <MDBBtn size="lg" className="invis"><i className="fas fa-chevron-left"></i></MDBBtn>}
                    <MDBPopover 
                        color="link" 
                        placement="top"
                        btnChildren={
                            <div className="mx-4 page-popover-triggers">
                                <MDBTypography tag="small" className="text-center text-default page-popover-triggers">Page</MDBTypography>
                                <h4 className="display-6 text-center my-0 text-default page-popover-triggers">{this.props.imageInfo.commentPage}</h4>
                            </div>
                        }
                        btnClassName="text-unset px-2 mx-2 page-popover-triggers"
                        className="mb-2 w-max-content max-w-max-content page-popover-triggers"
                        rippleColor="primary"
                        onShow={this.showPopover}
                        isOpen={this.state.popoverOpen}
                    >
                        <MDBPopoverBody className="page-popover-triggers">
                            <div className="d-flex align-items-center page-popover-triggers">
                                <MDBBtn onClick={this.first} style={{background: 'var(--mdb-purple)'}} className="d-block">
                                    <i className="fas fa-chevron-left"></i>
                                    <i className="fas fa-chevron-left me-2"></i>
                                    Page 1
                                </MDBBtn>
                                <div className="mx-2 page-popover-triggers">
                                    <div className="d-flex justify-content-center page-popover-triggers">
                                        <MDBInput 
                                            min="1" 
                                            max={Math.ceil(this.props.imageInfo.comments.length / 50)}
                                            defaultValue={this.commentPage} 
                                            label="Page" 
                                            type="number" 
                                            id="page-number-1"
                                            style={{
                                                width: '5rem'
                                            }}
                                            onKeyPress={e => this.pressEnter(e, 1)}
                                            className="page-popover-triggers"
                                        />
                                    </div>
                                    <MDBBtn onClick={() => this.go(1)} className="d-block mx-auto mt-2" color="success">Go</MDBBtn>
                                </div>
                                <MDBBtn onClick={this.last} style={{background: 'var(--mdb-purple)'}} className="d-block">
                                    Page {Math.ceil(this.props.imageInfo.comments.length / 50)}
                                    <i className="fas fa-chevron-right ms-2"></i>
                                    <i className="fas fa-chevron-right"></i>
                                </MDBBtn>
                            </div>
                        </MDBPopoverBody>
                    </MDBPopover>
                    
                    {this.commentPage !== Math.ceil(this.props.imageInfo.comments.length / 50) ?
                    <motion.span transition={t.transition} exit={this.state.rightButtonExit} animate={t.normalize} initial={this.state.rightButtonExit}>
                        <MDBBtn size="lg" onClick={this.next} style={{background: 'var(--mdb-indigo)'}} className="d-block"><i className="fas fa-chevron-right"></i></MDBBtn>
                    </motion.span>
                     : 
                    <MDBBtn size="lg" className="invis"><i className="fas fa-chevron-right"></i></MDBBtn>}
                </div> :
                <></>}
                <motion.div className="pb-4" style={{overflowX: 'hidden'}} transition={t.transition} exit={this.state.contentExit} animate={t.normalize} initial={this.state.contentExit}>
                    {this.props.imageInfo.image_id === 4506 ?
                    <MDBCard className={`mt-4 shadow-3-strong mx-auto`}>
                        <MDBCardHeader className="d-flex comment-card-headers">
                            <MDBCardTitle style={{maxWidth: '80%'}} className={`mb-0`}>
                            <span className="name-janny me-2">
                                <Link style={{color: 'var(--mdb-green)'}} onClick={e => this.goToProfile(e, 'Pringles Can Josiah')} to={`/u/Pringles Can Josiah`}>
                                    Pringles Can Josiah
                                </Link>
                                <MDBBadge className="ms-2 badge-janny">
                                    <div className="d-flex align-items-center">
                                        <div style={{height: '1.6em', width: '1.6em'}} className="d-flex justify-content-center align-items-center">
                                            <div className="fit-images" style={{backgroundImage: `url("/assets/images/thomastrans.png")`}}></div>
                                        </div>
                                        <p className="m-0">Janny</p>
                                        <div className="position-relative">
                                            <MDBBadge className="badge-oldfag" color='danger' notification pill>
                                                Oldfag
                                            </MDBBadge>
                                        </div>
                                    </div>
                                </MDBBadge>
                            </span>
                            <span className="mb-0 text-nowrap">9/25/2021 at 2:55AM</span>
                            </MDBCardTitle>   
                        </MDBCardHeader>
                        <MDBCardBody>
                            <h5>Linkin Pork</h5>
                            <video style={{width: '480px', marginTop: '15px', maxWidth: '90%'}} id="video-wellington" src="https://img.nanaimg.net/random/wellington.mp4" controls></video>
                            <h5 className="mt-2">Patrick's anal sex moan (occurs at 25:19)</h5>
                            <div><iframe width="300" height="60" src="https://vocaroo.com/embed/19TnDoswCmfi?autoplay=0" frameborder="0" allow="autoplay"></iframe><br/><a href="https://voca.ro/19TnDoswCmfi" title="Vocaroo Voice Recorder" target="_blank">View on Vocaroo &gt;&gt;</a></div>
                            <h5 className="mt-2">Child Child Child</h5>
                            <audio controls>
                                <source src="https://img.nanaimg.net/random/child.mp3" type="audio/mp3"></source>
                            </audio>
                        </MDBCardBody>
                    </MDBCard> : <></>
                    }
                    {this.props.imageInfo.comments.sort((a, b) => a.comment_id - b.comment_id).filter((comment, i) => {
                        if ((i >= (Number(this.commentPage) * 50) - 1 - 50) && (i < (Number(this.commentPage) * 50))) return true;
                        else return false;
                    }).map(comment => (
                        <motion.div key={comment.comment_id} id={`comment-${comment.comment_id}`} transition={t.transition} exit={t.normalize} animate={t.normalize} initial={this.state.commentsLoaded || this.props.imageInfo.comments.length === 1 ? t.fade_out_right : t.normalize}>
                            <MDBCard className={`mt-4 shadow-3-strong mx-auto ${(comment.removed) ? 'card-removed' : ''}`}>
                                <MDBCardHeader className="d-flex comment-card-headers">
                                    <MDBCardTitle style={{maxWidth: '80%'}} className={`mb-0 ${(comment.removed) ? 'text-light' : ''}`}>
                                    {comment.badge ? this.getBadge(comment) : 
                                    <>
                                        <span className={`${comment.removed ? "text-light" : "text-secondary"}`}>{comment.name}</span> <span className="text-nowrap me-2">(ID: <span className="text-light" style={{backgroundColor: `var(${comment.poster_css_color})`}}>{comment.poster_id}</span>)</span>
                                    </>
                                    }
                                    <span className="mb-0 text-nowrap">{h.makeDateHR(new Date(comment.timestamp))} at {h.getTimeHR(new Date(comment.timestamp))}</span>
                                    </MDBCardTitle>
                                    <MDBCardTitle onClick={() => this.props.clickCommentID(comment.comment_id)} className={`ms-2 mb-0 text-pkmn links-generic-light cursor-pointer ${(comment.removed) ? 'text-light' : ''}`}>#{comment.comment_id}</MDBCardTitle>    
                                </MDBCardHeader>
                                <MDBCardBody className="d-flex">
                                    {comment.avatar ? 
                                    <Link onClick={e => this.clickLink(e, `/image/${comment.avatar}`)} to={`/image/${comment.avatar}`}>
                                        <div style={{cursor: 'pointer'}} className="d-flex justify-content-center align-items-center square-8 mx-2">
                                            <div className="fit-images" style={{backgroundImage: `url("/api/image-id/${comment.avatar}")`}}></div>
                                        </div>
                                    </Link>
                                     : <></>}
                                    <MDBCardText className={`messages text-break ${(comment.removed) ? 'text-light' : ''}`}>{this.parseComment(comment.comment_text)}</MDBCardText>
                                </MDBCardBody>
                                <MDBCardFooter>
                                    {!comment.removed ?
                                    <div className="d-flex justify-content-between max-w-100 align-items-start">
                                        <div style={{maxWidth: '85%'}}>{this.renderCommentReplies(comment.comment_id)}</div>
                                        {h.checkJanny(this.props.userInfo) ?
                                        <div>
                                            <MDBBtn size="sm" onClick={() => this.openActionForm(comment.comment_id)} color="dark" className="d-block ms-auto">Remove</MDBBtn>
                                            <Remove commentID={comment.comment_id} socket={this.props.socket} show={this.state.actionFormOpened === comment.comment_id} />
                                        </div> :
                                        <div>
                                            {this.state.commentsReported.indexOf(comment.comment_id) !== -1 ? 
                                            <motion.h5 transition={t.transition} exit={t.fade_out_minimize} animate={t.normalize} initial={t.fade_out_minimize} className="text-end text-danger">Comment Reported</motion.h5> :
                                            <>
                                                <MDBBtn size="sm" onClick={() => this.openActionForm(comment.comment_id)} color="dark" className="d-block ms-auto">Report</MDBBtn>
                                                <Report setCommentReported={this.setCommentReported} commentID={comment.comment_id} show={this.state.actionFormOpened === comment.comment_id} />
                                            </>}
                                        </div>}
                                    </div> : 
                                    <>
                                        {h.checkJanny(this.props.userInfo) ?
                                        <div className="d-flex justify-content-between max-w-100 align-items-start">
                                            <div style={{maxWidth: '85%'}}>
                                                <h5 className="text-light messages">Removed: {comment.removed_reason === 'other' ? comment.removed_details : h.getRemovedReason(comment.removed_reason)}</h5>
                                                <div>{this.renderCommentReplies(comment.comment_id, true)}</div>
                                            </div>
                                            {this.state.commentsRestoring.indexOf(comment.comment_id) === -1 ?
                                            <MDBBtn size="sm" onClick={() => this.restoreComment(comment.comment_id)} style={{backgroundColor: 'var(--mdb-green)'}}>Restore Comment</MDBBtn> :
                                            <MDBBtn disabled size="sm" onClick={this.restoreComment} style={{backgroundColor: 'var(--mdb-green)'}}><Spinner size="sm" className="me-2"></Spinner>Restoring</MDBBtn>}
                                        </div> : <></>}
                                    </>}
                                </MDBCardFooter>
                            </MDBCard>
                        </motion.div>
                        
                    ))}
                </motion.div>
                {this.props.imageInfo.comments.length > 50 ?
                <div className="d-flex align-items-center image-comment-navs">
                    {this.commentPage !== 1 ?
                    <motion.span transition={t.transition} exit={this.state.leftButtonExit} animate={t.normalize} initial={this.state.leftButtonExit} >
                        <MDBBtn size="lg" onClick={this.back} style={{background: 'var(--mdb-indigo)'}} className="d-block"><i className="fas fa-chevron-left"></i></MDBBtn>
                    </motion.span>
                     : 
                    <MDBBtn size="lg" className="invis"><i className="fas fa-chevron-left"></i></MDBBtn>}
                    <MDBPopover 
                        color="link" 
                        placement="top"
                        btnChildren={
                            <div className="mx-4 page-popover-triggers">
                                <MDBTypography tag="small" className="text-center text-default page-popover-triggers">Page</MDBTypography>
                                <h4 className="display-6 text-center my-0 text-default page-popover-triggers">{this.props.imageInfo.commentPage}</h4>
                            </div>
                        }
                        btnClassName="text-unset px-2 mx-2 page-popover-triggers"
                        className="mb-2 w-max-content max-w-max-content page-popover-triggers"
                        rippleColor="primary"
                        onShow={this.showPopover}
                        isOpen={this.state.popoverOpen}
                    >
                        <MDBPopoverBody className="page-popover-triggers">
                            <div className="d-flex align-items-center page-popover-triggers">
                                <MDBBtn onClick={this.first} style={{background: 'var(--mdb-purple)'}} className="d-block">
                                    <i className="fas fa-chevron-left"></i>
                                    <i className="fas fa-chevron-left me-2"></i>
                                    Page 1
                                </MDBBtn>
                                <div className="mx-2 page-popover-triggers">
                                    <div className="d-flex justify-content-center page-popover-triggers">
                                        <MDBInput 
                                            min="1" 
                                            max={Math.ceil(this.props.imageInfo.comments.length / 50)}
                                            defaultValue={this.commentPage} 
                                            label="Page" 
                                            type="number" 
                                            id="page-number-2"
                                            style={{
                                                width: '5rem'
                                            }}
                                            onKeyPress={e => this.pressEnter(e, 1)}
                                            className="page-popover-triggers"
                                        />
                                    </div>
                                    <MDBBtn onClick={() => this.go(2)} className="d-block mx-auto mt-2" color="success">Go</MDBBtn>
                                </div>
                                <MDBBtn onClick={this.last} style={{background: 'var(--mdb-purple)'}} className="d-block">
                                    Page {Math.ceil(this.props.imageInfo.comments.length / 50)}
                                    <i className="fas fa-chevron-right ms-2"></i>
                                    <i className="fas fa-chevron-right"></i>
                                </MDBBtn>
                            </div>
                        </MDBPopoverBody>
                    </MDBPopover>
                    
                    {this.commentPage !== Math.ceil(this.props.imageInfo.comments.length / 50) ?
                    <motion.span transition={t.transition} exit={this.state.rightButtonExit} animate={t.normalize} initial={this.state.rightButtonExit}>
                        <MDBBtn size="lg" onClick={this.next} style={{background: 'var(--mdb-indigo)'}} className="d-block"><i className="fas fa-chevron-right"></i></MDBBtn>
                    </motion.span>
                     : 
                    <MDBBtn size="lg" className="invis"><i className="fas fa-chevron-right"></i></MDBBtn>}
                </div> :
                <></>}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
}

export default connect(mapStateToProps, { previous_comments, next_comments, update_comment, set_comment_page, route })(CommentSection);
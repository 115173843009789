import React from 'react';
import axios from 'axios';
import { route, purge_user, select_dashboard_item } from '../../redux/actions';
import { connect } from 'react-redux';
import options from './options';
import {
    MDBCard,
    MDBCardBody,
    MDBListGroup, 
    MDBListGroupItem,
    MDBBtn,
    MDBSpinner,
    MDBRipple
} from 'mdb-react-ui-kit';
import Spinner from '../../components/Spinner';

/**
 * This is the dashboard menu card on the left side
 * User selects menu items here, which are rendered on the right side
 */

class Menu extends React.Component{
    constructor(){
        super();
        this.state = {
            /**
             * loggingOut: Boolean indicating whether the user is in the process of logging out
             * loaded: Boolean indicating whether userInfo has been loaded into application state
             */
            loggingOut: false,
            loaded: false
        }
    }

    componentDidMount(){
        /**
         * When component mounts, check if user info exists in application state
         * If so, load
         */
        if (!this.state.loaded && this.props.userInfo.role) this.load();
    }

    componentDidUpdate(){
        /**
         * When component updates, check if user info exists in application state
         * If so, and component is not already loaded, load
         */
        if (!this.state.loaded && this.props.userInfo.role) this.load();
    }

    load = () => this.setState({
        ...this.state,
        loaded: true
    });

    logout = () => {
        /**
         * Fired when the user clicks the logout button at the bottom
         * Purge the user and redirect to the login page
         */
        if (!this.state.loggingOut) this.setState({
            ...this.state,
            loggingOut: true
        }, () => axios.post('/auth/logout').then(() => {
            this.props.purge_user();
            this.props.route('/login');
        }).catch(err => this.setState({
            ...this.state,
            loggingOut: false
        }, () => {
            console.log(err);
            alert('An error occurred. Please try again later');
        })));
    }


    render(){
        return (
            <MDBCard className="h-100 shadow">
                <MDBCardBody className="h-100 d-flex flex-column">
                    <h1 className="display-6 m-0 text-nowrap">Dashboard</h1>
                    <hr></hr>
                    <div className="flex-grow-1">
                        {this.props.userInfo.role && this.state.loaded ?
                        <MDBListGroup>
                            {options.find(o => o.category === this.props.userInfo.role).options.map(option => (
                                <MDBRipple>
                                    <MDBListGroupItem onClick={() => this.props.select_dashboard_item(option.id)} action active={option.id === this.props.dashboardItemSelected} className="cursor-pointer">
                                        {option.icon}
                                        {option.text}
                                    </MDBListGroupItem>
                                </MDBRipple>
                            ))}
                        </MDBListGroup> :
                        <div className="d-flex justify-content-center mt-2">
                            <MDBSpinner size='lg' grow color="primary" role='status' tag='span' />
                        </div>}
                    </div>
                    <hr></hr>
                    {this.state.loggingOut ?
                    <MDBBtn color="danger" size="lg" disabled><Spinner size='sm' className='me-2' />Working</MDBBtn>:
                    <MDBBtn onClick={this.logout} color="danger" size="lg">Logout</MDBBtn>
                    }
                </MDBCardBody>
            </MDBCard>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
  }
  
  export default connect(mapStateToProps, { route, purge_user, select_dashboard_item })(Menu);
import React from 'react';
import h from '../../../../utilities/helpers';
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
  } from 'mdb-react-ui-kit';

/**
 * This is the modal that pops up when the user selects a message from a list of contact form messages
 */

class MessageModal extends React.Component{
    constructor(){
        super();
    }

    render(){
        return (
            <>
                {typeof window !== 'undefined' && window.navigator ?
                <MDBModal show={this.props.modalShown} setShow={this.props.setShowModal} tabIndex='-1'>
                    <MDBModalDialog size="xl">
                        <MDBModalContent>
                            <MDBModalHeader>
                                <MDBModalTitle>Message</MDBModalTitle>
                                <MDBBtn className='btn-close' color='none' onClick={this.props.toggleShowModal}></MDBBtn>
                            </MDBModalHeader>
                            <MDBModalBody>
                                <div className="d-flex justify-content-between mobile-column">
                                    <div>
                                        <h4 className="text-break">Name: {this.props.message.name}</h4>
                                        <p className="mb-1 text-break">Email: {this.props.message.email}</p>
                                    </div>
                                    <div>
                                        <p className="mb-1 text-end text-break mobile-text-start">{h.makeDateHR(new Date(this.props.message.timestamp))}</p>
                                    </div>
                                </div>
                                <hr></hr>
                                <h4 className="text-break">Subject: {this.props.message.subject}</h4>
                                <p className="text-break">{this.props.message.message}</p>
                            </MDBModalBody>
                            <MDBModalFooter>
                                <MDBBtn size="lg" style={{backgroundColor: 'var(--mdb-gray)'}} onClick={this.props.toggleShowModal}>
                                    Close
                                </MDBBtn>
                            </MDBModalFooter>
                        </MDBModalContent>
                    </MDBModalDialog>
                </MDBModal> : <></>}
            </>
        );
    }
}


export default MessageModal;
import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { set_user, purge_user } from '../../../redux/actions';
import { userSettings } from './settings/textFields';
import ToastMisc from '../../../components/notifications/ToastMisc';
import { user_update_schema } from '../../../utilities/validations';
import ChangePasswordModal from './settings/ChangePasswordModal';
import {
    MDBBtn,
    MDBContainer,
    MDBSelect,
    MDBInput,
    MDBValidation,
    MDBValidationItem,
    MDBSpinner,
    MDBRadio,
    MDBBtnGroup,
    MDBBadge,
    MDBTextArea,
    MDBCheckbox,
    MDBRipple,
    MDBAlert
} from 'mdb-react-ui-kit';
import Spinner from '../../../components/Spinner';
import h from '../../../utilities/helpers';

/**
 * This is the settings tab of the user dashboard
 * It is used to change user settings, including CSS theme, password, and personal information
 */

// CSS theme options
const options = [
    {
        text: 'Default',
        id: 'default'
    },
    {
        text: 'Dark',
        id: 'dark'
    }
];

const allowedExtensions = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'image/bmp', 'image/webp', 'image/svg+xml'];

class Settings extends React.Component{
    constructor(props){
        super();
        this.state = {
            /**
             * working: Boolean - Whether the settings are in the process of being changed
             * loaded: Boolean - Whether the module is loaded (parent needs to calculate container height before loading)
             * avatar: String - URL of user avatar. Defaults to blank
             * avatarName: String - User-supplied file name. Defaults to "Select File"
             * notificationText: String - Text on the bootstrap toast notification
             * savingChanges: Boolean - Whether changes to personal details are in the process of being applied 
             * modalShown: Boolean - Whether the Change Password modal is shown
             * inputs: Array - All of the text inputs
             * badgeSelected: false | String - id of the badge that the user has selected to be displayed when they post
             * imageOptions: Object - The image options that the user can toggle when uploading a new avatar image
             * avatarFetchTimeout: false | Timeout to fetch an avatar image if exists
             * fetchingAvatar: Boolean indicating whether an avatar is in the process of being fetched
             * changingComments: Boolean indicating whether the user is in the process of enabling or disabling comments on their profile page
             */
            working: false,
            loaded: false,
            avatar: `/api/image-id-full/${props.userInfo.avatar}`,
            avatarName: 'Select File',
            avatarFile: '',
            notificationText: '',
            savingChanges: false,
            modalShown: false,
            inputs: userSettings.map(field => ({
                id: field.id,
                error: '',
                invalid: true,
                value: ''
            })),
            badgeSelected: false,
            imageOptions: {
                commentsDisabled: false,
                nsfw: false,
                hidden: false
            },
            avatarFetchTimeout: false,
            fetchingAvatar: false,
            changingComments: false
        }
        this.toastRef = React.createRef(null);
    }

    componentDidMount(){
        /**
         * Checks user info
         */
        
        this.checkUser();
    }

    componentDidUpdate(prevProps, prevState){
        // Checks user info when component updates
        //if (!prevState.loaded && this.state.loaded) document.getElementById('form_settings').addEventListener('submit', this.saveChanges);
        this.checkUser();
    }

    checkUser = () => {
        /**
         * Sets the avatar into state after user info is loaded
         */
        if (this.props.userInfo.username && !this.state.loaded & !this.state.working) this.setState({
            ...this.state,
            loaded: true,
            avatar: `/api/image-id-full/${this.props.userInfo.avatar}`,
            inputs: this.state.inputs.map(input => ({
                ...input,
                value: this.props.userInfo[input.id],
                invalid: false
            })),
            badgeSelected: this.props.userInfo.badge
        });
    }

    changeCSSTheme = e => {
        axios.post('/dashboard/css', {
            // Requests that the user's css theme be changed, then sets the updated user object into the application
            theme: e.value
        }).then(res => {
            this.props.set_user(res.data);
            this.setState({
                ...this.state,
                notificationText: 'Site theme changed'
            }, () => this.toastRef.current.click());
        }).catch(err => {
            console.log(err);
            alert('An error occurred while changing the css theme. Please try again later.');
        });
    } 
    
    /**
     * 
     * @param {Event} e - Javascript event
     * 
     * Fired when the user toggles any of the options when changing their avatar
     * Sets those changes into state
     */
    changeImageOptions = e => this.setState({
        ...this.state,
        imageOptions: {
            ...this.state.imageOptions,
            [e.target.name]: e.target.checked
        }
    });

    /**
     * Fired when the user clicks their avatar
     * 
     * Creates a virtual file input
     * Adds a change event that sets the selected file into state
     * Appends to document body (necessary for iDevices and possibly others)
     * Clicks the input
     * Removes the input after the file is selected
     */
    selectAvatar = () => {
        let input = document.createElement('input');
        input.type = 'file';
        input.style.visibility = "hidden";
        input.style.position = "fixed";
        document.body.appendChild(input);
        input.onchange = e => {
            clearTimeout(this.state.avatarFetchTimeout);
            let file = e.target.files[0];
            if (allowedExtensions.indexOf(file.type) !== -1){
                if (file.size < 15000001){
                    this.setState({
                        ...this.state,
                        avatarName: e.target.files[0].name,
                        avatarFile: e.target.files[0],
                        avatar: URL.createObjectURL(e.target.files[0]),
                        inputs: this.state.inputs.map(input => {
                            if (input.id === 'avatar') return {
                                ...input,
                                value: ''
                            } 
                            else return input
                        })
                    }, () => document.body.removeChild(input));
                } else {
                    document.body.removeChild(input);
                    alert('Your file is too big (Max: 15MB)');
                }
            } else {
                document.body.removeChild(input);
                alert('Please select a valid image file (png, jpg, gif, bmp, webp)');
            }
        }
        input.click();
    }

    saveChanges = () => {
        /**
         * Make sure changes are not already in the process of being saved
         * Remove Invalid Feedback from previous unsuccessful attempts, if any
         * Create object from user input
         * Validate the object
         * If object is invalid, add Invalid Feedback for all invalid entries
         * Create form data object, send to server
         * If successful, set new user object into application state, then spawn bootstrap Toast notification indicating changes were saved successfully
         * If unsuccessful, if session invalid, hide modal, logout user, and route to /login
         * If unsuccessful and session is valid, alert general error
         */
        document.getElementById('form_settings').classList.add('was-validated');
         let invalidInputs = this.state.inputs.filter(input => input.invalid);
         invalidInputs.forEach(input => document.getElementById(input.id).setCustomValidity('hello'));
         if (!this.state.working && !invalidInputs.length) this.setState({
             ...this.state,
             working: true
         }, () => {
             const data = Object.fromEntries(this.state.inputs.map(input => [input.id, input.value]));
             try {
                user_update_schema.validateSync(data, {
                     abortEarly: false
                 });
                 const fd = new FormData();
                 for ( const key in data ) {
                     fd.append(key, data[key]);
                 }
                 if (this.state.avatarFile){
                    fd.append('commentsDisabled', this.state.imageOptions.commentsDisabled);
                    fd.append('nsfw', this.state.imageOptions.nsfw);
                    fd.append('hidden', this.state.imageOptions.hidden);
                    fd.append('image', this.state.avatarFile, this.state.avatarName);
                }
                 fd.append('badge', this.state.badgeSelected);
                 axios.post('/dashboard/update-user', fd).then(res => {
                    this.setState({
                        ...this.state,
                        working: false,
                        notificationText: 'User information updated'
                    }, () => {
                        if (res.data.error) alert(res.data.error);
                        else this.setState({
                            ...this.state,
                            inputs: this.state.inputs.map(input => {
                                if (input.id === 'avatar') return {
                                    ...input,
                                    value: res.data.avatar
                                }
                                else return input
                            }),
                            avatarName: 'Select File',
                            avatarFile: ''
                        }, () => {
                            this.props.set_user(res.data)
                            this.toastRef.current.click();
                        });
                    });
                }).catch(err => this.setState({
                    ...this.state,
                    working: false
                }, () => {
                    console.log(err);
                    alert('An error occurred. Please try again later');
                }));
             } catch(err){
                 this.setState({
                     ...this.state,
                     working: false
                 }, () => {
                     console.log(err);
                     alert('An error occurred. Please try again later');
                 });
             }
        });
    }

    changePassword = () => this.setState({
        /**
         * Fired when the user clicks "Change Password"
         * Spawns the Change Password modal
         * Sets the notification text accordingly
         */
        ...this.state,
        modalShown: true,
        notificationText: 'Password changed successfully'
    });

    setShowModal = option => this.setState({
        ...this.state,
        modalShown: option
    });

    toggleShowModal = () => this.setState({
        ...this.state,
        modalShown: !this.state.modalShown
    });

    /**
     * 
     * @param {KeyboardEvent} e - Keyboard event triggered by text change in any of the text inputs
     * 
     * Sets the updated values into state
     * Validates the inputs
     * Updates the inputs with errors
     * Adds/removes custom validity as appropriate
     */
    changeHandler = e => {
        if (e.target.name === 'avatar') clearTimeout(this.state.avatarFetchTimeout);
        this.setState({
            ...this.state,
            inputs: this.state.inputs.map(input => {
                if (input.id === e.target.name) return {
                    ...input,
                    value: e.target.value
                }
                else return input
            }),
            fetchingAvatar: e.target.name === 'avatar',
            avatar: e.target.name === 'avatar' ? `/api/image-id-full/${e.target.value}` : this.state.avatar,
            avatarName: e.target.name === 'avatar' ? 'Click to Change' : this.state.avatarName,
            avatarFile: e.target.name === 'avatar' ? '' : this.state.avatarFile
        }, () => {
            const data = Object.fromEntries(this.state.inputs.map(input => [input.id, input.value]));
            try {
                user_update_schema.validateSync(data, {
                    abortEarly: false
                });
                this.setState({
                    ...this.state,
                    inputs: this.state.inputs.map(input => {
                        document.getElementById(input.id).setCustomValidity('');
                        return {
                            ...input,
                            invalid: false,
                            error: ''
                        }
                    }),
                    avatarFetchTimeout: e.target.name === 'avatar' ? setTimeout(this.fetchAvatar, 250) : this.state.avatarFetchTimeout
                });
            } catch(err){
                let errorsAdded = [];
                this.setState({
                    ...this.state,
                    inputs: this.state.inputs.map(input => {
                        if (err.inner.find(error => error.path === input.id) && errorsAdded.indexOf(input.id) === -1){
                            errorsAdded.push(input.id);
                            return {
                                ...input,
                                invalid: true,
                                error: err.inner.find(error => error.path === input.id).message
                            }
                        } 
                        else return {
                            ...input,
                            invalid: false,
                            error: ''
                        };
                    }),
                    avatarFetchTimeout: e.target.name === 'avatar' ? setTimeout(this.fetchAvatar, 250) : this.state.avatarFetchTimeout
                }, () => {
                    this.state.inputs.forEach(input => {
                        if (input.invalid) document.getElementById(input.id).setCustomValidity('hello');
                        else document.getElementById(input.id).setCustomValidity('');
                    });
                });
            }
        });
    } 

    /**
     * Changes the user's avatar to the one that was entered into the text box
     * 
     */
    fetchAvatar = () => this.setState({
        ...this.state,
        fetchingAvatar: false,
        avatar: `/api/image-id-full/${this.state.inputs.find(i => i.id === 'avatar').value}`
    });

    pressEnter = e => {
        /**
         * Submit the form if the user presses the enter key while in one of the inputs
         */
        if (e.key === 'Enter') this.saveChanges();
    }

    selectBadge = badge => this.setState({
        ...this.state,
        badgeSelected: badge
    });

    /**
     * Fired when the user clicks "Enable/Disable Profile Messages"
     * Opens or closes the comment section on the user's profile
     */
    changeProfilePage = () => {
        if (!this.state.changingComments) this.setState({
            ...this.state,
            changingComments: true
        }, () => axios.get('/dashboard/change-profile-comments').then(res => {
            this.props.set_user(res.data.userInfo);
            this.setState({
                ...this.state,
                changingComments: false,
                notificationText: (res.data.userInfo.profileCommentsDisabled) ? 'User Profile Messages Disabled' : 'User Profile Messages Enabled'
            }, () => this.toastRef.current.click());
        }).catch(err => this.setState({
            ...this.state,
            changingComments: false
        }, () => {
            console.log(err);
            alert('An error occurred. Please try again later');
        })));
    }

    render(){
        return (
            <div className="h-100 d-flex flex-column py-3">
                <MDBBtn id="toast-trigger-settings" className="d-none" color="primary" ref={this.toastRef}>Toast</MDBBtn>
                <ChangePasswordModal toastRef={this.toastRef.current} modalShown={this.state.modalShown} setShowModal={this.setShowModal} toggleShowModal={this.toggleShowModal} />
                {this.state.loaded ?
                <MDBContainer fluid className="fg-1 px-1 settings-container">
                    <ToastMisc triggerRef={this.toastRef} id={"settings-toast"} icon={<i className="fas fa-user-check text-success fa-lg"></i>} text={this.state.notificationText} />
                    <div className="row h-100 overflow-y-auto mx-0">
                        <div className="col-12 col-lg-3">
                            <div id="dashboard-buttons-container">
                                <MDBBtn block size="lg" onClick={this.changePassword} style={{background: 'var(--mdb-indigo)'}} className="my-3 dashboard-settings-buttons">
                                    <i className="fas fa-key me-2"></i>
                                    Change Password
                                </MDBBtn>
                                {this.props.userInfo.profileCommentsDisabled ?
                                <MDBBtn block size="lg" onClick={this.changeProfilePage} style={{background: 'var(--mdb-pink)'}} className="mb-4 dashboard-settings-buttons">
                                    <i className="fas fa-comments me-2"></i>
                                    Enable Profile Page Messages
                                </MDBBtn> :
                                <MDBBtn block size="lg" onClick={this.changeProfilePage} color="dark" className="mb-4 dashboard-settings-buttons">
                                    <i className="fas fa-comment-slash me-2"></i>
                                    Disable Profile Page Messages
                                </MDBBtn>}
                            </div>
                            <div className="form-floating">
                                <MDBSelect 
                                    label="Site Theme"
                                    size="lg"
                                    data={options.map(option => ({
                                        text: option.text,
                                        value: option.id,
                                        defaultSelected: option.id === this.props.userInfo.userSettings.theme
                                    }))}
                                    onValueChange={this.changeCSSTheme}
                                    className="mt-2"
                                />
                            </div> 
                        </div>
                        <div className="col-12 col-lg-9 mt-2 border-settings">
                            <MDBContainer fluid className="px-0">
                                <hr id="dashboard-hr"></hr>
                                <MDBAlert 
                                    className="cursor-pointer mb-4 ms-auto w-max-content" 
                                    show 
                                    onClick={() => this.props.imageNav(`/u/${this.props.userInfo.username}`)} 
                                    color="primary"
                                    id="dashboard-profile-mobile"
                                >
                                    <i className="fas fa-user me-2"></i>
                                    Profile Page
                                    <i className="fas fa-chevron-right ms-2"></i>
                                </MDBAlert>
                                <div className="row mx-0">
                                    <MDBValidation className="col-12 col-xl-6" id="form_settings" name="form_settings" method="dialog">
                                        {userSettings.filter(setting => setting.id !== 'avatar').map(i => (
                                            <MDBValidationItem key={i.id} feedback={this.state.inputs.find(input => input.id === i.id).error} invalid={true} >
                                                {i.type === 'input' ?
                                                <MDBInput
                                                    name={i.id}
                                                    onChange={this.changeHandler}
                                                    id={i.id}
                                                    label={i.text}
                                                    size="lg"
                                                    className={!this.state.inputs.find(input => input.id === i.id).invalid ? 'mb-0' : 0}
                                                    onKeyPress={this.pressEnter}
                                                    defaultValue={this.props.userInfo[i.id]}
                                                /> :
                                                <MDBTextArea
                                                    name={i.id}
                                                    onChange={this.changeHandler}
                                                    id={i.id}
                                                    label={i.text}
                                                    size="lg"
                                                    className={`mt-4 ${!this.state.inputs.find(input => input.id === i.id).invalid ? 'mb-0' : 0}`}
                                                    onKeyPress={this.pressEnter}
                                                    defaultValue={this.props.userInfo[i.id]}
                                                />}
                                            </MDBValidationItem>
                                        ))}
                                        <h4 className="mt-4">Display Picture</h4>
                                        <p className="mb-1" onClick={this.selectAvatar} style={{cursor: 'pointer', color: 'var(--mdb-gray)'}}>{this.state.avatarName}</p>
                                        <div className="d-flex">
                                            <div>
                                                {this.state.fetchingAvatar ? 
                                                    <div className="d-flex square-15 justify-content-center align-items-center mx-auto">
                                                        <MDBSpinner grow size="lg" color="primary"></MDBSpinner>
                                                    </div> : 
                                                    <MDBRipple tag="span" rippleColor="primary">
                                                        <div style={{cursor: 'pointer'}} onClick={this.selectAvatar} className="border border-dark p-3 d-flex justify-content-center align-items-center square-15 mx-auto">
                                                            <div className="fit-images" style={{backgroundImage: `url("${this.state.avatar}")`}}></div>
                                                        </div>
                                                    </MDBRipple>
                                                }
                                                <MDBInput
                                                    name={'avatar'}
                                                    onChange={this.changeHandler}
                                                    id='avatar'
                                                    label='avatar'
                                                    min="1"
                                                    type="number"
                                                    className={`comment-inputs mt-4 ${!this.state.inputs.find(input => input.id === 'avatar').invalid ? 'mb-0' : 0}`}
                                                    value={this.state.inputs.find(input => input.id === 'avatar').value}
                                                />
                                            </div>
                                            {this.state.avatarFile ?
                                            <div className="ms-2">
                                                <MDBCheckbox 
                                                    onChange={this.changeImageOptions} 
                                                    checked={this.state.imageOptions.commentsDisabled} 
                                                    className="check-commentsDisabled" 
                                                    id="check-commentsDisabled-main" 
                                                    name="commentsDisabled" 
                                                    label="Disable Comments" 
                                                    labelClass="mb-0"
                                                />
                                                <MDBCheckbox 
                                                    className="check-nsfw" 
                                                    id="check-nsfw-main" 
                                                    onChange={this.changeImageOptions} 
                                                    checked={this.state.imageOptions.nsfw} 
                                                    name="nsfw" 
                                                    label="Mark NSFW" 
                                                    labelClass="mb-0"
                                                />
                                                <MDBCheckbox 
                                                    className="check-hidden" 
                                                    id="check-hidden-main" 
                                                    onChange={this.changeImageOptions} 
                                                    checked={this.state.imageOptions.hidden} 
                                                    name="hidden" 
                                                    label="Hide from Browse" 
                                                    labelClass="mb-0"
                                                /> 
                                            </div> :
                                            <></>}
                                        </div>
                                        
                                    </MDBValidation>
                                    <div className="col-12 col-lg-6">
                                        <div id="dashboard-profile-badge" className="d-flex justify-content-between align-items-start">
                                            <div>
                                                <h5>Post As:</h5>
                                                <MDBBtnGroup className="d-flex flex-column shadow-none">
                                                    {h.checkChadmin(this.props.userInfo) ?
                                                    <MDBRadio
                                                        btn
                                                        btnColor='link'
                                                        id='post-as-Chadmin'
                                                        name='post-as'
                                                        className="w-100"
                                                        labelClass={`text-unset px-4 py-3 ${this.state.badgeSelected === 'Chadmin' ? 'shadow-4-strong' : ''}`}
                                                        wrapperClass="mt-4"
                                                        onClick={() => this.selectBadge('Chadmin')}
                                                        label={
                                                            <h5>
                                                                <span className="name-chadmin">
                                                                    <span style={{color: 'var(--mdb-yellow)'}}>
                                                                        {this.props.userInfo.username}
                                                                    </span>
                                                                    <MDBBadge className="ms-2 badge-chadmin">
                                                                        <div className="d-flex align-items-center">
                                                                            <div style={{height: '1.6em', width: '1.6em'}} className="d-flex justify-content-center align-items-center">
                                                                                <div className="fit-images" style={{backgroundImage: `url("/assets/images/meltrans.png")`}}></div>
                                                                            </div>
                                                                            <p className="m-0">Chadmin</p>
                                                                            {this.props.userInfo.oldfag ?
                                                                            <div className="position-relative">
                                                                                <MDBBadge className="badge-oldfag" color='danger' notification pill>
                                                                                    Oldfag
                                                                                </MDBBadge>
                                                                            </div> : <></>}
                                                                        </div>
                                                                    </MDBBadge>
                                                                </span>
                                                            </h5>
                                                        }
                                                    /> : <></>}
                                                    {h.checkJanny(this.props.userInfo) ?
                                                    <MDBRadio
                                                        btn
                                                        btnColor='link'
                                                        id='post-as-Janny'
                                                        name='post-as'
                                                        className="w-100"
                                                        labelClass={`text-unset px-4 py-3 ${this.state.badgeSelected === 'Janny' ? 'shadow-4-strong' : ''}`}
                                                        wrapperClass="mt-4"
                                                        onClick={() => this.selectBadge('Janny')}
                                                        label={
                                                            <h5>
                                                                <span className="name-janny">
                                                                    <span style={{color: 'var(--mdb-green)'}}>
                                                                        {this.props.userInfo.username}
                                                                    </span>
                                                                    <MDBBadge className="ms-2 badge-janny">
                                                                        <div className="d-flex align-items-center">
                                                                            <div style={{height: '1.6em', width: '1.6em'}} className="d-flex justify-content-center align-items-center">
                                                                                <div className="fit-images" style={{backgroundImage: `url("/assets/images/thomastrans.png")`}}></div>
                                                                            </div>
                                                                            <p className="m-0">Janny</p>
                                                                            {this.props.userInfo.oldfag ?
                                                                            <div className="position-relative">
                                                                                <MDBBadge className="badge-oldfag" color='danger' notification pill>
                                                                                    Oldfag
                                                                                </MDBBadge>
                                                                            </div> : <></>}
                                                                        </div>
                                                                    </MDBBadge>
                                                                </span>
                                                            </h5>
                                                            
                                                        }
                                                    /> : <></>}
                                                    <MDBRadio
                                                        btn
                                                        btnColor='link'
                                                        id='post-as-Verified'
                                                        name='post-as'
                                                        className="w-100"
                                                        labelClass={`text-unset px-4 py-3 ${this.state.badgeSelected === 'Verified' ? 'shadow-4-strong' : ''}`}
                                                        wrapperClass="mt-4"
                                                        onClick={() => this.selectBadge('Verified')}
                                                        label={
                                                            <h5>
                                                                <span className="name-verified">
                                                                    <span style={{color: 'rgb(28, 202, 255)'}}>
                                                                        {this.props.userInfo.username}
                                                                    </span>
                                                                    <MDBBadge className="ms-2 badge-verified">
                                                                        <div className="d-flex align-items-center">
                                                                            <div style={{height: '1.6em', width: '1.6em'}} className="d-flex justify-content-center align-items-center">
                                                                                <div className="fit-images" style={{backgroundImage: `url("/assets/images/verifiedlogotrans.png")`}}></div>
                                                                            </div>
                                                                            <p className="m-0">Verified</p>
                                                                            {this.props.userInfo.oldfag ?
                                                                            <div className="position-relative">
                                                                                <MDBBadge className="badge-oldfag" color='danger' notification pill>
                                                                                    Oldfag
                                                                                </MDBBadge>
                                                                            </div> : <></>}
                                                                        </div>
                                                                    </MDBBadge>
                                                                </span>
                                                            </h5>  
                                                        }
                                                    />
                                                    <MDBRadio
                                                        btn
                                                        btnColor='link'
                                                        id='post-as-Anonymous'
                                                        name='post-as'
                                                        className="w-100"
                                                        labelClass={`text-unset px-4 py-3 ${!this.state.badgeSelected ? 'shadow-4-strong' : ''}`}
                                                        wrapperClass="mt-4"
                                                        onClick={() => this.selectBadge(false)}
                                                        label={
                                                            <h5 className="text-default">
                                                                <span className="text-secondary">Anonymous</span> (ID: <span className="text-light" style={{backgroundColor: `var(${this.props.userInfo.poster_css_color})`}}>{this.props.userInfo.poster_id}</span>)
                                                            </h5>  
                                                        }
                                                    />
                                                </MDBBtnGroup>
                                            </div> 
                                            <MDBAlert 
                                                className="cursor-pointer mb-0" 
                                                show 
                                                onClick={() => this.props.imageNav(`/u/${this.props.userInfo.username}`)} 
                                                color="primary"
                                                id="dashboard-profile-desktop"
                                            >
                                                <i className="fas fa-user me-2"></i>
                                                Profile Page
                                                <i className="fas fa-chevron-right ms-2"></i>
                                            </MDBAlert>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className="row mx-0">
                                    <div id="dashboard-save-changes" className="col-12 my-4 d-flex">
                                        {this.state.working ?
                                        <MDBBtn size="lg" disabled color="success" className="btn-save"><Spinner size='sm' className='me-2' />Saving</MDBBtn> :
                                        <MDBBtn size="lg" onClick={this.saveChanges} color="success" className="btn-save"><i className="fas fa-save me-2"></i>Save Changes</MDBBtn>}
                                    </div>
                                </div>
                            </MDBContainer>
                        </div>
                    </div>
                </MDBContainer> :
                <div className="mt-5 w-100 d-flex justify-content-center">
                    <MDBSpinner style={{ width: '3.5rem', height: '3.5rem' }} grow color="primary" role='status' tag='span'/>
                </div>}
                
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
  }
  
  export default connect(mapStateToProps, { set_user, purge_user })(Settings);
// Contains data for all form validations
import * as y from 'yup';

function checkWhiteSpace(string){
    return this.test('checkWhiteSpace', string, function(value){
        const { path, createError } = this;
        if (!value.split('\n').join('').split(' ').join('')) return createError({path, message: 'Please enter a message'});
        return true;
    });
}

y.addMethod(y.string, 'checkWhiteSpace', checkWhiteSpace);

export const user_schema = y.object().shape({
    username: y.string().min(4, 'Username is too short (min: 4 characters)').max(30, 'Username is too long (max: 30 characters)').required('Please enter a username').matches(/^[a-zA-Z0-9-_]+$/, 'Username must be alphanumeric'),
    email: y.string().min(4, 'Email is too short (min: 4 characters)').max(256, 'Email is too long (max: 256 characters)').email('Invalid email').required('Please enter an email address'),
    password1: y.string().min(4, 'Password is too short (min: 4 characters)').max(256, 'Password is too long (max: 256 characters)').required('Please enter a password'),
    password2: y.string().oneOf([y.ref('password1'), null], 'Passwords must match').required('Please confirm your password'),
    bio: y.string().max(1000, 'Bio is too long (Max: 1000 chars)')
});

export const edit_chadmin_schema = y.object().shape({
    username: y.string().min(4, 'Username is too short (min: 4 characters)').max(30, 'Username is too long (max: 30 characters)').required('Please enter a username').matches(/^[a-zA-Z0-9-_]+$/, 'Username must be alphanumeric'),
    email: y.string().min(4, 'Email is too short (min: 4 characters)').max(256, 'Email is too long (max: 256 characters)').email('Invalid email').required('Please enter an email address'),
    comments: y.string().max(500, 'Comments are too long (max: 500 characters)')
});

export const user_schema_chadmin = y.object().shape({
    username: y.string().min(4, 'Username is too short (min: 4 characters)').max(30, 'Username is too long (max: 30 characters)').required('Please enter a username').matches(/^[a-zA-Z0-9-_]+$/, 'Username must be alphanumeric'),
    email: y.string().min(4, 'Email is too short (min: 4 characters)').max(256, 'Email is too long (max: 256 characters)').email('Invalid email').required('Please enter an email address'),
    comments: y.string().max(500, 'Comments are too long (max: 500 characters)')
});

export const edit_janny_schema = y.object().shape({
    username: y.string().min(4, 'Username is too short (min: 4 characters)').max(30, 'Username is too long (max: 30 characters)').required('Please enter a username').matches(/^[a-zA-Z0-9-_]+$/, 'Username must be alphanumeric'),
    email: y.string().min(4, 'Email is too short (min: 4 characters)').max(256, 'Email is too long (max: 256 characters)').email('Invalid email').required('Please enter an email address'),
    comments: y.string().max(500, 'Comments are too long (max: 500 characters)')
});

export const user_schema_janny = y.object().shape({
    username: y.string().min(4, 'Username is too short (min: 4 characters)').max(30, 'Username is too long (max: 30 characters)').required('Please enter a username').matches(/^[a-zA-Z0-9-_]+$/, 'Username must be alphanumeric'),
    email: y.string().min(4, 'Email is too short (min: 4 characters)').max(256, 'Email is too long (max: 256 characters)').email('Invalid email').required('Please enter an email address'),
    comments: y.string().max(500, 'Comments are too long (max: 500 characters)')
});

export const edit_verified_schema = y.object().shape({
    username: y.string().min(4, 'Username is too short (min: 4 characters)').max(30, 'Username is too long (max: 30 characters)').required('Please enter a username').matches(/^[a-zA-Z0-9-_]+$/, 'Username must be alphanumeric'),
    email: y.string().min(4, 'Email is too short (min: 4 characters)').max(256, 'Email is too long (max: 256 characters)').email('Invalid email').required('Please enter an email address'),
    comments: y.string().max(500, 'Comments are too long (max: 500 characters)')
});

export const user_schema_verified = y.object().shape({
    username: y.string().min(4, 'Username is too short (min: 4 characters)').max(30, 'Username is too long (max: 30 characters)').required('Please enter a username').matches(/^[a-zA-Z0-9-_]+$/, 'Username must be alphanumeric'),
    email: y.string().min(4, 'Email is too short (min: 4 characters)').max(256, 'Email is too long (max: 256 characters)').email('Invalid email').required('Please enter an email address'),
    comments: y.string().max(500, 'Comments are too long (max: 500 characters)')
});

export const change_password_schema = y.object().shape({
    current_password: y.string().required('Please enter your password'),
    password1: y.string().min(4, 'Password is too short (min: 4 characters)').max(256, 'Password is too long (max: 256 characters)').required('Please enter a new password'),
    password2: y.string().oneOf([y.ref('password1'), null], 'Passwords must match').required('Please confirm your password')
});

export const change_password_uuid_schema = y.object().shape({
    password1: y.string().min(4, 'Password is too short (min: 4 characters)').max(256, 'Password is too long (max: 256 characters)').required('Please enter a new password'),
    password2: y.string().oneOf([y.ref('password1'), null], 'Passwords must match').required('Please confirm your password')
});

export const forgot_password_schema = y.object().shape({
    username: y.string().required('Please enter your username'),
    email: y.string().required('Please enter your email address')
});

export const user_update_schema = y.object().shape({
    email: y.string().min(4, 'Email is too short (min: 4 characters)').max(256, 'Email is too long (max: 256 characters)').email('Invalid email').required('Please enter an email address'),
    bio: y.string().max(1000, 'Bio is too long (Max: 1000 chars)')
});

export const login_schema = y.object().shape({
    username: y.string().required('Please enter a username'),
    password: y.string().required('Please enter a password')
});

export const contact_form_schema = y.object().shape({
    email: y.string().max(256, 'Email is too long (max: 256 characters)').email('Invalid email').required('Please enter an email address'),
    name: y.string().max(50, 'Name is too long (max: 50 characters)').required('Please enter your name'),
    subject: y.string().max(150, 'Subject is too long (max: 150 characters)').required('Please enter a subject'),
    message: y.string().max(10000, 'Message is too long (max: 10000 characters)').required('Please enter a message'),
});

export const image_schema = y.object().shape({
    name: y.string().max(50, 'Name is too long (max: 50 characters)'),
    manifesto: y.string().max(10000, 'Manifesto is too long (max: 10000 characters)'),
});

export const comment_schema = y.object().shape({
    name: y.string().max(50, 'Name is too long (max: 50 characters)'),
    body: y.string().max(10000, 'Body is too long (max: 10000 characters)').required('Please enter a message').checkWhiteSpace('Please enter a message'),
});

export const report_schema = y.object().shape({
    details: y.string().max(200, 'Report reason is too long (max: 200 characters)').required('Please enter a reason').checkWhiteSpace('Please enter a reason'),
});
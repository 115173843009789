import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { purge_user, set_user, route } from '../redux/actions';
import './css/navBar.css';
import MobileLogo from './navBar/MobileLogo';
import MobileOnlyNavItems from './navBar/MobileOnlyNavItems';
import MobileLogin from './navBar/MobileLogin';
import DropdownMenuDesktop from './navBar/DropdownMenuDesktop';
import MenuItemsBoth from './navBar/MenuItemsBoth';
import LogoutButtonMobile from './navBar/LogoutButtonMobile';
import DesktopLogo from './navBar/DesktopLogo';
import {
    MDBNavbar,
    MDBContainer,
    MDBIcon,
    MDBNavbarToggler,
    MDBCollapse
  } from 'mdb-react-ui-kit';


/**
 * This is the navbar
 * It currently looks decent on all screen sizes, but the code needs to be cleaned up
 */

class NavBar extends React.Component{
    constructor(){
        super();
        this.state = {
            /**
             * userLoaded: Boolean indicating whether the first user session check has concluded
             */
            userLoaded: false,
            navExpanded: false
        }
    }

    componentDidMount(){
        /**
         * Set bootstrap (need to wait until mount or else it messes up on ssr)
         * Check user session
         * Set the mobile hamburger into state so that it can be closed upon route
         * Set tooltips
         */
        this.checkSession();
    }

    componentDidUpdate(prevProps){
        /**
         * Set tooltips upon login (they won't exist if the navbar is rendered logged out)
         * Change CSS theme if user changes the theme.
         * Check user session on page nav - This is so that changes made to the user by site admins will be reflected without the user having to log out/back in
         */
        if (prevProps.userInfo.userSettings.theme !== this.props.userInfo.userSettings.theme){
            let links = document.head.getElementsByTagName('link');
            for (let i = 0; i < links.length; i++){
                if (links[i].href.split('/bootstrap/').length > 1) links[i].href = links[i].href.split('/bootstrap/')[0] + `/bootstrap/${this.props.userInfo.userSettings.theme}.css`;
            }
        }
        if (prevProps.history.location.pathname !== this.props.history.location.pathname) this.checkSession();
    }

    checkSession = () => axios.get('/auth/check-user').then(res => {
        /**
         * Check user, set into state
         * If the JSON user object from the first load is still present on the page, remove it
         */
        this.props.set_user(res.data);
        if (document.getElementById('user-info-server')) document.getElementById('user-info-server').remove();
        if (!this.state.userLoaded) this.setState({
            ...this.state,
            userLoaded: true
        });
    } ).catch(err => console.log(err));

    logout = (redirect) => {
        /**
         * Collapse nav hamburger if it exists
         * Logout
         * Purge user
         * Navigate to Login page if redirect set to true
         */
        if (window.innerWidth < 992){
            this.collapseNav();
        }
        axios.post('/auth/logout').then(res => {
            this.props.purge_user();
            if (redirect) this.props.route('/login');
        }).catch(err => {
            console.log(err);
            this.props.purge_user();
        });
    }

    mobileNav = (route) => {
        /**
         * Collapse nav hamburger if it exists
         * Nav to destination
         */
        if (window.innerWidth < 992) this.collapseNav();
        this.props.route(route);
    } 

    collapseNav = () => this.setState({
        ...this.state,
        navExpanded: false
    });

    toggleNav = () => this.setState({
        ...this.state,
        navExpanded: !this.state.navExpanded
    });

    render(){
        return (
            <MDBNavbar light expand='lg' id="div-nav-container" className="container-fluid px-0 navbar navbar-bg w-100">
                <div className="row mx-auto w-100">
                    <MobileLogo mobileNav={this.mobileNav} />
                    <div id="nav-column" className="col-lg-12 col-6 d-flex flex-column justify-content-center">
                        <MDBNavbar style={{boxShadow: 'none'}} expand='lg' id="nav-container" className="py-2 w-100">
                            <MDBNavbarToggler
                                type='button'
                                data-target='#navbarText'
                                aria-controls='navbarText'
                                aria-expanded='false'
                                aria-label='Toggle navigation'
                                onClick={this.toggleNav}
                                className="ms-auto btn-lg"
                                style={{
                                    background: 'rgba(96, 125, 139, 0.90)',
                                    color: '#eee'
                                }}
                                size="sm"
                            >
                                <MDBIcon icon='bars' size="lg" fas />
                            </MDBNavbarToggler>
                            <MDBCollapse show={this.state.navExpanded} navbar id='navbarText'>
                                <MDBContainer fluid>
                                    <div className="row">
                                        <div className="col-12 col-lg-6 d-flex align-items-center">
                                            <DesktopLogo mobileNav={this.mobileNav} />
                                            <ul className="navbar-nav mb-lg-0, ul-main-nav">
                                                {!this.props.userInfo.username ?
                                                <>
                                                    {typeof document !== 'undefined' && document.getElementById('user-info-server') ?
                                                    <MobileOnlyNavItems history={this.props.history} userInfo={JSON.parse(document.getElementById('user-info-server').textContent)} mobileNav={this.mobileNav} />
                                                        : 
                                                    <>
                                                        {typeof document !== 'undefined' ?
                                                        <MobileLogin history={this.props.history} mobileNav={this.mobileNav} /> : <></>}
                                                    </>
                                                    
                                                    }
                                                </>
                                                : <MobileOnlyNavItems history={this.props.history} userInfo={this.props.userInfo} mobileNav={this.mobileNav} />}
        
                                                <MenuItemsBoth mobileNav={this.mobileNav} history={this.props.history} />

                                                {(this.props.userInfo.username || (typeof document !== 'undefined' && document.getElementById('user-info-server'))) ?
                                                <LogoutButtonMobile logout={this.logout} /> : <></>}

                                            </ul>
                                        </div>
                                        <div className="col-12 col-lg-6 d-flex justify-content-end align-items-center">
                                        {!this.props.userInfo.username ?
                                            <>
                                                {typeof document !== 'undefined' && document.getElementById('user-info-server') ?
                                                <DropdownMenuDesktop userInfo={JSON.parse(document.getElementById('user-info-server').textContent)} mobileNav={this.mobileNav} logout={this.logout} />
                                                    : 
                                                <>
                                                    {typeof document !== 'undefined' ? 
                                                    <a onClick={() => this.mobileNav('/login')} style={{cursor: 'pointer'}} className={`text-end links-generic h6 me-3 mb-0 fw-bold navbar-user-desktop text-nowrap`} >Login / Create Account</a> : <></>}
                                                </>
                                                }
                                            </>
                                            : <DropdownMenuDesktop userInfo={this.props.userInfo} mobileNav={this.mobileNav} logout={this.logout} />    
                                        }
                                        </div>
                                    </div>
                                </MDBContainer>
                            </MDBCollapse>
                        </MDBNavbar>
                    </div>
                </div>                    
            </MDBNavbar>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
}

export default connect(mapStateToProps, { purge_user, set_user, route })(NavBar);
import React from 'react';
import { connect } from 'react-redux';
import { motion } from 'framer-motion';
import t from '../../../utilities/transitions';
import {
    MDBBtn,
    MDBTypography,
    MDBPopover,
    MDBPopoverBody,
    MDBInput
} from 'mdb-react-ui-kit';


class CommentNav extends React.Component{
    constructor(props){
        super();
        /**
         * this.page: Number - The page number that the user is on
         * this.history: Array - A list of all the pages that the user has visited
         * this.mostRecentPage: Number - the last element in the this.history array
         */
        this.page = props.profileCommentsPage;
        this.history = props.profileCommentsStack;
        this.mostRecentPage = this.history[this.history.length - 1];
        this.state = {
            /**
             * popoverOpen: Boolean - Indicates whether the page number select is open
             */
            popoverOpen: false
        }
    }

    /**
     * 
     * @param {MouseEvent} e - Event object from the mousedown event added to document.body when the page selector was opened
     * 
     * Close the page selector
     * Remove the event listener when done
     */
    closePopover = e => {
        if (!(e.target && e.target.classList.contains('profile-comment-popover-triggers'))) this.setState({
            ...this.state,
            popoverOpen: false
        }, () => document.body.removeEventListener('mousedown', this.closePopover));
    } 

    /**
     * Hit when the user taps or clicks the page number
     * Opens the page selector
     * Adds an event listener to close the page selector when the user clicks outside it
     * 
     */
    showPopover = () => this.setState({
        ...this.state,
        popoverOpen: true
    }, () => setTimeout(() => document.body.addEventListener('mousedown', this.closePopover), 500));

    getInitialLeftButtonExit = (mostRecentPage, page) => {
        /**
         * If going back, bob left
         * If coming from another endpoint or going to another end point, minimize
         * If going forward, remain stationary
         */
        if (mostRecentPage){
            if (mostRecentPage > page) return t.bob_left;
            else if (mostRecentPage === 1) return t.fade_out_minimize;
            else return t.normalize;
        } else return t.fade_out_minimize;   
    }

    getInitialRightButtonExit = (mostRecentPage, page, props) => {
        /**
         * If going back, bob left
         * If coming from another endpoint or going to another end point, minimize
         * If going forward, remain stationary
         */
        if (mostRecentPage){
            if (mostRecentPage < page) return t.bob_right;
            else return t.normalize;
        } else return t.fade_out_minimize;   
    }

    pressEnter = e => {
        /**
         * Submit the form if the user presses the enter key while in one of the inputs
         */
        if (e.key === 'Enter') this.props.go(this.props.index);
    }

    render(){
        return (
            <>
                <div className="d-flex align-items-center my-4 mx-auto justify-content-center">
                    {this.page !== 1 ?
                    <motion.span transition={t.transition} exit={this.props.leftButtonExit} animate={t.normalize} initial={this.props.leftButtonExit} >
                        <MDBBtn size="lg" onClick={this.props.back} style={{background: 'var(--mdb-indigo)'}} className="d-block"><i className="fas fa-chevron-left"></i></MDBBtn>
                    </motion.span>
                        : 
                    <MDBBtn size="lg" className="invis"><i className="fas fa-chevron-left"></i></MDBBtn>}
                    <MDBPopover 
                        color="link" 
                        placement="top"
                        btnChildren={
                            <div className="mx-4 profile-comment-popover-triggers">
                                <MDBTypography tag="small" className="text-center text-default profile-comment-popover-triggers mx-auto">Page</MDBTypography>
                                <h4 className="display-6 text-center my-0 text-default profile-comment-popover-triggers">{this.props.page}</h4>
                            </div>
                        }
                        btnClassName="text-unset px-2 mx-2 profile-comment-popover-triggers"
                        className="mb-2 w-max-content max-w-max-content profile-comment-popover-triggers"
                        rippleColor="primary"
                        onShow={this.showPopover}
                        isOpen={this.state.popoverOpen}
                    >
                        <MDBPopoverBody className="profile-comment-popover-triggers">
                            <div className="d-flex align-items-center profile-comment-popover-triggers">
                                <div className="mx-2 profile-comment-popover-triggers">
                                    <div className="d-flex justify-content-center profile-comment-popover-triggers">
                                        <MDBInput 
                                            min="1" 
                                            max={this.props.totalProfileCommentsPages}
                                            defaultValue={this.page} 
                                            label="Page" 
                                            type="number" 
                                            id={`profile-comment-page-number-${this.props.index}`}
                                            style={{
                                                width: '5rem'
                                            }}
                                            onKeyPress={this.pressEnter}
                                            className="profile-comment-popover-triggers"
                                        />
                                    </div>
                                    <MDBBtn onClick={() => this.props.go(this.props.index)} className="d-block mx-auto mt-2" color="success">Go</MDBBtn>
                                </div>
                            </div>
                        </MDBPopoverBody>
                    </MDBPopover>
                    {this.page !== this.props.totalProfileCommentsPages ?
                    <motion.span transition={t.transition} exit={this.props.rightButtonExit} animate={t.normalize} initial={this.props.rightButtonExit}>
                        <MDBBtn size="lg" onClick={this.props.next} style={{background: 'var(--mdb-indigo)'}} className="d-block"><i className="fas fa-chevron-right"></i></MDBBtn>
                    </motion.span> : 
                    <MDBBtn size="lg" className="invis"><i className="fas fa-chevron-right"></i></MDBBtn>}
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
  }
  
  export default connect(mapStateToProps, { })(CommentNav);
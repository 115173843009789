import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { purge_user, route } from '../../../redux/actions';
import h from '../../../utilities/helpers';
import MessageModal from './messages/MessageModal';
import {
    MDBContainer,
    MDBBtn,
    MDBSpinner
} from 'mdb-react-ui-kit';

const itemsPerPage = 40;

/**
 * This is the Messages menu on the dashboard
 * The user can view messages submitted through the contact form here
 * Messages are arranged on a table with 40 messages per page
 */

class Messages extends React.Component{
    constructor(){
        super();
        this.state = {
            /**
             * messages: Array containing all of the contact form messages
             * loaded: Boolean indicating whether the contact form messages have been loaded from the server
             * message: Object, the selected contact form message, if any
             * modal: Bootstrap modal that pops up when the user selects a message
             * page: Number, current page number
             * maxPages: Number, maximum number of pages that display messages
             * sortBy: String, sort basis
             * sortDirection, String, sort direction, 'ascending' | 'descending'
             */
            messages: [],
            loaded: false,
            messageSelected: {},
            modalShown: false,
            page: 1,
            maxPages: 1,
            sortBy: 'date',
            sortDirection: 'descending'
        }
    }

    componentDidMount(){
        /**
         * Set bootstrap equal to bootstrap window object 
         * Must do this or else SSR will crash as window object does not exist on the server
         * Get the contact form messages from the server
         * Set into state, or throw an error
         * If error and user has no session, logout and redirect to login page
         */
        this.load();
    }

    load = () => {
        axios.get('/contact/messages').then(res => this.setState({
            ...this.state,
            messages: res.data.messages,
            maxPages: Math.ceil(res.data.messages.length / itemsPerPage),
            loaded: true
        }, () => console.log(res.data))).catch(err => {
            console.log(err);
            if (err.response && err.response.status === 401) {
                this.props.purge_user();
                this.props.route('/login');
                alert('Your session has expired');
            } else setTimeout(this.load, 2000);
        });
    }


    selectMessage = message => this.setState({
        /**
         * Fired when the user selects a message
         * Set message into state
         * Spawn the modal with message details
         */
        ...this.state,
        messageSelected: message,
        modalShown: true
    });

    changePage = increment => this.setState({
        /**
         * Fired when the user clicks one of the page arrows
         * Changes the page
         */
        ...this.state,
        page: increment ? this.state.page + 1 : this.state.page - 1
    });

    sortMessages = messages => {
        /**
         * Receives the messages array, then sorts the messages based on the sort basis
         */
        switch(this.state.sortBy){
            case 'name':
                if (this.state.sortDirection === 'ascending') return messages.sort((a, b) => a.name.localeCompare(b.name));
                else return messages.sort((b, a) => a.name.localeCompare(b.name));
            case 'email':
                if (this.state.sortDirection === 'ascending') return messages.sort((a, b) => a.email.localeCompare(b.email));
                else return messages.sort((b, a) => a.email.localeCompare(b.email));
            case 'date':
                if (this.state.sortDirection === 'ascending') return messages.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
                else return messages.sort((b, a) => new Date(a.timestamp) - new Date(b.timestamp));
            case 'subject':
                if (this.state.sortDirection === 'ascending') return messages.sort((a, b) => a.subject.localeCompare(b.subject));
                else return messages.sort((b, a) => a.subject.localeCompare(b.subject));
        }
        return messages;
    }

    setSort = sort => {
        /**
         * Fired when the user clicks one of the table headers
         * If messages are already sorted by the corresponding header, change the sort direction
         * Else, sort by the corresponding header
         */
        if (sort === this.state.sortBy) this.setState({
            ...this.state,
            sortDirection: this.state.sortDirection === 'ascending' ? 'descending' : 'ascending'
        });
        else this.setState({
            ...this.state,
            sortBy: sort
        });
    }

    setShowModal = option => this.setState({
        ...this.state,
        modalShown: option
    });

    toggleShowModal = () => this.setState({
        ...this.state,
        modalShown: !this.state.modalShown
    });

    render(){
        return (
            <div className="h-100 d-flex flex-column py-3">
                <MessageModal toggleShowModal={this.toggleShowModal} setShowModal={this.setShowModal} modalShown={this.state.modalShown} message={this.state.messageSelected}/>
                {this.state.loaded ?
                <>
                    {this.state.maxPages > 1 ?
                    <MDBContainer fluid className="px-0">
                        <div className="row mx-0">
                            <div className="col-4 d-flex justify-content-end">
                                {this.state.page !== 1 ?
                                <MDBBtn onClick={() => this.changePage(false)} color="dark"><i className="fas fa-chevron-left"></i></MDBBtn> : <></>}
                            </div>
                            <div className="col-4">
                                <h5 className="text-center mx-4 display-6">Page {this.state.page}</h5>
                            </div>
                            <div className="col-4">
                                {this.state.page !== this.state.maxPages ?
                                <MDBBtn onClick={() => this.changePage(true)} color="dark"><i className="fas fa-chevron-right"></i></MDBBtn> : <></>}
                            </div>
                        </div>
                    </MDBContainer> : <></>}
                    {this.state.messages.length ?
                    <div className="fg-1 messages-container-dashboard">
                        <div className="h-100 overflow-y-auto px-3 mobile-px-0">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th style={{width: '25%'}} onClick={() => this.setSort('name')} className="table-sticky-head cursor-pointer table-text-sm mobile-px-1" scope="col">Name{this.state.sortBy === 'name' ? 
                                        <i className={`ms-2 fas fa-caret-${this.state.sortDirection === 'ascending' ? 'up' : 'down'}`}></i> : <></>}</th>
                                        <th style={{width: '25%'}} onClick={() => this.setSort('email')} className="table-sticky-head text-center cursor-pointer table-text-sm mobile-px-1" scope="col">Email{this.state.sortBy === 'email' ? 
                                        <i className={`ms-2 fas fa-caret-${this.state.sortDirection === 'ascending' ? 'up' : 'down'}`}></i> : <></>}</th>
                                        <th style={{width: '25%'}} onClick={() => this.setSort('date')} className="table-sticky-head text-center cursor-pointer table-text-sm mobile-px-1" scope="col">Date{this.state.sortBy === 'date' ? 
                                        <i className={`ms-2 fas fa-caret-${this.state.sortDirection === 'ascending' ? 'up' : 'down'}`}></i> : <></>}</th>
                                        <th style={{width: '25%'}} onClick={() => this.setSort('subject')} className="table-sticky-head text-center cursor-pointer table-text-sm mobile-px-1" scope="col">Subject{this.state.sortBy === 'subject' ? 
                                        <i className={`ms-2 fas fa-caret-${this.state.sortDirection === 'ascending' ? 'up' : 'down'}`}></i> : <></>}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {this.sortMessages(this.state.messages.filter((message, i) => {
                                    if ((i >= (this.state.page * itemsPerPage) - 1 - itemsPerPage) && (i < (this.state.page * itemsPerPage))) return true;
                                    else return false
                                })).map(message => (
                                    <tr onClick={() => this.selectMessage(message)} className="table-row-clickable">
                                        <th style={{width: '25%'}} className="table-text-sm mobile-px-1 mobile-text-overflow" scope="row">{h.shortString(message.name)}</th>
                                        <td style={{width: '25%'}} className="text-center table-text-sm mobile-px-1 mobile-text-overflow">{h.shortString(message.email)}</td>
                                        <td style={{width: '25%'}} className="text-center table-text-sm mobile-px-1 mobile-text-overflow">{h.makeDateHR(new Date(message.timestamp))}</td>
                                        <td style={{width: '25%'}} className="text-center table-text-sm mobile-px-1 mobile-text-overflow">{h.shortString(message.subject)}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    
                    : 
                    <h3 className="mt-5 text-center display-6">No contact form messages found</h3>}
                </>:
                <div className="d-flex justify-content-center mt-5">
                    <MDBSpinner style={{ width: '3.5rem', height: '3.5rem' }} color="primary" grow role='status' tag='span'/>
                </div>}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
  }
  
  export default connect(mapStateToProps, { purge_user, route })(Messages);
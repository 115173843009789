import React from 'react';
import { connect } from 'react-redux';
import { motion } from 'framer-motion';
import { route } from '../redux/actions';
import t from '../utilities/transitions';
import {
    MDBSpinner
} from 'mdb-react-ui-kit';
import { withRouter } from 'react-router-dom';
import axios from 'axios';

class Comment extends React.Component{
    constructor(props){
        super();
        this.comment_id = props.match.params.id;
    }

    componentDidMount(){
        document.getElementById('root').scrollTop = 0;
        this.load();
    }

    load = () => {
        /**
         * Grab the comment details, then navigate to the comment section where that comment is located
         */
        axios.get(`/api/comment-info/${this.comment_id}`).then(res => {
            if (res.data && res.data.image_id){
                this.props.route(`/image/${res.data.image_id}`);
                window.location.hash = `#comment-${this.comment_id}`;
            } else if (res.data && res.data.user_profile){
                this.props.route(`/u/${res.data.user_profile}`);
                window.location.hash = `#comment-${this.comment_id}`;
            } else this.props.route('/not-found');
        }).catch(err => {
          console.log(err);
          setTimeout(this.load, 1000);  
        });
    }

    render(){
        return (
            <motion.div transition={t.transition} exit={t.fade_out} animate={t.normalize} initial={t.fade_out} className="d-flex justify-content-center mt-4">
                <MDBSpinner color="success" grow style={{ width: '3rem', height: '3rem' }}>
                    <span className='visually-hidden'>Loading...</span>
                </MDBSpinner>
            </motion.div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
  }
  
  export default connect(mapStateToProps, { route })(withRouter(Comment));
import React from 'react';
import { connect } from 'react-redux';
import {
    MDBNavbarItem,
    MDBNavbarLink,
    MDBRipple
} from 'mdb-react-ui-kit';

/**
 * 
 * @param {Function} nav - The navigation function
 * @param {Object} history - The react-router history object
 * @returns Menu items that are present on all screens 
 */
const MenuItemsBoth = ({ mobileNav, history }) => (
    <>
        <MDBRipple rippleColor="light">
            <MDBNavbarItem onClick={() => mobileNav('/browse')} className="nav-item nav-items-collapse rounded">
                <MDBNavbarLink id="nav-browse" className={`nav-link text-default  nav-main-items ${'browse' === history.location.pathname.split('/')[1] ? 'active' : ''}`}>
                    <i style={{color: 'browse' !== history.location.pathname.split('/')[1] ? '#00796B' : '#fff'}} className="fas fa-images fa-lg mx-auto d-block text-center mb-2"></i>
                    Browse
                </MDBNavbarLink>
            </MDBNavbarItem>
        </MDBRipple>
        <MDBRipple rippleColor="light">
            <MDBNavbarItem onClick={() => mobileNav('/info')} className="nav-item nav-items-collapse rounded">
                <MDBNavbarLink id="nav-info" className={`nav-link text-default  nav-main-items ${'info' === history.location.pathname.split('/')[1] ? 'active' : ''}`}>
                    <i style={{color: 'info' !== history.location.pathname.split('/')[1] ? '#1976D2' : '#fff'}} className="fas fa-info-circle fa-lg mx-auto d-block text-center mb-2"></i>
                    Info
                </MDBNavbarLink>
            </MDBNavbarItem>
        </MDBRipple>
    </>
);

const mapStateToProps = (state) => {
    return {
        ...state
    }
}

export default connect(mapStateToProps, {})(MenuItemsBoth);
import React from 'react';
import {
    MDBRipple
} from 'mdb-react-ui-kit';

/**
 * 
 * @param {Function} nav - The navigation function
 * @returns The desktop logo
 */
const DesktopLogo = ({ mobileNav }) => (
    <MDBRipple className="me-3" rippleColor='light' rippleTag='span'>
        <a onClick={() => mobileNav('/')} id="logo-desktop">
            <img id="svg-logo" src="/assets/images/logo.png" alt="Navbar Logo"/>
        </a>
    </MDBRipple>
);

export default DesktopLogo;
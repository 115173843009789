import {
    SET_HISTORY,
    SET_USER,
    PURGE_USER,
    ROUTE,
    SELECT_DASHBOARD_ITEM,
    SET_IMAGE,
    UPDATE_IMAGE,
    UPLOAD_IMAGES,
    UPLOAD_END,
    UPLOAD_PROGRESS,
    ADD_COMMENT,
    SET_IMAGE_COUNT,
    ADD_IMAGE_VIEW,
    UPDATE_COMMENT,
    NEW_IMAGE_RESET,
    SET_SERIES,
    PREVIOUS_COMMENTS,
    NEXT_COMMENTS,
    SET_COMMENT_PAGE,
    SET_IMAGE_PAGE,
    SET_MAX_IMAGE_PAGES,
    SET_IMAGES,
    SET_IMAGE_SORT,
    SELECT_IMAGE,
    SET_SHOW_IMAGE_TOAST,
    SET_REPORT_PAGE,
    SET_MAX_REPORT_PAGES,
    SET_REPORTS,
    SET_REPORT_SORT,
    SELECT_REPORT,
    DASHBOARD_NAV,
    SET_MOD_LOG_PAGE,
    SET_MAX_MOD_LOG_PAGES,
    SET_MOD_LOGS,
    SET_MOD_LOG_SORT,
    SELECT_MOD_LOG,
    SET_PROFILE_CONTENT_PAGE,
    SET_MAX_PROFILE_CONTENT_PAGES,
    SET_PROFILE_CONTENT,
    SET_PROFILE_CONTENT_ALL,
    SET_PROFILE_CONTENT_SORT,
    SELECT_PROFILE_CONTENT,
    SET_PROFILE_COMMENT_PAGE,
    SET_MAX_PROFILE_COMMENT_PAGES,
    SET_PROFILE_COMMENTS,
    SET_PROFILE_COMMENT_SORT,
    SELECT_PROFILE_COMMENT,
    SET_PROFILE_INFO,
    ADD_PROFILE_COMMENT,
    UPDATE_PROFILE_COMMENT,
    SET_NSFW_MODAL
} from './actions';
import React from 'react';

/**
 * Actions take place here 
 * Data is synchronized across all pages and components
 */

const emptyImage = {
    image_id: '',
    user: '',
    manifesto: '',
    filename: '',
    md5: '',
    timestamp: new Date(),
    img_day: '01',
    img_month: '01',
    img_year: '2020',
    comments_disabled: false,
    nsfw: false,
    removed: false,
    removed_reason: '',
    manifesto_removed: false,
    manifesto_removed_reason: '',
    poster_id: '',
    poster_css_color: '',
    views: 0,
    comments: [],
    commentPage: 1,
    commentStack: [],
    imageID: ''
}

const initialState = {
    /**
     * userInfo: Object - The user object
     * historyStack: Array - List of all locations that the user has navigated to
     * dashboardItemSelected: String - Dashboard tab that the user is on/will go to upon visiting the dashboard
     * images_dash: Array - List of images loaded in the Images tab on the dashboard
     * imagePage: Number - Page number that the user is on in the Images tab on the dashboard
     * imageStack: Array - List of all image pages that the user has navigated to in the Images tab on the dashboard
     * totalImagePages: Number - Total image pages in the Images tab on the dashboard
     * imageSortBy: Number - Sort criteria in the Images tab on the dashboard
     * imageSortDirection: Number - Sort direction in the Images tab on the dashboard
     * uploading: Boolean - whether the user is in the process of uploading image(s) to the server
     * uploadProgress: Number - Image percent uploaded
     * imageCount: Number - Total number of images on NanaImg
     * imageInfo: Object - Images document with details added from aggregation
     * alreadyPostedRef: Object - Ref to be clicked when notifying the user that their image has already been posted
     * newImage: false | Object with new Images document that was just uploaded by the user
     * series: Object containing data about a series of images that was just uploaded by the user
     * showImageToast: Possibly unneccesary
     * dashboardNav: Boolean indicating whether the user has temporarily visited a page from the dashboard
     * reports_dash: Array - List of reports loaded in the Reports tab on the dashboard
     * reportPage: Number - Page number that the user is on in the Reports tab on the dashboard
     * reportStack: Array - List of all report pages that the user has navigated to in the Reports tab on the dashboard
     * totalReportPages: Number - Total report pages in the Reports tab on the dashboard
     * reportSortBy: Number - Sort criteria in the Reports tab on the dashboard
     * reportSortDirection: Number - Sort direction in the Reports tab on the dashboard
     * mod_logs_dash: Array - List of mod logs loaded in the Mod Logs tab on the dashboard
     * modLogPage: Number - Page number that the user is on in the Mod Logs tab on the dashboard
     * modLogStack: Array - List of all mod log pages that the user has navigated to in the Mod Logs tab on the dashboard
     * totalModLogPages: Number - Total mod log pages in the Mod Logs tab on the dashboard
     * modLogSortBy: Number - Sort criteria in the Mod Logs tab on the dashboard
     * modLogSortDirection: Number - Sort direction in the Mod Logs tab on the dashboard
     * profileContentAll: Array - List of all images and comments on profile page
     * profileContentPage: Number - Page number that the user is on in the Content tab on a user page
     * profileContentStack: Array - List of all report pages that the user has navigated to in the Content tab on a user page
     * totalProfileContentPages: Number - Total report pages in the Content tab on a user page
     * profileContentSortBy: Number - Sort criteria in the Content tab on a user page
     * profileContentSortDirection: Number - Sort direction in the Content tab on a user page
     * profileComments: Array - List of all profile comments on a user's profile page
     * profileCommentsPage: Number - Page number that the user is on in the Comments tab on a user page
     * profileCommentsStack: Array - List of all report pages that the user has navigated to in the Comments tab on a user page
     * totalProfileCommentsPages: Number - Total report pages in the Comments tab on a user page
     * profileCommentsSortBy: Number - Sort criteria in the Comments tab on a user page
     * profileCommentsSortDirection: Number - Sort direction in the Comments tab on a user page
     * showNsfwModal: Boolean - Whether the NSFW modal is shown
     * nsfwAccepted: Boolean - Whether the user has acknowledged that they are over 18 and willing to see potentially adult content
     */
    userInfo: {
        userSettings: {
            theme: 'default'
        },
        avatar: 7856
    },
    historyStack: ['/null'],
    dashboardItemSelected: 'settings',
    images_dash: [],
    imagePage: 1,
    imageStack: [],
    totalImagePages: 1,
    imageSortBy: 'date',
    imageSortDirection: 'descending',
    uploading: false,
    uploadProgress: 0,
    imageCount: 0,
    imageInfo: emptyImage,
    alreadyPostedRef: React.createRef(null),
    newImage: false,
    series: {},
    showImageToast: false,
    dashboardNav: false,
    reports_dash: [],
    reportPage: 1,
    reportStack: [],
    totalReportPages: 1,
    reportSortBy: 'timestamp',
    reportSortDirection: 'descending',
    mod_logs_dash: [],
    modLogPage: 1,
    modLogStack: [],
    totalModLogPages: 1,
    modLogSortBy: 'timestamp',
    modLogSortDirection: 'descending',
    profileContent: [],
    profileContentAll: [],
    profileContentPage: 1,
    profileContentStack: [],
    totalProfileContentPages: 1,
    profileContentSortBy: 'timestamp',
    profileContentSortDirection: 'descending',
    profileComments: [],
    profileCommentsPage: 1,
    profileCommentsStack: [],
    totalProfileCommentsPages: 1,
    profileCommentsSortBy: 'timestamp',
    profileCommentsSortDirection: 'descending',
    profileInfo: {},
    showNsfwModal: false,
    nsfwAccepted: true
}

const reducer = (state = initialState, action) => {
    switch(action.type){
        case SET_HISTORY:
            return {
                ...state,
                history: action.data.history,
                location: action.data.location
            }
        case SET_USER:
            return {
                ...state,
                userInfo: action.data
            }
        case SET_SERIES:
            return {
                ...state,
                series: action.data
            }
        case DASHBOARD_NAV:
            return {
                ...state,
                dashboardNav: !state.dashboardNav,
                dashboardItemSelected: action.data
            }
        case ADD_COMMENT:
            return {
                ...state,
                imageInfo: {
                    ...state.imageInfo,
                    comments: [
                        ...state.imageInfo.comments,
                        action.data
                    ],
                    commentPage: Math.ceil((state.imageInfo.comments.length + 1) / 50)
                }
            }
        case ADD_PROFILE_COMMENT:
            return {
                ...state,
                profileComments: [
                    ...state.profileComments,
                    action.data.comment
                ],
                totalProfileCommentsPages: Math.ceil((state.profileComments.length + 1) / action.data.itemsPerPage),
                profileContentAll: (state.profileInfo.commentSection === action.data.comment.commentSection) ? [
                    action.data.comment,
                    ...state.profileContentAll
                ] : state.profileContentAll,
                profileCommentsPage: (action.data.selfAdded) ? 1 : state.profileCommentsPage
            }
        case SET_NSFW_MODAL:
            return {
                ...state,
                showNsfwModal: action.data
            }
        case PREVIOUS_COMMENTS:
            return {
                ...state,
                imageInfo: {
                    ...state.imageInfo,
                    commentPage: state.imageInfo.commentPage - 1,
                    commentStack: [
                        ...state.imageInfo.commentStack,
                        state.imageInfo.commentPage
                    ]
                }
            }
        case NEXT_COMMENTS:
            return {
                ...state,
                imageInfo: {
                    ...state.imageInfo,
                    commentPage: state.imageInfo.commentPage + 1,
                    commentStack: [
                        ...state.imageInfo.commentStack,
                        state.imageInfo.commentPage
                    ]
                }
            }
        case SET_SHOW_IMAGE_TOAST:
            return {
                ...state,
                showImageToast: action.data
            }
        case SET_COMMENT_PAGE:
            const commentPage = action.data ? action.data : 1;
            return {
                ...state,
                imageInfo: {
                    ...state.imageInfo,
                    commentPage: commentPage,
                    commentStack: [
                        ...state.imageInfo.commentStack,
                        state.imageInfo.commentPage
                    ]
                }
            }
        case UPDATE_COMMENT:
            return {
                ...state,
                imageInfo: {
                    ...state.imageInfo,
                    comments: state.imageInfo.comments.map(comment => {
                        if (comment.comment_id === action.data.comment_id) return action.data;
                        else return comment;
                    })
                }
            }
        case UPDATE_PROFILE_COMMENT:
            return {
                ...state,
                profileComments: state.profileComments.map(comment => {
                    if (comment.comment_id === action.data.comment_id) return action.data;
                    else return comment;
                })
            }
        case SET_IMAGE_COUNT:
            return {
                ...state,
                imageCount: action.data
            }
        case ADD_IMAGE_VIEW:
            return {
                ...state,
                imageInfo: {
                    ...state.imageInfo,
                    views: state.imageInfo.views + 1
                }
            }
        case UPLOAD_PROGRESS:
            return {
                ...state,
                uploadProgress: action.data
            }
        case UPLOAD_IMAGES:
            return {
                ...state,
                uploading: true,
                uploadProgress: 0,
                imageCount: 0
            }
        case UPLOAD_END:
            return {
                ...state,
                uploading: false
            }
        case PURGE_USER:
            return {
                ...state,
                userInfo: {
                    userSettings: {
                        theme: 'default'
                    }
                },
                dashboardItemSelected: 'settings',
                images_dash: [],
                imagePage: 1,
                imageStack: [],
                totalImagePages: 1,
                imageSortBy: 'date',
                imageSortDirection: 'descending',
                contracts: [],
                uploading: false,
                uploadProgess: 0,
                imageCount: 0,
                imageInfo: emptyImage,
                newImage: false,
                series: {},
                showImageToast: false,
                dashboardNav: false,
                reports_dash: [],
                reportPage: 1,
                reportStack: [],
                totalReportPages: 1,
                reportSortBy: 'timestamp',
                reportSortDirection: 'descending',
                mod_logs_dash: [],
                modLogPage: 1,
                modLogStack: [],
                totalModLogPages: 1,
                modLogSortBy: 'timestamp',
                modLogSortDirection: 'descending',
                profileContent: [],
                profileContentPage: 1,
                profileContentStack: [],
                totalProfileContentPages: 1,
                profileContentSortBy: 'timestamp',
                profileContentSortDirection: 'descending',
                profileComments: [],
                profileCommentsPage: 1,
                profileCommentsStack: [],
                totalProfileCommentsPages: 1,
                profileCommentsSortBy: 'timestamp',
                profileCommentsSortDirection: 'descending'
            }
        case ROUTE:
            state.history.push({
                pathname: action.data.destination,
                state: {
                    prevRoute: action.data.prevRoute
                }
            });
            return {
                ...state,
                historyStack: [
                    ...state.historyStack,
                    action.data.prevRoute
                ],
                newImage: action.data.newImage,
                imageInfo: (action.data.destination.split('/')[1] === 'image' || action.data.prevRoute.split('/')[1] === 'image') ? state.imageInfo : emptyImage,
                showImageToast: action.data.destination.split('/')[1] === 'image' ? state.showImageToast : false,
                showNsfwModal: false
            }
        case NEW_IMAGE_RESET:
            return {
                ...state,
                newImage: false
            }
        case SET_IMAGE_PAGE: 
            const page = action.data ? action.data : 1;
            return {
                ...state,
                imagePage: page,
                imageStack: [
                    ...state.imageStack,
                    page
                ]
            }
        case SET_IMAGES:
            return {
                ...state,
                images_dash: action.data
            }
        case SET_IMAGE_SORT:
            if (state.imageSortBy === action.data) return {
                ...state,
                imageSortDirection: state.imageSortDirection === 'ascending' ? 'descending' : 'ascending'
            } 
            else return {
                ...state,
                imageSortBy: action.data
            }
        case SET_MAX_IMAGE_PAGES:
            const imagePage = action.data ? action.data : 1
            return {
                ...state,
                totalImagePages: action.data,
                imagePage: state.imagePage > imagePage ? imagePage : state.imagePage,
                imageStack: state.imagePage > imagePage ? [
                    ...state.imageStack,
                    imagePage
                ] : state.imageStack
            }
        case SELECT_IMAGE:
            return {
                ...state,
                imageID: action.data
            }
        case SET_REPORT_PAGE: 
            const reportPage = action.data ? action.data : 1
            return {
                ...state,
                reportPage: reportPage,
                reportStack: [
                    ...state.reportStack,
                    reportPage
                ]
            }
        case SET_REPORTS:
            return {
                ...state,
                reports_dash: action.data
            }
        case SET_REPORT_SORT:
            if (state.reportSortBy === action.data) return {
                ...state,
                reportSortDirection: state.reportSortDirection === 'ascending' ? 'descending' : 'ascending'
            } 
            else return {
                ...state,
                reportSortBy: action.data
            }
        case SET_MAX_REPORT_PAGES:
            const maxReportPages = action.data ? action.data : 1
            return {
                ...state,
                totalReportPages: maxReportPages,
                reportPage: state.reportPage > maxReportPages ? maxReportPages : state.reportPage,
                reportStack: state.reportPage > maxReportPages ? [
                    ...state.reportStack,
                    maxReportPages
                ] : state.reportStack
            }
        case SELECT_REPORT:
            return {
                ...state,
                reportID: action.data
            }
        case SELECT_DASHBOARD_ITEM:
            return {
                ...state,
                dashboardItemSelected: action.data
            }
        case SET_IMAGE:
            return {
                ...state,
                imageInfo: action.data
            }
        case UPDATE_IMAGE:
            return {
                ...state,
                imageInfo: {
                    ...state.imageInfo,
                    ...action.data,
                    comments: state.imageInfo.comments
                }
            }
        case SET_MOD_LOG_PAGE: 
            const modLogPage = action.data ? action.data : 1
            return {
                ...state,
                modLogPage: modLogPage,
                modLogStack: [
                    ...state.modLogStack,
                    modLogPage
                ]
            }
        case SET_MOD_LOGS:
            return {
                ...state,
                mod_logs_dash: action.data
            }
        case SET_MOD_LOG_SORT:
            if (state.modLogSortBy === action.data) return {
                ...state,
                modLogSortDirection: state.modLogSortDirection === 'ascending' ? 'descending' : 'ascending'
            } 
            else return {
                ...state,
                modLogSortBy: action.data
            }
        case SET_MAX_MOD_LOG_PAGES:
            const maxModLogPages = action.data ? action.data : 1
            return {
                ...state,
                totalModLogPages: maxModLogPages,
                modLogPage: state.modLogPage > maxModLogPages ? maxModLogPages : state.modLogPage,
                modLogStack: state.modLogPage > maxModLogPages ? [
                    ...state.modLogStack,
                    maxModLogPages
                ] : state.modLogStack
            }
        case SELECT_MOD_LOG:
            return {
                ...state,
                modLogID: action.data
            }
        case SET_PROFILE_CONTENT_PAGE: 
            const profileContentPage = action.data ? action.data : 1
            return {
                ...state,
                profileContentPage: profileContentPage,
                profileContentStack: [
                    ...state.profileContentStack,
                    profileContentPage
                ]
            }
        case SET_PROFILE_CONTENT:
            return {
                ...state,
                profileContent: action.data
            }
        case SET_PROFILE_CONTENT_ALL:
            return {
                ...state,
                profileContent: action.data,
                profileContentAll: action.data
            }
        case SET_PROFILE_CONTENT_SORT:
            if (state.profileContentSortBy === action.data) return {
                ...state,
                profileContentSortDirection: state.profileContentSortDirection === 'ascending' ? 'descending' : 'ascending'
            } 
            else return {
                ...state,
                profileContentSortBy: action.data
            }
        case SET_MAX_PROFILE_CONTENT_PAGES:
            if (!action.data) action.data = 1;
            return {
                ...state,
                totalProfileContentPages: action.data,
                profileContentPage: state.profileContentPage > action.data ? action.data : state.profileContentPage,
                profileContentStack: state.profileContentPage > action.data ? [
                    ...state.profileContentStack,
                    action.data
                ] : state.profileContentStack
            }
        case SELECT_PROFILE_CONTENT:
            return {
                ...state,
                profileContentID: action.data
            }
        case SET_PROFILE_COMMENT_PAGE: 
            if (!action.data) action.data = 1;
            return {
                ...state,
                profileCommentsPage: action.data,
                profileCommentsStack: [
                    ...state.profileCommentsStack,
                    action.data
                ]
            }
        case SET_PROFILE_COMMENTS:
            return {
                ...state,
                profileComments: action.data
            }
        case SET_PROFILE_COMMENT_SORT:
            if (state.profileCommentsSortBy === action.data) return {
                ...state,
                profileCommentsSortDirection: state.profileCommentsSortDirection === 'ascending' ? 'descending' : 'ascending'
            } 
            else return {
                ...state,
                profileCommentsSortBy: action.data
            }
        case SET_MAX_PROFILE_COMMENT_PAGES:
            if (!action.data) action.data = 1;
            return {
                ...state,
                totalProfileCommentsPages: action.data,
                profileCommentsPage: state.profileCommentsPage > action.data ? action.data : state.profileCommentsPage,
                profileCommentsStack: state.profileCommentsPage > action.data ? [
                    ...state.profileCommentsStack,
                    action.data
                ] : state.profileCommentsStack
            }
        case SELECT_PROFILE_COMMENT:
            return {
                ...state,
                profileCommentsID: action.data
            }
        case SET_PROFILE_INFO:
            return {
                ...state,
                profileInfo: action.data
            }
        default:
            return {
                ...state
            }
    }
}

export default reducer;
import React from 'react';
import {
    MDBRipple
} from 'mdb-react-ui-kit';

/**
 * 
 * @param {Function} nav - The navigation function
 * @returns The main logo on mobile devices
 */
const MobileLogo = ({ mobileNav }) => (
    <div className="col-6">
        <MDBRipple onClick={() => mobileNav('/')} id="logo-mobile" className="d-block mt-2 w-max-content" rippleColor='light' rippleTag='a'>
            <img id="svg-logo" src="/assets/images/logo.png" alt="Navbar Logo"/>
        </MDBRipple>
        <span className="nav-mobile mb-2"></span>
    </div>
)

export default MobileLogo;
import React from 'react';

/**
 * These are the headers (similar to a table) for the list of customer users
 */
const JannyHeaders = props => (
    <>
        <div onClick={() => props.setSort('username')} className="col-3 d-flex cursor-pointer">
            <p className="fw-bold mb-1 text-sm-sm">Username</p>
            {props.sortBy === 'username' ? 
            <i className={`d-block ms-2 mt-1 fas fa-caret-${props.sortDirection === 'ascending' ? 'up' : 'down'}`}></i> : <></>}
        </div>
        <div onClick={() => props.setSort('email')} className="col-3 d-flex justify-content-center cursor-pointer">
            <p className="fw-bold text-center mb-1 text-sm-sm">Email</p>
            {props.sortBy === 'email' ? 
            <i className={`d-block ms-2 mt-1 fas fa-caret-${props.sortDirection === 'ascending' ? 'up' : 'down'}`}></i> : <></>}
        </div>
        <div onClick={() => props.setSort('comments')} className="col-3 d-flex justify-content-center cursor-pointer">
            <p className="fw-bold text-center mb-1 text-sm-sm">Comments</p>
            {props.sortBy === 'comments' ? 
            <i className={`d-block ms-2 mt-1 fas fa-caret-${props.sortDirection === 'ascending' ? 'up' : 'down'}`}></i> : <></>}
        </div>
        <div onClick={() => props.setSort('date')} className="col-3 d-flex justify-content-center cursor-pointer">
            <p className="fw-bold text-center mb-1 text-sm-sm">Join Date</p>
            {props.sortBy === 'date' ? 
            <i className={`d-block ms-2 mt-1 fas fa-caret-${props.sortDirection === 'ascending' ? 'up' : 'down'}`}></i> : <></>}
        </div>
    </>
);

export default JannyHeaders;
import React from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import h from '../utilities/helpers';
import t from '../utilities/transitions';
import {
    MDBSpinner,
    MDBBtn
} from 'mdb-react-ui-kit';
import { connect } from 'react-redux';
import Header from './image/Header';
import { route, set_image, add_comment, set_image_count, add_image_view, new_image_reset, select_dashboard_item, set_nsfw_modal } from '../redux/actions';
import Content from './image/Content';
import Comments from './image/Comments';
import { io } from 'socket.io-client';
import { motion } from 'framer-motion';

/**
 * This is the page the user hits when they cancel a password reset request
 */

 const axiosSource = axios.CancelToken.source();

class Image extends React.Component{
    constructor(props){
        super();
        /**
         * this.imageNumber: Number - ref Images.image_id
         * this.history: Array - Comment page navigation history
         * this.mostRecentPage: Number - The last entry in this.history
         * this.toastRef: Reference to button that must be clicked for Toast to show
         */
        this.imageNumber = Number(props.match.params.id.split('#')[0]);
        this.history = props.historyStack;
        this.mostRecentPage = this.history[this.history.length - 1];
        this.toastRef = React.createRef(null);
        this.state = {
            /**
             * contentExit: Object - framer-motion exit transition for the image
             * commentExit: Object - framer-motion exit transition for the comment section
             * leftButtonExit: Object - framer-motion exit transition for the top left button
             * rightButtonExit: Object - framer-motion exit transition for the top right button
             * exitFull: Object - framer-motion exit transition for the whole page
             * loaded: Boolean indicating whether the page has been loaded
             * socket: false | socket.io Socket instance
             * transitioning: Boolean indicating whether the page is in the process of changing to a new image from clicking the Back/Next buttons
             */
            contentExit: this.getInitialContentExit(),
            commentExit: this.getInitialCommentExit(props),
            leftButtonExit: this.getInitialLeftButtonExit(props),
            rightButtonExit: this.getInitialRightButtonExit(),
            exitFull: this.getExitFull(),
            loaded: false,
            socket: false,
            transitioning: false
        }
    }

    componentDidMount(){
        document.title = `${this.props.match.params.id.split('#')[0]} - NanaImg`;
        this.load();
    }

    componentDidUpdate(prevProps){
        if (!prevProps.imageInfo.removed && this.props.imageInfo.removed) document.getElementById('nav-container').scrollIntoView();
        if (this.props.imageCount && document.getElementById('p-metadata')) document.getElementById('p-metadata').remove();
        if (prevProps.dashboardNav !== this.props.dashboardNav) this.dashboardNav();
    }

    componentWillUnmount(){
        axiosSource.cancel();
        if (this.state.socket && this.state.socket.disconnect) this.state.socket.disconnect();
    }

    dashboardNav = () => {
        this.setState({
            ...this.state,
            exitFull: t.fade_out_right
        }, () => this.props.route('/dashboard'));
    }

    /**
     * Request image data from the server
     * If there is a comment in the window location hash, go to the comment page where it is located and scroll to it
     * Once loaded, if NSFW, show NSFW modal
     */
    load = () => {
        axios.get(`/api/images/${this.props.match.params.id}`).then(res => {
            if (String(res.data.image_id) === window.location.href.split('/image/')[1].split('#comment-')[0]){
                let commentPage = 1;
                if (window.location.hash){
                    let commentNumber = window.location.hash.split('#comment-')[1];
                    if (h.isNumeric(commentNumber)){
                        commentNumber = Number(commentNumber);
                        const comment = res.data.comments.find(c => c.comment_id === commentNumber);
                        if (comment){
                            commentPage = Math.ceil((res.data.comments.indexOf(comment) + 1) / 50);
                        }
                    }
                }
                this.props.set_image({
                    ...res.data,
                    commentStack: [],
                    commentPage: (res.data.commentSection !== this.props.imageInfo.commentSection) ? commentPage : this.props.imageInfo.commentPage
                });
                this.setState({
                    ...this.state,
                    loaded: true,
                }, () => {
                    const mainImage = document.getElementById("img-main");
                    if (mainImage) mainImage.addEventListener('load', () => {
                        if (!this.props.userInfo.nsfwAccepted && res.data.nsfw && !this.props.showNsfwModal){
                            this.props.set_nsfw_modal(true);
                        }
                        setTimeout(() => {
                            if (window.location.pathname.split('/')[1] === 'image' && Number(this.props.match.params.id.split('#')[0]) === this.props.imageInfo.image_id){
                                if (window.location.hash){
                                    const element = document.querySelector(window.location.hash);
                                    if (element) element.scrollIntoView();
                                }
                                this.setState({
                                    ...this.state,
                                    leftButtonExit: t.fade_out_minimize,
                                    rightButtonExit: t.fade_out_minimize,
                                    contentExit: t.fade_out,
                                    commentExit: t.fade_out,
                                    exitFull: t.normalize
                                }, () => this.props.select_dashboard_item('settings'));
                            }
                        }, 200);
                    });
                    else this.setState({
                        ...this.state,
                        leftButtonExit: t.fade_out_minimize,
                        rightButtonExit: t.fade_out_minimize,
                        contentExit: t.fade_out,
                        commentExit: t.fade_out,
                        exitFull: t.normalize
                    }, () => this.props.select_dashboard_item('settings'));
                    this.setSocket();
                });
            }
            
        }).catch(err => {
            console.log(err);
            if (err.response && err.response.status === 404) this.props.route('/not-found');
            else {
                setTimeout(this.load, 1000);
            }
        });
    }

    /**
     * Fires whenever a mod action takes place or an image is added
     * Requests fresh data from the server
     */
    reload = () => axios.get(`/api/images/${this.props.match.params.id}`).then(res => {
        this.props.set_image({
            ...res.data,
            commentPage: this.props.imageInfo.commentPage,
            commentStack: this.props.imageInfo.commentStack
        });
        document.title = `${res.data.image_id} - NanaImg`;
        this.setState({
            ...this.state,
            loaded: true
        }, () => {
            if (!this.props.userInfo.nsfwAccepted && res.data.nsfw && !this.props.showNsfwModal){
                this.props.set_nsfw_modal(true);
            } 
        });
    }).catch(err => {
        console.log(err);
        setTimeout(this.reload, 2000);
    });

    /**
     * Initializes the socket connection
     * Handles events
     *  
     */
    setSocket = () => this.setState({
        ...this.state,
        socket: io('/', {
            query: {
                type: 'image',
                comment_section: this.props.imageInfo.commentSection,
                image_id: this.props.imageInfo.image_id
            }
        })
    }, () => {
        this.state.socket.on('comment-receive', this.props.add_comment);
        this.state.socket.on('image-count', this.props.set_image_count);
        this.state.socket.on('mod-action-notification', this.reload);
        this.state.socket.on('image-added', () => {
            this.props.set_image_count(this.props.imageCount + 1);
            this.reload();
        });
        this.state.socket.on('view', this.props.add_image_view);
        if (this.props.newImage){
            this.state.socket.emit('new-image');
            this.props.new_image_reset();
        }
        this.props.add_image_view();
    });

    getInitialContentExit = () => {
        /**
         * If going back, fade out left
         * If coming from another endpoint or going to another end point, fade in
         * If going forward, fade out right
         */
        if (this.mostRecentPage.split){
            const split = this.mostRecentPage.split('image/');
            if (split.length > 1){
                if (Number(split[1]) > this.imageNumber) return t.fade_out_right;
                else return t.fade_out_left;
            } else return t.fade_out;
        } else return t.fade_out;
    }

    getExitFull = () => {
        if (this.mostRecentPage === '/dashboard') return t.fade_out_right;
        else return t.normalize;
    }

    getInitialCommentExit = props => {
        /**
         * If going back, fade out left
         * If coming from another endpoint or going to another end point, fade in
         * If going forward, fade out right
         */
        if (this.mostRecentPage.split){
            const split = this.mostRecentPage.split('image/');
            if (split.length > 1){
                if (Number(split[1]) > this.imageNumber){
                    if (props.imageInfo.previousImage && props.imageInfo.previousImage.commentSection === props.imageInfo.commentSection) return t.normalize;
                    else return t.fade_out_right;
                } 
                else {
                    if (props.imageInfo.nextImage && props.imageInfo.nextImage.commentSection === props.imageInfo.commentSection) return t.normalize;
                    else return t.fade_out_left;
                } 
            } else return t.fade_out;
        } else return t.fade_out;
    }

    getInitialLeftButtonExit = props => {
        /**
         * If going back, bob left
         * If coming from another endpoint or going to another end point, minimize
         * If going forward, remain stationary
         */
        if (this.mostRecentPage.split){
            const split = this.mostRecentPage.split('image/');
            if (split.length > 1){
                if (Number(split[1]) < this.imageNumber) return t.bob_left;
                else {
                    if (!props.imageInfo.nextImage) return t.fade_out_minimize;
                    else return t.normalize;
                }
            } else return t.fade_out_minimize;
        } else return t.fade_out_minimize;   
    }

    getInitialRightButtonExit = () => {
        /**
         * If going back, bob left
         * If coming from another endpoint or going to another end point, minimize
         * If going forward, remain stationary
         */
        if (this.mostRecentPage.split){
            const split = this.mostRecentPage.split('image/');
            if (split.length > 1){
                if (Number(split[1]) > this.imageNumber) return t.bob_right;
                else {
                    if (this.imageNumber === 2) return t.fade_out_minimize;
                    else return t.normalize;
                } 
            } else return t.fade_out_minimize;
        } else return t.fade_out_minimize;   
    }

    /**
     * Fired when the user clicks the left nav button at the top
     */
    back = () => {
        
        if (!this.state.transitioning && this.state.loaded) this.setState({
            ...this.state,
            transitioning: true
        }, () => this.setState({
            ...this.state,
            leftButtonExit: (!this.props.imageInfo.nextImage) ? t.fade_out_minimize : t.normalize,
            rightButtonExit: (this.imageNumber > 2) ? t.bob_right : t.fade_out_minimize,
            contentExit: t.fade_out_left,
            commentExit: (this.props.imageInfo.previousImage && this.props.imageInfo.previousImage.commentSection === this.props.imageInfo.commentSection) ? t.normalize : t.fade_out_left
        }, () => this.props.route(`/image/${this.imageNumber - 1}`)));
    }

    /**
     * Fired when the user clicks the right nav button at the top
     */  
    next = () => {
        if (!this.state.transitioning && this.state.loaded) this.setState({
            ...this.state,
            transitioning: true
        }, () => this.setState({
            ...this.state,
            leftButtonExit: (Number(this.props.imageInfo.nextImage.image_id) !== this.props.imageCount) ? t.bob_left : t.fade_out_minimize,
            rightButtonExit: (!this.props.imageInfo.previousImage) ? t.fade_out_minimize : t.normalize,
            contentExit: t.fade_out_right,
            commentExit: (this.props.imageInfo.nextImage && this.props.imageInfo.nextImage.commentSection === this.props.imageInfo.commentSection) ? t.normalize : t.fade_out_right
        }, () => this.props.route(`/image/${this.imageNumber + 1}`)));
    }


    render(){
        return (
            <motion.div ref={this.toastRef} transition={t.transition} exit={this.state.exitFull} animate={t.normalize} initial={this.state.exitFull}>
                <Header 
                    leftButtonExit={this.state.leftButtonExit} 
                    rightButtonExit={this.state.rightButtonExit} 
                    back={this.back} 
                    next={this.next} 
                    match={this.props.match} 
                    imageNumber={this.imageNumber}
                    loaded={this.state.loaded}
                />
                {this.state.loaded ?
                <>
                    {this.props.imageInfo.removed && !(h.checkJanny(this.props.userInfo) || this.props.userInfo._id === this.props.imageInfo.userID) ? <></> : 
                    <>
                        <Content socket={this.state.socket} contentExit={this.state.contentExit} />
                        <Comments socket={this.state.socket} googleReCaptchaProps={this.props.googleReCaptchaProps} commentExit={this.state.commentExit} />
                        <div className="py-5">
                            <MDBBtn 
                                onClick={() => document.getElementById('root').scrollTop = 0} 
                                style={{backgroundColor: '#607D8B'}}
                                size="lg" 
                                className="d-block mx-auto"
                            >
                                Return to Top
                                <i className="fas fa-level-up-alt ms-2"></i>
                            </MDBBtn>
                        </div>
                    </>}
                </> : 
                <div className="d-flex justify-content-center mt-4">
                    <MDBSpinner color="success" grow style={{ width: '3rem', height: '3rem' }}>
                        <span className='visually-hidden'>Loading...</span>
                    </MDBSpinner>
                </div>}
            </motion.div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
}

export default withRouter(connect(mapStateToProps, { route, set_image, add_comment, set_image_count, add_image_view, new_image_reset, select_dashboard_item, set_nsfw_modal })(Image));
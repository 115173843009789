import React from 'react';
import data from './data/editUser';
import { edit_chadmin_schema } from '../../../../../utilities/validations';
import axios from 'axios';
import { route, purge_user } from '../../../../../redux/actions';
import { connect } from 'react-redux';
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBContainer,
    MDBInput,
    MDBValidation,
    MDBValidationItem
} from 'mdb-react-ui-kit';
import Spinner from '../../../../../components/Spinner';

/**
 * This is the modal that pops up when the user selects a user from the Admin category
 * Admin users can be disabled and altered here
 */

class ChadminInfoModal extends React.Component{
    constructor(props){
        super();
        this.state = {
            /**
             * working: Boolean indicating whether changes to the selected user are in the process of being applied
             * disabling: Boolean indicating whether the selected user is in the process of being disable
             */
            working: false,
            disabling: false,
            inputs: data.map(field => ({
                id: field.id,
                error: '',
                invalid: false,
                value: props.userSelected[field.id]
            }))
        }
    }

    componentDidUpdate(prevProps){
        
        if (this.props.userSelected.username !== prevProps.userSelected.username) this.setState({
            ...this.state,
            inputs: data.map(field => ({
                id: field.id,
                error: '',
                invalid: false,
                value: this.props.userSelected[field.id]
            }))
        });
    }

    disableUser = () => {
        /**
         * Fired when the user clicks the red Disable User button
         * Disables the user
         * Updates the parent
         * Hides the modal
         */
        if (!this.state.disabling && !this.state.working) this.setState({
            ...this.state,
            disabling: true
        }, () => axios.post('/users/disable/', {
            username: this.props.userSelected.username
        }).then(res => {
            const notificationInfo = {
                icon: <i className="fas fa-user-alt-slash fa-lg text-danger"></i>,
                text: `${res.data.username} Disabled`
            }
            this.props.toggleInfoModal();
            this.setState({
                ...this.state,
                disabling: false
            }, () => this.props.updateUser(res.data, notificationInfo));
        }).catch(err => this.setState({
            ...this.state,
            disabling: false
        }, () => {
            if (err.response.status === 401) {
                this.props.toggleInfoModal();
                this.props.purge_user();
                this.props.route('/login');
                alert('Your session has expired. Please log in again.');
            } else {
                console.log(err);
                alert('An error occurred. Please try again later');
            }
        })));
    }

    saveUser = () => {
        /**
         * Make sure changes to the user are not already in the process of being saved
         * Remove Invalid Feedback from previous unsuccessful attempts, if any
         * Create object from user input
         * Validate the object
         * If object is invalid, add Invalid Feedback for all invalid entries
         * Create form data object, send to server
         * If successful, set new user object into application state, then redirect to dashboard
         * If unsuccessful and error 403, alert user is locked out
         * If unsuccessful and not error 403, general error alert
         */
        console.log('update')
        document.getElementById('form_update_info').classList.add('was-validated');
         let invalidInputs = this.state.inputs.filter(input => input.invalid);
         invalidInputs.forEach(input => document.getElementById(input.id).setCustomValidity('hello'));
         if (!this.state.working && !invalidInputs.length && !this.state.disabling) this.setState({
             ...this.state,
             working:true
         }, () => {
             const data = Object.fromEntries(this.state.inputs.map(input => [input.id, input.value]));
             try {
                edit_chadmin_schema.validateSync(data, {
                    abortEarly: false
                });
                const fd = new FormData();
                for ( const key in data ) {
                    fd.append(key, data[key]);
                }
                fd.append('oldUsername', this.props.userSelected.username);
                axios.post('/users/edit-user', fd).then(res => {
                    if (!res.data.error) this.props.toggleInfoModal();
                    this.setState({
                        ...this.state,
                        working: false
                    }, () => {
                        if (res.data.error) alert(res.data.error);
                        else {
                            const notificationInfo = {
                                icon: <i className="fas fa-user-check me-2 text-success"></i>,
                                text: `${res.data.username} Updated`
                            }
                            this.props.updateUser(res.data, notificationInfo);
                        }
                    });
                }).catch(err => this.setState({
                    ...this.state,
                    working: false
                }, () => {
                    if (err.response.status === 401) {
                        this.props.toggleInfoModal();
                        this.props.purge_user();
                        this.props.route('/login');
                        alert('Your session has expired. Please log in again.');
                    } else {
                        console.log(err);
                        alert('An error occurred. Please try again later');
                    }
                }));
             } catch(err){
                if (err.inner) console.log('validation', err.inner);
                 this.setState({
                     ...this.state,
                     working: false
                 }, () => {
                     console.log(err);
                     alert('An error occurred. Please try again later');
                 });
             }
         });
    }

    changeHandler = e => this.setState({
        ...this.state,
        inputs: this.state.inputs.map(input => {
            if (input.id === e.target.name) return {
                ...input,
                value: e.target.value
            }
            else return input
        })
    }, () => {
        const data = Object.fromEntries(this.state.inputs.map(input => [input.id, input.value]));
        try {
            edit_chadmin_schema.validateSync(data, {
                abortEarly: false
            });
            this.setState({
                ...this.state,
                inputs: this.state.inputs.map(input => {
                    document.getElementById(input.id + '-edit').setCustomValidity('');
                    return {
                        ...input,
                        invalid: false,
                        error: ''
                    }
                })
            });
        } catch(err){
            let errorsAdded = [];
            this.setState({
                ...this.state,
                inputs: this.state.inputs.map(input => {
                    if (err.inner.find(error => error.path === input.id) && errorsAdded.indexOf(input.id) === -1){
                        errorsAdded.push(input.id);
                        return {
                            ...input,
                            invalid: true,
                            error: err.inner.find(error => error.path === input.id).message
                        }
                    } 
                    else return {
                        ...input,
                        invalid: false,
                        error: ''
                    };
                })
            }, () => this.state.inputs.forEach(input => {
                if (input.invalid) document.getElementById(input.id + '-edit').setCustomValidity('hello');
                else document.getElementById(input.id + '-edit').setCustomValidity('');
            }));
        }
    });

    pressEnter = e => {
        /**
         * Submit the form if the user presses the enter key while in one of the inputs
         */
        if (e.key === 'Enter') this.saveUser();
    }

    render(){
        return (
            <>
              
              {typeof window !== 'undefined' && window.navigator ?
              <MDBModal staticBackdrop show={this.props.infoModalShown} setShow={this.props.setInfoModal} tabIndex='-1'>
                <MDBModalDialog size="xl">
                  <MDBModalContent>
                    <MDBModalHeader>
                      <MDBModalTitle>{this.props.userSelected.username}</MDBModalTitle>
                      <MDBBtn className='btn-close' color='none' onClick={this.props.toggleInfoModal}></MDBBtn>
                    </MDBModalHeader>
                    <MDBModalBody>
                        <MDBContainer fluid className="px-0">
                            <MDBValidation key={this.props.userSelected.username} id="form_update_info" className="row" name="form_update_info" method="dialog">
                                {data.map(field => (
                                        <MDBValidationItem className="col-12 col-md-4 mt-4" feedback={this.state.inputs.find(input => input.id === field.id).error} invalid={true} key={field.id + '-edit'}>
                                            <MDBInput
                                                name={field.id}
                                                onChange={this.changeHandler}
                                                id={field.id + '-edit'}
                                                label={field.text}
                                                size="lg"
                                                className={!this.state.inputs.find(input => input.id === field.id).invalid ? 'mb-0' : 0}
                                                onKeyPress={this.pressEnter}
                                                defaultValue={this.props.userSelected[field.id]}
                                            />
                                        </MDBValidationItem>
                                    ))}
                            </MDBValidation>
                        </MDBContainer>
                    </MDBModalBody>
                    <div className="show-lg">
                        <MDBModalFooter className="justify-content-between">
                            {this.state.disabling ?
                            <MDBBtn size="lg" disabled color="danger" className="text-nowrap min-w-max-content my-0"><Spinner size='sm' className='me-2' />Disabling</MDBBtn> :
                            <>
                                {this.state.working ?
                                <MDBBtn size="lg" disabled className="text-nowrap min-w-max-content my-0" color="danger"><i className="fas fa-user-alt-slash me-2"></i>Disable User</MDBBtn> :
                                <MDBBtn size="lg" onClick={this.disableUser} className="text-nowrap min-w-max-content my-0" color="danger"><i className="fas fa-user-alt-slash me-2"></i>Disable User</MDBBtn>}
                            </>
                            }
                            <div className="d-flex">
                                {this.state.working ?
                                <MDBBtn size="lg" disabled color="primary" className="text-nowrap min-w-max-content my-0" block><Spinner size='sm' className='me-2' />Saving</MDBBtn> :
                                <>
                                    {this.state.disabling ?
                                    <MDBBtn size="lg" disabled color="primary" className="text-nowrap min-w-max-content my-0" block><i className="fas fa-save me-2"></i>Save Changes</MDBBtn> :
                                    <MDBBtn size="lg" onClick={this.saveUser} color="primary" className="text-nowrap min-w-max-content my-0" block><i className="fas fa-save me-2"></i>Save Changes</MDBBtn>}
                                </>}
                                <MDBBtn size="lg" block style={{backgroundColor: 'var(--mdb-gray)'}} className="ms-2 my-0" onClick={this.props.toggleInfoModal}>Close</MDBBtn>
                            </div>
                        </MDBModalFooter>
                    </div>

                    <div className="show-sm">
                        <MDBModalFooter className="justify-content-between align-items-start flex-nowrap px-1">
                            {this.state.disabling ?
                            <MDBBtn disabled color="danger" className="text-nowrap min-w-max-content my-0"><Spinner size='sm' className='me-2' />Disabling</MDBBtn> :
                            <>
                                {this.state.working ?
                                <MDBBtn disabled color="danger" className="text-nowrap min-w-max-content my-0"><i className="fas fa-user-alt-slash me-2"></i>Disable User</MDBBtn> :
                                <MDBBtn onClick={this.disableUser} color="danger" className="text-nowrap min-w-max-content my-0"><i className="fas fa-user-alt-slash me-2"></i>Disable User</MDBBtn>}
                            </>
                            }
                            <div>
                                {this.state.working ?
                                <MDBBtn disabled color="primary block" className="text-nowrap min-w-max-content my-0"><Spinner size='sm' className='me-2' />Saving</MDBBtn> :
                                <>
                                    {this.state.disabling ?
                                    <MDBBtn disabled color="primary block" className="text-nowrap min-w-max-content my-0"><i className="fas fa-save me-2"></i>Save Changes</MDBBtn> :
                                    <MDBBtn onClick={this.saveUser} color="primary block" className="text-nowrap min-w-max-content my-0"><i className="fas fa-save me-2"></i>Save Changes</MDBBtn>}
                                </>}
                                <MDBBtn style={{backgroundColor: 'var(--mdb-gray)'}} block className="ms-auto mt-2" onClick={this.props.toggleInfoModal}>Close</MDBBtn>
                            </div>
                        </MDBModalFooter>
                    </div>
                  </MDBModalContent>
                </MDBModalDialog>
              </MDBModal> : <></>}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
  }
  
  export default connect(mapStateToProps, { route, purge_user })(ChadminInfoModal);
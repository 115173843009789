import React from 'react';
import { motion } from 'framer-motion';
import t from '../../utilities/transitions';

class EmojiBody extends React.Component{
    constructor(props){
        super();
        this.index = props.emojiIndex;
    }

    // Loop through JSON emojis, replace emoji with svg so they are uniform across all devices
    render(){
        return (
            <motion.div transition={t.transition_fast} exit={this.props.exit} animate={t.normalize} initial={this.props.entrance} className="emoji-panel__body">
                {this.props.json[this.index].emojis.map((item, index) =>
                <button className="emoji-panel__btn emoji-svg" key={index} onClick={() => this.props.onEmojiSelect(item)}>{this.props.createSvg(item.unicode, item.title)}</button>)}
            </motion.div>
        );
    }
}

export default EmojiBody;
import React from 'react';
import { connect } from 'react-redux';
import { motion } from 'framer-motion';
import t from '../../../../utilities/transitions';
import h from '../../../../utilities/helpers';
import { 
    MDBBtn
} from 'mdb-react-ui-kit';
import Spinner from '../../../../components/Spinner';
import axios from 'axios';
import { set_max_mod_log_pages, set_mod_logs } from '../../../../redux/actions';

const itemsPerPage = 40;

class ModLogPage extends React.Component{
    constructor(props){
        super();
        /**
         * this.page: Number, the mod log page that the user is currently on
         */
        this.page = props.modLogPage;
        this.state = {
            /**
             * reversing: Array containing a list of Janny actions that are in the process of being reversed
             */
            reversing: []
        }
    }

    sortModLogs = modLogs => {
        /**
         * Receives the modLogs array, then sorts the modLogs based on the sort basis
         */
        switch(this.props.modLogSortBy){
            case 'user':
                if (this.props.modLogSortDirection === 'ascending') return modLogs.sort((a, b) => a.username.localeCompare(b.username));
                else return modLogs.sort((b, a) => a.username.localeCompare(b.username));
            case 'id':
                if (this.props.modLogSortDirection === 'ascending') return modLogs.sort((a, b) => a.contentID - b.contentID);
                else return modLogs.sort((b, a) => a.contentID - b.contentID);
            case 'action':
                if (this.props.modLogSortDirection === 'ascending') return modLogs.sort((a, b) => (a.action + a.type).localeCompare(b.action + b.type));
                else return modLogs.sort((b, a) => (a.action + a.type).localeCompare(b.action + b.type));
            case 'timestamp':
                if (this.props.modLogSortDirection === 'ascending') return modLogs.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
                else return modLogs.sort((b, a) => new Date(a.timestamp) - new Date(b.timestamp));
            default:
                return modLogs;
        }
    }

    /**
     * 
     * @param {Number} image ref Images.image_id
     */
    selectModLog = image => {
        this.props.imageNav(`/image/${image.image_id}`);
    }

    /**
     * 
     * @param {Object} modLog - ModLogs document
     * 
     * Hit when the user clicks the Reverse button on any modlog
     * 
     * Adds that log to state.reversing
     * Reverses the action outlined in the mod log
     * Updates the mod logs and the page numbers. 
     * 
     * TODO: Use a single redux action
     */
    reverse = modLog => {
        if (this.state.reversing.indexOf(modLog._id) === -1) this.setState({
            ...this.state,
            reversing: [
                ...this.state.reversing,
                modLog._id
            ]
        }, () => axios.post('/modlogs/reverse', modLog).then(res => {
            this.props.set_max_mod_log_pages(Math.ceil(res.data.modLogs.length / itemsPerPage));
            this.props.set_mod_logs(res.data.modLogs);
            this.setState({
                ...this.state,
                reversing: this.state.reversing.filter(r => r !== modLog._id)
            });
        }).catch(err => this.setState({
            ...this.state,
            reversing: this.state.reversing.filter(r => r !== modLog._id)
        }, () => {
            console.log(err);
            alert('An error occurred. Please try again later');
        })));
    }

    /**
     * 
     * @param {Object} action ModLogs document
     * 
     * Navigates to the content in question
     */
    contentNav = action => {
        if (action.imageID) this.props.imageNav(`/image/${action.imageID}#comment-${action.contentID}`);
        else this.props.imageNav(`/u/${action.user}#comment-${action.contentID}`);
    }

    render(){
        return (
            <motion.tbody style={{overflowX: 'hidden'}} transition={t.transition} exit={this.props.contentExit} animate={t.normalize} initial={this.props.contentEnter}>
                {this.sortModLogs(this.props.mod_logs_dash).filter((modLog, i) => {
                    if ((i >= (this.props.modLogPage * itemsPerPage) - 1 - itemsPerPage) && (i < (this.props.modLogPage * itemsPerPage))) return true;
                    else return false;
                }).map(modLog => (
                    <tr>
                        <th className="table-text-sm mobile-px-1" style={{verticalAlign: 'middle'}} scope="row">{modLog.username}</th>
                        <td className="text-center text-break table-text-sm mobile-px-1" style={{verticalAlign: 'middle'}}>{modLog.contentID}</td>
                        <td className="text-center table-text-sm mobile-px-1" style={{verticalAlign: 'middle'}}><span className="text-transform-capitalize">{modLog.action}</span> {modLog.type.split('-').map((word, w) => <span className="text-transform-capitalize">{w ? ' ' : ''}{word}</span>)}</td>
                        <td className="text-center table-text-sm mobile-px-1" style={{verticalAlign: 'middle'}}>
                            {h.makeDateHR(new Date(modLog.timestamp))}
                            <br/>
                            {h.getTimeHR(new Date(modLog.timestamp))}
                        </td>
                        <td className="table-text-sm mobile-px-1 table-button-container" style={{verticalAlign: 'middle'}}>
                            {this.state.reversing.indexOf(modLog._id) !== -1 ?
                            <MDBBtn disabled className="d-block mx-auto report-buttons" color="dark"><Spinner size="sm" className="me-2" />Reversing</MDBBtn> :
                            <MDBBtn onClick={() => this.reverse(modLog)} className="d-block mx-auto report-buttons" color="dark">Reverse</MDBBtn>}
                            {modLog.type === 'comment' ?
                            <>
                                <MDBBtn color="primary" onClick={() => this.contentNav(modLog)} className="d-block mx-auto report-buttons mt-2">Visit<i className="fas fa-chevron-right ms-2"></i></MDBBtn>
                            </> : 
                            <>
                                <MDBBtn color="primary" onClick={() => this.props.imageNav(`/image/${modLog.imageID}`)} className="d-block mx-auto report-buttons mt-2">Visit<i className="fas fa-chevron-right ms-2"></i></MDBBtn>
                            </>}
                        </td>
                    </tr>
                ))}
            </motion.tbody>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
  }
  
  export default connect(mapStateToProps, { set_max_mod_log_pages, set_mod_logs })(ModLogPage);
import React from 'react';
import { connect } from 'react-redux';
import {
    MDBCheckbox,
    MDBContainer
} from 'mdb-react-ui-kit';
import h from '../../utilities/helpers';
import t from '../../utilities/transitions';
import ContentNav from './content/ContentNav';
import ContentPage from './content/ContentPage';
import { StaticRouter, Switch, Route } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { set_profile_content_page } from '../../redux/actions';

class Content extends React.Component{
    constructor(){
        super();
        this.state = {
            loaded: false,
            leftButtonExit: t.fade_out_minimize,
            rightButtonExit: t.fade_out_minimize,
            contentExit: t.fade_out,
            contentEnter: t.fade_out
        }
    }

    /**
     * Fired when the user clicks the left nav button in the comment section
     */
    back = () => {
        this.setState({
            ...this.state,
            rightButtonExit: (this.props.profileContentPage === this.props.totalProfileContentPages) ? t.fade_out_minimize : t.normalize,
            leftButtonExit: (this.props.profileContentPage > 2) ? t.bob_left : t.fade_out_minimize,
            contentExit: t.fade_out_right,
            contentEnter: t.fade_out_left,
            softLoading: true
        }, () => this.props.set_profile_content_page(this.props.profileContentPage - 1));
    }

    /**
     * Fired when the user clicks the right nav button in the comment section
     */  
    next = () => {
        this.setState({
            ...this.state,
            rightButtonExit: (this.props.profileContentPage === this.props.totalProfileContentPages - 1) ? t.fade_out_minimize : t.bob_right,
            leftButtonExit: (this.props.profileContentPage === 1) ? t.fade_out_minimize : t.normalize,
            contentExit: t.fade_out_left,
            contentEnter: t.fade_out_right,
            softLoading: true
        }, () => this.props.set_profile_content_page(this.props.profileContentPage + 1)); 
    }

    /**
     * 
     * @param {Number} index - Index of the page selector popover
     * 
     * Fired when the user clicks the Go button in the page selector
     * Navigates to the page that was entered in the page selector input
     */
    go = index => {
        let pageNumber = document.getElementById(`content-page-number-${index}`).value;
        if (h.isNumeric(pageNumber)){
            pageNumber = Number(pageNumber);
        } else pageNumber = 1;
        if (this.props.profileContentPage !== pageNumber) this.setState({
            ...this.state,
            rightButtonExit: (this.props.profileContentPage > pageNumber) ? t.normalize : (pageNumber === this.props.totalProfileContentPages ? t.fade_out_minimize : t.bob_right),
            leftButtonExit: (this.props.profileContentPage > pageNumber) ? (pageNumber === 1 ? t.fade_out_minimize : t.bob_left) : t.normalize,
            contentExit: (this.props.profileContentPage > pageNumber) ? t.fade_out_right : t.fade_out_left,
            contentEnter: (this.props.profileContentPage < pageNumber) ? t.fade_out_right : t.fade_out_left,
            softLoading: true
        }, () => this.props.set_profile_content_page(pageNumber));
    }

    render(){
        return (
            <MDBContainer fluid className="px-1">
                {this.props.profileContent.length ?
                <>
                    <div className="d-flex justify-content-between user-content-header-row">
                        {this.props.totalProfileContentPages > 1 ?
                        <StaticRouter location={`/${this.props.profileContentPage}`}>
                            <AnimatePresence exitBeforeEnter>
                                <Switch key={this.props.profileContentPage}>
                                    <Route exact path="/:page">
                                        <ContentNav 
                                            page={this.props.profileContentPage} 
                                            key={this.props.profileContentPage}
                                            leftButtonExit={this.state.leftButtonExit}
                                            rightButtonExit={this.state.rightButtonExit}
                                            back={this.back}
                                            next={this.next}
                                            go={this.go}
                                            loaded={this.state.loaded}
                                            changeShow={this.props.changeShow}
                                            showImages={this.props.showImages}
                                            showComments={this.props.showComments}
                                            index={1}
                                        />
                                    </Route>
                                </Switch>
                            </AnimatePresence>
                        </StaticRouter> : 
                        <div className="mt-4 ms-4">
                            <MDBCheckbox 
                                name="showImages" 
                                id="show-images-check" 
                                onChange={this.props.changeShow} 
                                checked={this.props.showImages}
                                label="Show Images" 
                                labelClass="mb-0"
                            />
                            <MDBCheckbox 
                                name="showComments" 
                                id="show-comments-check" 
                                onChange={this.props.changeShow} 
                                checked={this.props.showComments}
                                className="mt-2" 
                                label="Show Comments" 
                                labelClass="mt-2"
                            />
                        </div>}
                        <div>
                            <h5 className="text-end">{h.numberWithCommas(this.props.profileContentAll.filter(content => {
                                if (content.comment_id) return false;
                                else return true;
                            }).length)} Images</h5>
                            <h5 className="text-end">{h.numberWithCommas(this.props.profileContentAll.filter(content => {
                                if (content.comment_id) return true;
                                else return false;
                            }).length)} Comments</h5>
                        </div>
                    </div>
                    <hr></hr>
                    <div style={{overflowX: 'hidden'}}>
                        <StaticRouter location={`/${this.props.profileContentPage}`}>
                            <AnimatePresence exitBeforeEnter>
                                <Switch key={this.props.profileContentPage}>
                                    <Route exact path="/:page">
                                        <ContentPage
                                            key={this.props.profileContentPage}
                                            contentExit={this.state.contentExit}
                                            contentEnter={this.state.contentEnter}
                                            profileContentNav={this.props.profileContentNav}
                                            goToComment={this.props.goToComment}
                                        />
                                    </Route>
                                </Switch>
                            </AnimatePresence>
                        </StaticRouter>
                    </div>
                    {this.props.totalProfileContentPages > 1 ?
                        <>
                            <StaticRouter location={`/${this.props.profileContentPage}`}>
                                <AnimatePresence exitBeforeEnter>
                                    <Switch key={this.props.profileContentPage}>
                                        <Route exact path="/:page">
                                            <ContentNav 
                                                page={this.props.profileContentPage} 
                                                key={this.props.profileContentPage}
                                                leftButtonExit={this.state.leftButtonExit}
                                                rightButtonExit={this.state.rightButtonExit}
                                                back={this.back}
                                                next={this.next}
                                                go={this.go}
                                                loaded={this.state.loaded}
                                                changeShow={this.props.changeShow}
                                                showImages={this.props.showImages}
                                                showComments={this.props.showComments}
                                                index={2}
                                            />
                                        </Route>
                                    </Switch>
                                </AnimatePresence>
                            </StaticRouter>
                            <div className="mb-4"></div>
                        </> : <></>}
                </> :
                <div className="mt-4 ms-4">
                    <MDBCheckbox 
                        name="showImages" 
                        id="show-images-check" 
                        onChange={this.props.changeShow} 
                        checked={this.props.showImages}
                        label="Show Images" 
                        labelClass="mb-0"
                    />
                    <MDBCheckbox 
                        name="showComments" 
                        id="show-comments-check" 
                        onChange={this.props.changeShow} 
                        checked={this.props.showComments}
                        className="mt-2" 
                        label="Show Comments" 
                        labelClass="mt-2"
                    />
                    <h1 className="mt-5 text-center display-6">{this.props.profileInfo.username} has not posted anything with these filters</h1>
                </div>
                }
            </MDBContainer>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
  }
  
  export default connect(mapStateToProps, { set_profile_content_page })(Content);
import React from 'react';
import axios from 'axios';
import h from '../../../../utilities/helpers';
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBContainer,
    MDBListGroup,
    MDBListGroupItem
} from 'mdb-react-ui-kit';
import Spinner from '../../../../components/Spinner';

/**
 * This is the Disabled Users modal
 * A list of disabled users is displayed, and the user can restore those users
 */

class DisabledUsers extends React.Component{
    constructor(){
        super();
        this.state = {
            /**
             * usersRestoring: Array containing a list of disabled users that are in the process of being restored
             */
            usersRestoring: []
        }
    }

    componentDidUpdate(){
        // If there are no more disabled users and the modal is not hidden, hide the modal
        if (!this.props.users.length && this.props.disabledModalShown) this.props.setDisabledModal(false);
    }

    restoreUser = user => {
        /**
         * Fired when the user clicks the blue Restore button next to one of the users
         * Restores the user, then updates the parent
         */
        if (this.state.usersRestoring.indexOf(user) === -1) this.setState({
            ...this.state,
            usersRestoring: [
                ...this.state.usersRestoring,
                user
            ]
        }, () => axios.post('/users/restore-user', {
            username: user
        }).then(res => this.setState({
            ...this.state,
            usersRestoring: this.state.usersRestoring.filter(u => u !== user)
        }, () => {
            const notificationInfo = {
                icon: <i className="fas fa-user-check fa-lg text-success"></i>,
                text: `${res.data.username} Restored`
            }
            this.props.updateUser(res.data, notificationInfo);
        })).catch(err => this.setState({
            ...this.state,
            usersRestoring: this.state.usersRestoring.filter(u => u !== user)
        }, () => {
            console.log(err);
            alert(`An error occurred while restoring ${user} - Please try again later`);
        })));
    }

    render(){
        return (
            <>
              
              {typeof window !== 'undefined' && window.navigator ?
              <MDBModal staticBackdrop={this.state.usersRestoring.length} show={this.props.disabledModalShown} setShow={this.props.setDisabledModal} tabIndex='-1'>
                <MDBModalDialog size="xl">
                  <MDBModalContent>
                    <MDBModalHeader>
                      <MDBModalTitle>Disabled Users</MDBModalTitle>
                      <MDBBtn className='btn-close' color='none' onClick={this.props.toggleDisabledModal}></MDBBtn>
                    </MDBModalHeader>
                    <MDBModalBody>
                        <MDBContainer className="px-2">
                            <div className="row">
                                <div className="col-4">
                                    <p className="mb-1 fw-bold">User</p>
                                </div>
                                <div className="col-4">
                                    <p className="mb-1 fw-bold text-center">Disabled On</p>
                                </div>
                                <div className="col-4">
                                    <p className="mb-1 fw-bold text-center">Actions</p>
                                </div>
                            </div>
                        </MDBContainer>
                        <MDBListGroup className="px-0">
                            {this.props.users.map(user => (
                                <MDBListGroupItem key={user.username + '-disabled'} className="container-fluid px-2">
                                    <div className="row align-items-center">
                                        <div className="col-4">
                                            <p className="mb-1 fw-bold text-default">{user.username}</p>
                                        </div>
                                        <div className="col-4">
                                            <p className="mb-1 fw-bold text-center text-default">{h.makeDateHR(new Date(user.actions.filter(a => a.type === 'userDisabled').sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))[0].timestamp))}</p>
                                        </div>
                                        <div className="col-4">
                                            <div className="show-lg d-flex justify-content-center">
                                                {this.state.usersRestoring.indexOf(user.username) === -1 ?
                                                <MDBBtn onClick={() => this.restoreUser(user.username)} color="primary" className="mx-auto"><i className="fas fa-user-check me-2"></i>Restore User</MDBBtn> :
                                                <MDBBtn disabled color="primary" className="mx-auto"><Spinner size="sm" className="me-2" />Restoring</MDBBtn>}
                                            </div>
                                            <div className="show-sm d-flex justify-content-center">
                                                {this.state.usersRestoring.indexOf(user.username) === -1 ?
                                                <MDBBtn onClick={() => this.restoreUser(user.username)} color="primary" size="sm" className="mx-auto text-nowrap">Restore</MDBBtn> :
                                                <MDBBtn disabled color="primary" size="sm" className="mx-auto text-nowrap"><Spinner size="sm" className="me-2" /></MDBBtn>}
                                            </div>
                                        </div>
                                    </div>
                                </MDBListGroupItem>
                            ))}
                        </MDBListGroup>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn size="lg" style={{backgroundColor: 'var(--mdb-gray)'}} onClick={this.props.toggleDisabledModal}>
                            Close
                        </MDBBtn>
                    </MDBModalFooter>
                  </MDBModalContent>
                </MDBModalDialog>
              </MDBModal> : <></>}
            </>
        );
    }
}

export default DisabledUsers;
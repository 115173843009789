import React from 'react';
import { motion } from 'framer-motion';
import { connect } from 'react-redux';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { withRouter } from 'react-router-dom';
import t from '../utilities/transitions';
import h from '../utilities/helpers';
import {
    MDBSpinner,
    MDBContainer,
    MDBCard,
    MDBCardBody,
    MDBCardText,
    MDBCardHeader,
    MDBBadge,
    MDBTabsContent,
    MDBTabsPane,
    MDBAlert,
    MDBBtn
} from 'mdb-react-ui-kit';
import axios from 'axios';
import Comments from './user/Comments';
import Content from './user/Content';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { 
    route, 
    set_profile_content_page, 
    set_max_profile_content_pages, 
    set_profile_content,
    set_profile_content_all, 
    set_profile_content_sort, 
    set_profile_comment_page, 
    set_max_profile_comment_pages, 
    set_profile_comments, 
    set_profile_comment_sort,
    set_profile_info,
    add_profile_comment,
    select_dashboard_item,
    set_nsfw_modal
} from '../redux/actions';
import { io } from 'socket.io-client';

const itemsPerPage = 40;

class User extends React.Component{
    constructor(props){
        super();
        this.dashboardItem = props.dashboardItemSelected;
        this.state = {
            /**
             * loaded: Boolean - Whether initial data has loaded
             * exit: Object - framer-motion exit animation
             * tabSelected: String - "content" | "comments" - The tab that the user is on
             * showImages: Boolean - Whether the user has opted to display images
             * showComments: Boolean - Whether the user has opted to display comments
             * socket: false | Socket.io socket object
             */
            loaded: false,
            exit: this.getInitialExit(),
            tabSelected: 'content',
            showImages: true,
            showComments: true,
            socket: false
        }
    }

    componentDidMount(){
        this.setState({
            ...this.state,
            exit: t.fade_out
        }, () => {
            this.props.select_dashboard_item('settings');
            this.setSocket();
        });
    }

    /**
     * 
     * @param {Object} prevProps - Previous this.props object
     * 
     * If new content arrives, show it (might remove)
     */
    componentDidUpdate(prevProps){
        if (this.state.loaded && prevProps.profileContentAll.length !== this.props.profileContentAll.length){
            this.changeShow({
                target: {
                    name: '1',
                    checked: true
                }
            });
        }
    }

    /**
     * Disconnect socket when unmounted
     */
    componentWillUnmount(){
        if (this.state.socket && this.state.socket.disconnect) this.state.socket.disconnect();
    }

    /**
     * Initializes the socket connection
     * Handles events
     *  
     */
    setSocket = () => this.setState({
        ...this.state,
        socket: io('/', {
            query: {
                type: 'profiles',
                room: this.props.match.params.username.toLowerCase()
            }
        })
    }, () => {
        this.state.socket.on('comment-receive', this.props.add_profile_comment);
        this.state.socket.on('mod-action-notification', this.load);
        this.load();
    });

    /**
     * 
     * @param {CheckboxEvent} e 
     * 
     * Triggered when the user toggles any of the options on the User Content page
     * Can toggle show images/comments
     */
    changeShow = e => this.setState({
        ...this.state,
        [e.target.name]: e.target.checked
    }, () => {
        let content = this.props.profileContentAll.filter(content => {
            if (content.comment_id) return this.state.showComments
            else return this.state.showImages;
        });
        this.props.set_max_profile_content_pages(Math.ceil(content.length / itemsPerPage));
        this.props.set_profile_content(content);
    });


/**
 * Load the profile info
 * Set comments, images, and profile comments into state, calculate pages
 * Spawn NSFW modal if necessary
 */
    load = () => {
        let loaded = this.state.loaded;
        if (!this.props.profileInfo.username || !(this.props.profileInfo.username.toLowerCase() === this.props.match.params.username)) axios.get(`/profile/${this.props.match.params.username}`).then(res => {
            this.props.set_profile_info(res.data.userInfo)
            this.props.set_max_profile_comment_pages(Math.ceil(res.data.userInfo.profileComments.length / itemsPerPage));
            this.props.set_profile_comments(res.data.userInfo.profileComments);
            const content = [
                ...res.data.userInfo.comments,
                ...res.data.userInfo.images
            ].sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
            this.props.set_max_profile_content_pages(Math.ceil(content.length / itemsPerPage));
            this.props.set_profile_content_all(content);
            this.setState({
                ...this.state,
                loaded: true,
                tabSelected: (window.location.hash.split('#comment-').length > 1 && !this.state.loaded) ? 'comments' : this.state.tabSelected
            }, () => {
                if (window.location.hash && !loaded){
                    let commentNumber = window.location.hash.split('#comment-')[1];
                    if (h.isNumeric(commentNumber)){
                        commentNumber = Number(commentNumber);
                        const comment = res.data.userInfo.profileComments.find(c => c.comment_id === commentNumber);
                        if (comment) this.goToComment(comment);
                    }
                }
                let nsfwModal = false;
                if (!this.props.userInfo.nsfwAccepted) res.data.userInfo.images.forEach(image => {
                    if (image.nsfw & !this.props.showNsfwModal && !nsfwModal) {
                        console.log('top');
                        nsfwModal = true;
                        this.props.set_nsfw_modal(true);
                    }
                });
            });
        }).catch(err => {
            console.log(err);
            if (err.response.status === 404) this.props.route('/not-found');
            else setTimeout(this.load, 1000);
        });
        else this.setState({
            ...this.state,
            loaded: true
        }, () => axios.get(`/profile/${this.props.match.params.username}`).then(res => {
            this.props.set_profile_info(res.data.userInfo)
            this.props.set_max_profile_comment_pages(Math.ceil(res.data.userInfo.profileComments.length / itemsPerPage));
            const content = [
                ...res.data.userInfo.comments,
                ...res.data.userInfo.images
            ].sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
            this.props.set_max_profile_content_pages(Math.ceil(content.length / itemsPerPage));
            this.props.set_profile_content_all(content);
            if (window.location.hash.split('#comment-').length > 1 && !loaded) this.setState({
                ...this.state,
                tabSelected: 'comments'
            }, () => {
                let commentNumber = window.location.hash.split('#comment-')[1];
                if (h.isNumeric(commentNumber)){
                    commentNumber = Number(commentNumber);
                    const comment = res.data.userInfo.profileComments.find(c => c.comment_id === commentNumber);
                    if (comment) this.goToComment(comment);
                }
                let nsfwModal = false;
                if (!this.props.userInfo.nsfwAccepted) res.data.userInfo.images.forEach(image => {
                    if (image.nsfw & !this.props.showNsfwModal && !nsfwModal){
                        console.log('bottom');
                        nsfwModal = true;
                        this.props.set_nsfw_modal(true);
                    }
                });
            });
        }).catch(err => {
            console.log(err);
            if (err.response.status === 404) this.props.route('/not-found');
            else setTimeout(this.load, 1000);
        }));
    } 

    getInitialExit = () => {
        return t.fade_out;
    }

    /**
     * 
     * @returns The user's name, along with a badge to indicate their role
     */
    getBadge = () => {
        switch(this.props.profileInfo.role){
            case 'Chadmin':
                return (
                    <span className="name-chadmin">
                        <MDBBadge className="badge-chadmin">
                            <div className="d-flex align-items-center">
                                <div style={{height: '1.6em', width: '1.6em'}} className="d-flex justify-content-center align-items-center">
                                    <div className="fit-images" style={{backgroundImage: `url("/assets/images/meltrans.png")`}}></div>
                                </div>
                                <p className="m-0">Chadmin</p>
                                {this.props.profileInfo.oldfag ?
                                <div className="position-relative">
                                    <MDBBadge className="badge-oldfag" color='danger' notification pill>
                                        Oldfag
                                    </MDBBadge>
                                </div> : <></>}
                            </div>
                        </MDBBadge>
                    </span>
                );
            case 'Janny':
                return (
                    <span className="name-janny">
                        <MDBBadge className="badge-janny">
                            <div className="d-flex align-items-center">
                                <div style={{height: '1.6em', width: '1.6em'}} className="d-flex justify-content-center align-items-center">
                                    <div className="fit-images" style={{backgroundImage: `url("/assets/images/thomastrans.png")`}}></div>
                                </div>
                                <p className="m-0">Janny</p>
                                {this.props.profileInfo.oldfag ?
                                <div className="position-relative">
                                    <MDBBadge className="badge-oldfag" color='danger' notification pill>
                                        Oldfag
                                    </MDBBadge>
                                </div> : <></>}
                            </div>
                        </MDBBadge>
                    </span>
                );
            case 'Verified':
                return (
                    <span className="name-verified">
                        <MDBBadge className="badge-verified">
                            <div className="d-flex align-items-center">
                                <div style={{height: '1.6em', width: '1.6em'}} className="d-flex justify-content-center align-items-center">
                                    <div className="fit-images" style={{backgroundImage: `url("/assets/images/verifiedlogotrans.png")`}}></div>
                                </div>
                                <p className="m-0">Verified</p>
                                {this.props.profileInfo.oldfag ?
                                <div className="position-relative">
                                    <MDBBadge className="badge-oldfag" color='danger' notification pill>
                                        Oldfag
                                    </MDBBadge>
                                </div> : <></>}
                            </div>
                        </MDBBadge>
                    </span>
                );
            default:
                console.log('oob badge', this.props.profileInfo.badge);
                return <></>
        }
    }

    selectTab = (e, option) => this.setState({
        ...this.state,
        tabSelected: option
    });

    /**
     * 
     * @param {String} dashboardItem - Dashboard tab
     * 
     * Returns the user to their dashboard at the tab that they were on
     */
    routeToDashboard = dashboardItem => {
        if (dashboardItem === 'settings') this.props.select_dashboard_item('settings-from-user');
        else this.props.select_dashboard_item(dashboardItem);
        this.setState({
            ...this.state,
            exit: t.fade_out_right
        }, () => this.props.route('/dashboard'));
    }

    /**
     * If the user visited the page from the dashboard, there will be a button that they can click which will allow them to return to what they are doing
     * 
     * @returns Alert button that when clicked will return the user to the dashboard on the tab that they were on
     */
    renderDashboardButton = () => {
        switch(this.dashboardItem){
            case 'settings':
                if (this.props.userInfo.username && this.props.match.params.username.toLowerCase() === this.props.userInfo.username.toLowerCase()) return (
                    <MDBAlert 
                        className="mb-2 cursor-pointer" 
                        show 
                        style={{
                            width: '250px',
                            maxWidth: '90%'
                        }}
                        onClick={() => this.routeToDashboard('settings')} 
                        color="primary"
                    >
                        <i className="fas fa-chevron-left me-2"></i>
                        Edit Profile
                        <i className="fas fa-user ms-2"></i>
                    </MDBAlert>
                );
                else return <></>
            case 'reports':
                return (
                    <MDBAlert 
                        className="mb-2 cursor-pointer" 
                        show 
                        style={{
                            width: '250px',
                            maxWidth: '90%'
                        }}
                        onClick={() => this.routeToDashboard('reports')} 
                        color="warning"
                    >
                        <i className="fas fa-chevron-left me-2"></i>
                        Reports
                        <i className="fas fa-flag ms-2"></i>
                    </MDBAlert>
                );
            case 'modlogs':
                return (
                    <MDBAlert 
                        className="mb-2 cursor-pointer" 
                        show 
                        style={{
                            width: '250px',
                            maxWidth: '90%'
                        }}
                        onClick={() => this.routeToDashboard('modlogs')} 
                        color="danger"
                    >
                        <i className="fas fa-chevron-left me-2"></i>
                        Mod Logs
                        <i className="fas fa-gavel ms-2"></i>
                    </MDBAlert>
                );
            default: 
                return <></>
        }
    }

    /**
     * 
     * @param {Number} commentID - ref Comments.comment_id
     * 
     * Hit when the user clicks on a quoted comment
     * If it is already in the document, scroll to it
     * Else, wait and try again (could happen due to framer-motion transitions)
     */
    goToComment = comment => {
        window.location.hash = `#comment-${comment.comment_id}`;
        const index = this.props.profileComments.indexOf(comment) + 1;
        const page = Math.ceil(index / itemsPerPage);
        this.props.set_profile_comment_page(page);
        this.setState({
            ...this.state,
            tabSelected: 'comments'
        }, () => {
            const scroll = () => setTimeout(() => {
                if (['u', 'user'].indexOf(window.location.pathname.split('/')[1]) !== -1){
                    const element = document.getElementById(`comment-${comment.comment_id}`);
                    if (element) element.scrollIntoView();
                    else {
                        scroll();
                    }
                }
            }, 200);
            scroll();
        });
    }

    render(){
        return (
            <motion.div transition={t.transition} exit={this.state.exit} animate={t.normalize} initial={this.state.exit}>
                {this.state.loaded ?
                <MDBContainer className="pb-4" fluid>
                    <div className="row user-profile-row">
                        <div className="col-lg-9 col-12 d-flex flex-column pt-4">
                            {this.renderDashboardButton()}
                            <Tabs value={this.state.tabSelected} onChange={this.selectTab}>
                                <Tab style={{minHeight: '48px'}} icon={<i className="fas fa-images"></i>} iconPosition="start" label="Content" value="content" />
                                <Tab style={{minHeight: '48px'}} icon={<i className="fas fa-comments"></i>} iconPosition="start" label="Profile Messages" value="comments" />
                            </Tabs>
                            <MDBTabsContent>
                                <MDBTabsPane show={this.state.tabSelected === 'content'}>
                                    <Content 
                                        changeShow={this.changeShow}
                                        showImages={this.state.showImages}
                                        showComments={this.state.showComments}
                                        goToComment={this.goToComment}
                                    />
                                </MDBTabsPane>
                                <MDBTabsPane show={this.state.tabSelected === 'comments'}>
                                    {this.props.profileInfo.profileCommentsDisabled ?
                                    <h5 className="text-center mt-4 display-6">
                                        {this.props.userInfo.username && this.props.match.params.username.toLowerCase() === this.props.userInfo.username.toLowerCase() ? 'You have' : `${this.props.profileInfo.username} has`} disabled profile comments
                                    </h5>
                                    : <Comments 
                                        googleReCaptchaProps={this.props.googleReCaptchaProps}
                                        socket={this.state.socket}
                                        goToComment={this.goToComment}
                                    />}
                                </MDBTabsPane>
                            </MDBTabsContent>
                        </div>
                        <div className="col-lg-3 col-12 py-4">
                            <MDBCard className="shadow-3-strong">
                                <MDBCardHeader>
                                    <div className="d-flex justify-content-between">
                                        <h4 className="display-6">{this.props.profileInfo.username}</h4>
                                        <h5 className="text-pkmn">#{this.props.profileInfo.user_id}</h5>
                                    </div>
                                    <div className="d-flex justify-content-center align-items-center square-15 mb-4">
                                        <div className="fit-images" style={{backgroundImage: `url("/api/image-id-full/${this.props.profileInfo.avatar}")`}}></div>
                                    </div>
                                    <h5>{this.getBadge()}</h5>
                                    <h5 className="m-0">Joined on {h.makeDateHR(new Date(this.props.profileInfo.creationDate))}</h5>
                                </MDBCardHeader>
                                <MDBCardBody>
                                    <MDBCardText>{this.props.profileInfo.bio}</MDBCardText>
                                </MDBCardBody>
                            </MDBCard>
                        </div>
                    </div>
                    {(this.state.tabSelected === 'content' && this.props.profileContent.length) || (this.state.tabSelected === 'comments' && this.props.profileComments.length) ?
                    <MDBBtn 
                        onClick={() => document.getElementById('root').scrollTop = 0} 
                        style={{backgroundColor: '#607D8B'}}
                        size="lg" 
                        className="d-block mx-auto mt-3"
                    >
                        Return to Top
                        <i className="fas fa-level-up-alt ms-2"></i>
                    </MDBBtn> : <></>}
                </MDBContainer> :
                <div className="mt-5 w-100 d-flex justify-content-center">
                    <MDBSpinner style={{ width: '3.5rem', height: '3.5rem' }} grow color="primary" role='status' tag='span'/>
                </div>
                }
            </motion.div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
  }
  
export default connect(mapStateToProps, { 
    route, 
    set_profile_content_page, 
    set_max_profile_content_pages, 
    set_profile_content, 
    set_profile_content_all,
    set_profile_content_sort, 
    set_profile_comment_page, 
    set_max_profile_comment_pages, 
    set_profile_comments, 
    set_profile_comment_sort,
    set_profile_info,
    add_profile_comment,
    select_dashboard_item,
    set_nsfw_modal
})(withRouter(withGoogleReCaptcha(User)));
import React from 'react';
import { connect } from 'react-redux';
import {
    MDBProgress,
    MDBProgressBar,
    MDBToast
} from 'mdb-react-ui-kit';
import LinearProgress from '@mui/material/LinearProgress';

/**
 * This is the mini upload progress indicator that will appear in the bottom left side of the page if the user navigates away from the home page while an upload is in progress
 */

class UploadProgress extends React.Component{
    constructor(){
        super();
    }

    render(){
        return (
            <>
                {typeof window !== 'undefined' ?
                <MDBToast
                    show={window.location.pathname !== '/' && this.props.uploading}
                    position='bottom-left'
                    appendToBody
                    bodyContent={
                        <>
                            <strong className='me-auto'>{this.props.uploadProgress >= 100 ? 'Processing' : `Uploading - ${this.props.uploadProgress}%`}</strong>
                            <hr></hr>
                            {this.props.uploadProgress >= 100 ?
                            <LinearProgress /> :
                            <MDBProgress>
                                <MDBProgressBar width={this.props.uploadProgress} valuemin={0} valuemax={100} />
                            </MDBProgress>}
                        </>
                    }
                /> : <></>}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
}

export default connect(mapStateToProps, {})(UploadProgress);
import React from 'react';
import { connect } from 'react-redux';
import { motion } from 'framer-motion';
import t from '../utilities/transitions';
import h from '../utilities/helpers';
import './home/home.css';
import { 
    MDBBtn,
    MDBRipple,
    MDBContainer,
    MDBIcon,
    MDBCollapse,
    MDBInput,
    MDBValidation,
    MDBValidationItem,
    MDBTextArea,
    MDBProgress,
    MDBProgressBar,
    MDBSpinner,
    MDBCheckbox
} from 'mdb-react-ui-kit';
import { image_schema } from '../utilities/validations';
import { upload_images } from '../redux/actions';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import LinearProgress from '@mui/material/LinearProgress';
import EmojiPicker from '../components/EmojiPicker';

/**
 * This is the Upload page
 */

const fields = [
    {
        id: 'name',
        text: 'Name',
        type: 'input'
    },
    {
        id: 'manifesto',
        text: 'Manifesto',
        type: 'textarea'
    }
]

const allowedExtensions = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'image/bmp', 'image/webp', 'image/svg+xml'];

class Home extends React.Component{
    constructor(){
        super();
        this.state = {
            /**
             * files: Array - List of files that have been selected by the user
             * showDetails: Boolean - Whether the Details form is shown
             * inputs: Array - The input data (values, errors, etc)
             * imageHovered: String - md5 hash of the image that the user has their mouse over
             * uploading: Boolean indicating whether the user is in the process of uploading files
             * processing: Boolean indicating whether files have been selected and md5 hashes are being generated 
             */
            files: [],
            showDetails: false,
            inputs: fields.map(field => ({
                id: field.id,
                error: '',
                invalid: true,
                value: ''
            })),
            imageHovered: '',
            uploading: false,
            processing: false
        }
    }

    componentDidMount(){
        /**
         * Add paste event listener 
         * Prepare drop area
         * Blank changeHandler
         */
        document.onpaste = this.pasteFile;
        this.setDropArea();
        if (this.props.uploading) this.setState({
            ...this.state,
            uploading: true
        });
        else this.changeHandler({
            target: {
                name: ''
            }
        });
    }

    /**
     * 
     * @param {Object} prevProps - Previous this.props object
     * @param {Object} prevState - Previous this.state object
     * 
     * Any time the number of files in state changes, prime the upload space for drag/drop files
     */
    componentDidUpdate(prevProps, prevState){
        if ((!prevState.files.length && this.state.files.length) || (prevState.files.length && !this.state.files.length)){
            this.setDropArea();
        } 
        if (this.props.uploading && !this.state.uploading) this.setState({
            ...this.state,
            uploading: true
        });
    }

    /**
     * Triggered any time state.files changes
     * Prepares the upload space for files to be dragged/dropped
     */
    setDropArea = () => {
        const dropArea = (this.state.files.length) ? document.getElementById('file-upload-container-files') : document.getElementById('file-upload-container');
        ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
            dropArea.addEventListener(eventName, this.preventDefaults, false);
        });
        ['dragenter', 'dragover'].forEach(eventName => {
            dropArea.addEventListener(eventName, () => dropArea.classList.add('upload-highlight'), false);
        });
        
        ['dragleave', 'drop'].forEach(eventName => {
            dropArea.addEventListener(eventName, () => dropArea.classList.remove('upload-highlight'), false);
        });
        dropArea.addEventListener('drop', this.dropFiles, false)
    }

    preventDefaults = e => {
        e.preventDefault();
        e.stopPropagation();
    }

    /**
     * 
     * @param {Event Object} e - Drop event
     * 
     * User drags/drops files into form
     */
    dropFiles = e => {
        this.setState({
            ...this.state,
            processing: true
        }, async () => {
            let files = [];
            const fileArray = [].slice.call(e.dataTransfer.files);
            for (let i = 0; i < fileArray.length; i++){
                const file = fileArray[i];
                const md5 = await h.getMD5(file);
                if (allowedExtensions.indexOf(file.type) !== -1){
                    if (file.size < 15000001){
                        files.push({
                            name: file.name,
                            file: file,
                            path: URL.createObjectURL(file),
                            md5: md5
                        });
                    } else {
                        alert('Your file is too big (Max: 15MB)');
                    }
                } else {
                    alert('Please select a valid image file (png, jpg, gif, bmp, webp)');
                }
            }
            this.setState({
                ...this.state,
                files: [
                    ...this.state.files,
                    ...files.filter(file => !this.state.files.find(f => f.md5 === file.md5))
                ],
                processing: false
            }, () => {
                const lastFile = document.getElementById(`file-${this.state.files.length - 1}`);
                console.log(lastFile);
                if (lastFile) lastFile.scrollIntoView();
            });
        });
    }

    /**
     * 
     * @param {Event Object} e - Paste event
     * 
     * User pastes files from elsewhere
     */
    pasteFile = e => {
        this.setState({
            ...this.state,
            processing: true
        }, async () => {
            let files = [];
            const fileArray = [].slice.call(e.clipboardData.files);
            for (let i = 0; i < fileArray.length; i++){
                const file = fileArray[i];
                const md5 = await h.getMD5(file);
                if (allowedExtensions.indexOf(file.type) !== -1){
                    if (file.size < 15000001){
                        files.push({
                            name: file.name,
                            file: file,
                            path: URL.createObjectURL(file),
                            md5: md5
                        });
                    } else {
                        alert('Your file is too big (Max: 15MB)');
                    }
                } else {
                    alert('Please select a valid image file (png, jpg, gif, bmp, webp)');
                }
            }
            this.setState({
                ...this.state,
                files: [
                    ...this.state.files,
                    ...files.filter(file => !this.state.files.find(f => f.md5 === file.md5))
                ],
                processing: false
            }, () => {
                const lastFile = document.getElementById(`file-${this.state.files.length - 1}`);
                if (lastFile) lastFile.scrollIntoView();
            });
        });
    }

    selectFile = () => {
        /**
         * Creates a virtual file input
         * Adds a change event that sets the selected file into state
         * Appends to document body (necessary for iDevices and possibly others)
         * Clicks the input
         * Removes the input after the file is selected
         */
        let input = document.createElement('input');
        input.type = 'file';
        input.multiple = true;
        input.style.visibility = "hidden";
        input.style.position = "fixed";
        document.body.appendChild(input)
        input.onchange = e => {
            this.setState({
                ...this.state,
                processing: true
            }, async () => {
                let files = [];
                const fileArray = [].slice.call(e.target.files);
                for (let i = 0; i < fileArray.length; i++){
                    const file = fileArray[i];
                    const md5 = await h.getMD5(file);
                    
                    if (allowedExtensions.indexOf(file.type) !== -1){
                        if (file.size < 15000001){
                            files.push({
                                name: file.name,
                                file: file,
                                path: URL.createObjectURL(file),
                                md5: md5
                            });
                        } else {
                            
                            alert('Your file is too big (Max: 15MB)');
                        }
                    } else {
                        alert('Please select a valid image file (png, jpg, gif, bmp, webp)');
                    }
                }
                this.setState({
                    ...this.state,
                    files: [
                        ...this.state.files,
                        ...files.filter(file => !this.state.files.find(f => f.md5 === file.md5))
                    ],
                    processing: false
                }, () => {
                    const lastFile = document.getElementById(`file-${this.state.files.length - 1}`);
                    if (lastFile) lastFile.scrollIntoView();
                    document.body.removeChild(input);
                });
            });
        }
        input.click();
    }

    scroll = e => {
        document.getElementById('image-container').scrollLeft += (100 * Math.sign(e.deltaY));
    }

    /**
     * Fired when the user clicks the Details button
     * Toggles the Details form
     * If open, scroll into view
     * 
     */
    toggleDetails = () => this.setState({
        ...this.state,
        showDetails: !this.state.showDetails
    }, () => setTimeout(() => {
        if (this.state.showDetails) document.getElementById('hidden_check').scrollIntoView();
    }, 500));


    /**
     * Submit only if there isn't already a submission being sent
     * Validate inputs
     * Run image upload redux script
     */
    submit = () => {
       document.getElementById('form').classList.add('was-validated');
        if (!this.state.files.length) alert('You must select at least one image');
        else {

            let invalidInputs = this.state.inputs.filter(input => input.invalid);
            invalidInputs.forEach(input => document.getElementById(input.id).setCustomValidity('hello'));
            if (!this.state.working && !invalidInputs.length) this.setState({
                ...this.state,
                working: true
            }, () => {
                const data = Object.fromEntries(this.state.inputs.map(input => [input.id, input.value]));
                try {
                    image_schema.validateSync(data, {
                        abortEarly: false
                    });
                    const fd = new FormData();
                    for ( const key in data ) {
                        fd.append(key, data[key]);
                    }
                    fd.append('commentsDisabled', document.getElementById('commentsDisabled_check').checked);
                    fd.append('nsfw', document.getElementById('nsfw_check').checked);
                    fd.append('singleComments', document.getElementById('singleComments_check').checked);
                    fd.append('hidden', document.getElementById('hidden_check').checked);
                    this.setState({
                        ...this.state,
                        uploading: true
                    }, async () => {
                        try {
                            document.getElementById('root').scrollTop = 0;
                            const captchaKey = await h.getRecaptcha(this.props.googleReCaptchaProps);
                            fd.append('captchaKey', captchaKey);
                            this.state.files.forEach(file => fd.append('images', file.file, file.name));
                            this.props.upload_images(fd);
                            
                        } catch(err){
                            console.log(err);
                            this.setState({
                                ...this.state,
                                uploading: false
                            });
                        }
                    });
                } catch(err){
                    this.setState({
                        ...this.state,
                        working: false,
                        uploading: false
                    }, () => {
                        console.log(err);
                        alert('An error occurred. Please try again later');
                    });
                }
            });
            else this.setState({
                ...this.state,
                showDetails: true
            });
        }
   }


   /**
     * 
     * @param {KeyboardEvent} e - Keyboard event triggered by text change in any of the text inputs
     * 
     * Sets the updated values into state
     * Validates the inputs
     * Updates the inputs with errors
     * Adds/removes custom validity as appropriate
     */
    changeHandler = e => this.setState({
        ...this.state,
        inputs: this.state.inputs.map(input => {
            if (input.id === e.target.name) return {
                ...input,
                value: e.target.value
            }
            else return input
        })
    }, () => {
        const data = Object.fromEntries(this.state.inputs.map(input => [input.id, input.value]));
        try {
            image_schema.validateSync(data, {
                abortEarly: false
            });
            this.setState({
                ...this.state,
                inputs: this.state.inputs.map(input => {
                    document.getElementById(input.id).setCustomValidity('');
                    return {
                        ...input,
                        invalid: false,
                        error: ''
                    }
                })
            });
        } catch(err){
            console.log(err);
            let errorsAdded = [];
            this.setState({
                ...this.state,
                inputs: this.state.inputs.map(input => {
                    if (err.inner.find(error => error.path === input.id) && errorsAdded.indexOf(input.id) === -1){
                        errorsAdded.push(input.id);
                        return {
                            ...input,
                            invalid: true,
                            error: err.inner.find(error => error.path === input.id).message
                        }
                    } 
                    else return {
                        ...input,
                        invalid: false,
                        error: ''
                    };
                })
            }, () => this.state.inputs.forEach(input => {
                if (input.invalid) document.getElementById(input.id).setCustomValidity('hello');
                else document.getElementById(input.id).setCustomValidity('');
            }));
        }
    });

    /**
     * 
     * @param {Event Object} e 
     * 
     * Fired when the user clicks the Remove Files button
     * Clears all the files
     */
    clearFiles = e => {
        document.getElementById('singleComments_check').checked = true;
        e.stopPropagation();
        this.setState({
            ...this.state,
            files: []
        });
    }

    /**
     * 
     * @param {String} md5 - md5 of the image hovered
     * 
     */
    setImageHovered = md5 => this.setState({
        ...this.state,
        imageHovered: md5
    });

    /**
     * 
     * @param {String} md5 - md5 of the file to be removed
     * @param {Event Object} e 
     * 
     * Fired when the user clicks a red trash can on any of the selected files
     */
    removeFile = (md5, e) => {
        if (this.state.files.length < 3) document.getElementById('singleComments_check').checked = true;
        e.stopPropagation();
        this.setState({
            ...this.state,
            files: this.state.files.filter(file => file.md5 !== md5),
            imageHovered: ''
        });
    } 

    /**
     * 
     * @param {Event Object} e - Javascript event object
     * 
     * Prevent the form from submitting if the enter key is pressed
     */
    preventEnterSubmit = e => {
        const key = e.charCode || e.keyCode
        if (key === 13 && e.target.tagName === 'INPUT') e.preventDefault();
    }

    /**
     * Add emoji to the comment body if selected
     */
    selectEmoji = e => {
        if (!this.state.working) this.changeHandler({
            target: {
                value: this.state.inputs.find(i => i.id === 'manifesto').value + e.char,
                name: 'manifesto'
            }
        });
    }

    render(){
        return (
            <motion.div transition={t.transition} exit={t.fade_out} animate={t.normalize} initial={t.fade_out} className="pt-4 h-100">
                <MDBContainer className="pb-3">
                    {this.state.uploading ?
                    <>  
                        <h1 className="text-center display-4">{this.props.uploadProgress >= 100 ? 'Processing' : `Uploading - ${this.props.uploadProgress || 0}%`}</h1>
                        {this.props.uploadProgress >= 100 ?  <LinearProgress />
                        :
                        <>
                            <MDBProgress>
                                <MDBProgressBar width={this.props.uploadProgress} valuemin={0} valuemax={100} />
                            </MDBProgress>
                        </>}
                    </>
                    :
                    <>
                        <h1 className="text-center display-4">Upload</h1>
                        <hr></hr>
                        {this.state.processing ?
                        <div className="d-flex justify-content-center my-5">
                            <MDBSpinner color="primary" grow style={{ width: '3.5rem', height: '3.5rem' }}>
                                <span className='visually-hidden'>Loading...</span>
                            </MDBSpinner>
                        </div>  :
                        <>
                        {this.state.files.length ?
                        <>
                            <MDBRipple rippleColor="primary" id="file-upload-container-files" onClick={this.selectFile} className="container-fluid px-0 cursor-pointer">
                                <MDBBtn onClick={this.clearFiles} color="danger" size="lg" className="d-block ms-auto"><i className="fas fa-times me-2"></i>Clear All</MDBBtn>
                                <div className="row mx-0 justify-content-center">
                                    {this.state.files.map((file, f) => (
                                        <div id={`file-${f}`} key={file.md5} className="col-12 col-md-4 col-lg-3 mt-3">
                                            <div onMouseEnter={() => this.setImageHovered(file.md5)} onMouseLeave={() => this.setImageHovered('')} style={{cursor: 'pointer', border: '1px solid #607D8B'}} className={`mx-auto p-2 d-flex justify-content-center align-items-center square-15 position-relative ${this.state.imageHovered === file.md5 ? 'image-hover' : ''}`}>
                                                {this.state.imageHovered === file.md5 ?
                                                <>
                                                    <motion.p transition={t.transition} exit={t.fade_out} animate={t.normalize} initial={t.fade_out} style={{textOverflow: 'ellipsis'}} className="file-names position-absolute bottom-0 m-0">{file.name}</motion.p>
                                                    <MDBBtn onClick={e => this.removeFile(file.md5, e)} className="position-absolute image-delete-buttons" color="danger">
                                                        <MDBIcon size="lg" far icon="trash-alt" />
                                                    </MDBBtn>
                                                </>
                                                 : <></>}
                                                <div className="fit-images" style={{backgroundImage: `url("${file.path}")`}}></div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </MDBRipple>
                        </>  :
                        <>
                            <MDBRipple rippleColor="primary" onClick={this.selectFile} id="file-upload-container" className="d-flex justify-content-center align-items-center w-100 cursor-pointer rounded">
                                <MDBIcon icon='cloud-upload-alt' size='5x' />
                            </MDBRipple>
                        </>
                        }
                        </>}
                        <hr></hr>
                        <div className="d-flex justify-content-center">
                            <MDBBtn onClick={this.submit} className="me-2 d-block" color="success" size="lg"><i className="fas fa-paper-plane me-3"></i>Submit</MDBBtn>
                            <MDBBtn onClick={this.toggleDetails} className="ms-3 d-block" style={{backgroundColor: '#1976D2'}} size="lg"><i className="fas fa-info-circle me-2"></i>Details</MDBBtn>
                        </div>
                        <MDBCollapse show={this.state.showDetails}>
                            <div className="form-containers-lg mt-4">
                                <MDBValidation name="form" method="dialog" id="form">
                                {fields.map(i => (
                                    <MDBValidationItem key={i.id} className="pb-4" feedback={this.state.inputs.find(input => input.id === i.id).error} invalid={true} >
                                        {i.type === 'input' ?
                                        <MDBInput
                                            name={i.id}
                                            onChange={this.changeHandler}
                                            id={i.id}
                                            label={i.text}
                                            size="lg"
                                            className={!this.state.inputs.find(input => input.id === i.id).invalid ? 'mb-0' : 0}
                                        /> :
                                        <MDBTextArea
                                            name={i.id}
                                            onChange={this.changeHandler}
                                            id={i.id}
                                            label={i.text}
                                            size="lg"
                                            className={!this.state.inputs.find(input => input.id === i.id).invalid ? 'mb-0' : 0}
                                            style={{minHeight: '10rem'}}
                                            value={this.state.inputs.find(input => input.id === 'manifesto').value}
                                        />}
                                    </MDBValidationItem>
                                ))}
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <MDBCheckbox labelClass="mb-0" name="commentsDisabled" id='commentsDisabled_check' value='' label="Disable Comments" />
                                            <MDBCheckbox labelClass="mb-0" name="nsfw" id='nsfw_check' value='' label="Mark NSFW" />
                                            <MDBCheckbox disabled={this.state.files.length < 2} labelClass="mb-0" defaultChecked name="singleComments" id='singleComments_check' value='' label="Same Comment Section for All Images" />
                                            <MDBCheckbox labelClass="mb-0" name="hidden" id='hidden_check' value='' label="Hide from Browse page" /> 
                                        </div>
                                        <div className="d-flex">
                                            <EmojiPicker onEmojiSelect={this.selectEmoji} />
                                            <p className="text-end mb-0 ms-2"><span className={this.state.inputs.find(i => i.id === 'manifesto').value.length > 10000 ? 'text-danger' : ''}>{this.state.inputs.find(i => i.id === 'manifesto').value.length}</span>/10000</p>
                                        </div>
                                    </div> 
                                </MDBValidation>
                            </div>
                        </MDBCollapse>
                    </>}  
                </MDBContainer>
            </motion.div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
  }
  
  export default connect(mapStateToProps, { upload_images })(withGoogleReCaptcha(Home));
export const userSettings = [
    {
        id: 'email',
        text: 'Email Address',
        type: 'input'
    },
    {
        id: 'bio',
        text: 'Bio',
        type: 'textarea'
    },
    {
        text: 'Avatar',
        id: 'avatar',
        type: 'input'
    }
]

export const changePassword = [
    {
        id: 'current_password',
        text: 'Current Password'
    },
    {
        id: 'password1',
        text: 'New Password'
    },
    {
        id: 'password2',
        text: 'Re-enter password'
    }
]
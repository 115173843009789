import React from 'react';
import { connect } from 'react-redux';
import {
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBCardHeader,
    MDBCardFooter,
    MDBTooltip,
    MDBContainer,
    MDBBtn,
    MDBBadge,
    MDBSpinner
} from 'mdb-react-ui-kit';
import Spinner from '../../components/Spinner';
import { motion } from 'framer-motion';
import t from '../../utilities/transitions';
import h from '../../utilities/helpers';
import Report from './content/Report';
import Remove from './content/Remove';
import RemoveManifesto from './content/RemoveManifesto';
import RestoreModal from './content/RestoreModal';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { update_image, set_comment_page, route } from '../../redux/actions';

class Content extends React.Component{
    constructor(){
        super();
        this.state = {
            /**
             * bbCopied: Boolean - Whether the bbCode with the image link has been copied
             * directLinkCopied: Boolean - Whether the direct link to the image has been copied
             * reportOpen: Boolean - Whether the Report Image form is opened
             * removeOpen: Boolean - Whether the Remove Image form is opened
             * removeManifesto: Boolean - Whether the Remove Manifesto form is opened
             * imageReported: Boolean - Whether the image has been reported
             * imageRemoved: Boolean - Whether the image has been removed
             * modalShown: Boolean - Whether the Restore Image modal is shown
             * manifestoRestoring: Boolean - Whether the manifesto is in the process of being restored
             * tooltipComment: tooltipComment: JSX - Content of comment with comment_id state.commentHover
             * commentHover: Number - comment_id of quoted comment that the user is hovering over with their mouse
             * payQuasiInterval: false | Interval that updates the Pay Quasi debt clock every second
             * payQuasiAmount: Number - The number of pennies that Patrick S. Tomlinson owes Quasi101
             */
            bbCopied: false,
            directLinkCopied: false,
            reportOpen: false,
            removeOpen: false,
            removeManifestoOpen: false,
            imageReported: false,
            imageRemoved: false,
            modalShown: false,
            manifestoRestoring: false,
            tooltipComment: <></>,
            commentHover: 0,
            payQuasiInterval: false,
            payQuasiAmount: 3476225
        }
    }

    /**
     * If the image number is 4506, that is the Josiah tape. 
     * Set up the Pay Quasi interval
     */
    componentDidMount(){
        if (this.props.imageInfo.image_id === 4506) this.setState({
            ...this.state,
            payQuasiInterval: setInterval(this.calculateQuasi, 1000)
        }, () => this.calculateQuasi());
    }

    // Clear the Pay Quasi interval un unmount
    componentWillUnmount(){
        clearInterval(this.state.payQuasiInterval);
    }

    calculateQuasi = () => {
        // Time in California right now
        const nowCali = new Date(new Date().toLocaleString("en-US", {timeZone: "America/Los_Angeles"}));
        // 9:30AM in California on 9/30/2021
        let courtDateCali = new Date(new Date('9/30/2021').toLocaleString("en-US", {timeZone: "America/Los_Angeles"}));
        courtDateCali.setHours(9, 30, 0, 0);
        let courtDateSantaClara = new Date(new Date('4/7/2022').toLocaleString("en-US", {timeZone: "America/Los_Angeles"}));
        courtDateSantaClara.setHours(9, 0, 0, 0);
        let sfwaDate = new Date(new Date('7/13/2022').toLocaleString("en-US", {timeZone: "America/Los_Angeles"}));
        let firstCopyDate = new Date(new Date('10/3/2022').toLocaleString("en-US", {timeZone: "America/Los_Angeles"}));
        let secondCopyDate = new Date(new Date('10/28/2022').toLocaleString("en-US", {timeZone: "America/Los_Angeles"}));
        let googleFeesDate = new Date(new Date('3/7/2023').toLocaleString("en-US", {timeZone: "America/Los_Angeles"}));
        googleFeesDate.setHours(18, 7, 0, 0);

        // Principle amount in pennies
        const principle = 2373925;
        const principleSantaClara = 1102300;
        const sfwaPrinciple = 712455;
        const firstCopyPrinciple = 4150;
        const secondCopyPrinciple = 4150;
        const googleFeesPrinciple = 536988;

        const rate = 0.1;
        const timeDifference = nowCali - courtDateCali;
        const timeDifferenceSC = nowCali - courtDateSantaClara;
        const timeDifferenceSFWA = nowCali - sfwaDate;
        const timeDifferenceFirstCopy = nowCali - firstCopyDate;
        const timeDifferenceSecondCopy = nowCali - secondCopyDate;
        const timeDifferenceGoogleFees = nowCali - googleFeesDate;
        const msYears = (365 * 24 * 60 * 60 * 1000);

        let amount = (1 + (rate * (timeDifference)/msYears)) * principle;
        let amountSC = (1 + (rate * (timeDifferenceSC)/msYears)) * principleSantaClara;
        let amountSFWA = (1 + (rate * (timeDifferenceSFWA)/msYears)) * sfwaPrinciple;
        let amountFirstCopy = (1 + (rate * (timeDifferenceFirstCopy)/msYears)) * firstCopyPrinciple;
        let amountSecondCopy = (1 + (rate * (timeDifferenceSecondCopy)/msYears)) * secondCopyPrinciple;
        let amountGoogleFees = (1 + (rate * (timeDifferenceGoogleFees)/msYears)) * googleFeesPrinciple;
        let totalAmount = amount + amountSC + amountSFWA + amountFirstCopy + amountSecondCopy + amountGoogleFees;
        this.setState({
            ...this.state,
            payQuasiAmount: totalAmount
        });
    }

    /**
     * Copies the direct link of the image (or video) to the clipboard
     */
    copyDirectLink = () => {
        if (this.props.imageInfo.image_id === 4506) navigator.clipboard.writeText('https://nanaimg.net/fatfuck5.mp4');
        else navigator.clipboard.writeText(document.getElementById('direct-link').textContent);
        this.setState({
            ...this.state,
            directLinkCopied: true
        });
    }

    /**
     * Copies the bbCode of the image to the clipboard
     */
    copyBBCode = () => {
        navigator.clipboard.writeText(document.getElementById('bb-code').textContent);
        this.setState({
            ...this.state,
            bbCopied: true
        });
    }

    /**
     * 
     * @param {Event Object} e - Javascript click event or similar
     * @param {String} user - Username of user that was clicked
     * 
     * Fired when a user is clicked
     * Navigates to that user's profile
     */
    goToProfile = (e, user) => {
        e.preventDefault();
        this.props.route(`/u/${user}`);
    }

    /**
     * 
     * @param {Object} comment - Comments document
     * @returns The user's name, along with a badge to indicate their role
     */
    getBadge = () => {
        switch(this.props.imageInfo.badge){
            case 'Chadmin':
                return (
                    <span className="name-chadmin">
                        <Link style={{color: 'var(--mdb-yellow)'}} onClick={e => this.goToProfile(e, this.props.imageInfo.user)} to={`/u/${this.props.imageInfo.user}`}>
                            {this.props.imageInfo.user}
                        </Link>
                        <MDBBadge className="ms-2 badge-chadmin">
                            <div className="d-flex align-items-center">
                                <div style={{height: '1.6em', width: '1.6em'}} className="d-flex justify-content-center align-items-center">
                                    <div className="fit-images" style={{backgroundImage: `url("/assets/images/meltrans.png")`}}></div>
                                </div>
                                <p className="m-0">Chadmin</p>
                                {this.props.imageInfo.oldfag ?
                                <div className="position-relative">
                                    <MDBBadge className="badge-oldfag" color='danger' notification pill>
                                        Oldfag
                                    </MDBBadge>
                                </div> : <></>}
                            </div>
                        </MDBBadge>
                    </span>
                );
            case 'Janny':
                return (
                    <span className="name-janny">
                        <Link style={{color: 'var(--mdb-green)'}} onClick={e => this.goToProfile(e, this.props.imageInfo.user)} to={`/u/${this.props.imageInfo.user}`}>
                            {this.props.imageInfo.user}
                        </Link>
                        <MDBBadge className="ms-2 badge-janny">
                            <div className="d-flex align-items-center">
                                <div style={{height: '1.6em', width: '1.6em'}} className="d-flex justify-content-center align-items-center">
                                    <div className="fit-images" style={{backgroundImage: `url("/assets/images/thomastrans.png")`}}></div>
                                </div>
                                <p className="m-0">Janny</p>
                                {this.props.imageInfo.oldfag ?
                                <div className="position-relative">
                                    <MDBBadge className="badge-oldfag" color='danger' notification pill>
                                        Oldfag
                                    </MDBBadge>
                                </div> : <></>}
                            </div>
                        </MDBBadge>
                    </span>
                );
            case 'Verified':
                return (
                    <span className="name-verified">
                        <Link style={{color: 'rgb(28, 202, 255)'}} onClick={e => this.goToProfile(e, this.props.imageInfo.user)} to={`/u/${this.props.imageInfo.user}`}>
                            {this.props.imageInfo.user}
                        </Link>
                        <MDBBadge className="ms-2 badge-verified">
                            <div className="d-flex align-items-center">
                                <div style={{height: '1.6em', width: '1.6em'}} className="d-flex justify-content-center align-items-center">
                                    <div className="fit-images" style={{backgroundImage: `url("/assets/images/verifiedlogotrans.png")`}}></div>
                                </div>
                                <p className="m-0">Verified</p>
                                {this.props.imageInfo.oldfag ?
                                <div className="position-relative">
                                    <MDBBadge className="badge-oldfag" color='danger' notification pill>
                                        Oldfag
                                    </MDBBadge>
                                </div> : <></>}
                            </div>
                        </MDBBadge>
                    </span>
                );
            default:
                console.log('oob badge', this.props.imageInfo.badge);
                return <></>
        }
    }

    toggleRemoveForm = () => this.setState({
        ...this.state,
        removeOpen: !this.state.removeOpen
    });

    toggleRemoveManifestoForm = () => this.setState({
        ...this.state,
        removeManifestoOpen: !this.state.removeManifestoOpen
    });

    toggleReportForm = () => this.setState({
        ...this.state,
        reportOpen: !this.state.reportOpen
    });

    setImageReported = () => this.setState({
        ...this.state,
        imageReported: true
    });

    setShowModal = option => this.setState({
        ...this.state,
        modalShown: option
    });

    toggleShowModal = () => this.setState({
        ...this.state,
        modalShown: !this.state.modalShown
    });

    /**
     * Fired when the user restores a removed manifesto
     * Only runs if identical process if not already running
     * Makes request to server
     * Updates image in application state
     * Emits mod-action socket event
     */
    restoreManifesto = () => {
        if (!this.state.manifestoRestoring) this.setState({
            ...this.state,
            manifestoRestoring: true
        }, () => axios.post((h.checkJanny(this.props.userInfo)) ? '/support/restore/manifesto' : '/support/restore/manifesto-own', {
            commentSection: this.props.imageInfo.commentSection,
            imageID: this.props.imageInfo.image_id
        }).then(res => this.setState({
            ...this.state,
            manifestoRestoring: false
        }, () => {
            this.props.update_image(res.data.image);
            this.props.socket.emit('mod-action');
        })).catch(err => this.setState({
            ...this.state,
            manifestoRestoring: false
        }, () => {
            console.log(err);
            alert('An error occurred. Please try again later');
        })));
    }

    /**
     * 
     * @param {Number} postNumber - ref Comments.comment_id
     * 
     * Renders a comment that was quoted with the double hashes (##)
     * If the comment is in the comment section, pull the comment data from state and render
     * Else, render a spinner while the comment data is fetched from the server
     */
    renderTooltipComment = postNumber => {
        let comment = this.props.imageInfo.comments.find(comment => comment.comment_id === postNumber);
        if (comment) this.setState({
            ...this.state,
            commentHover: postNumber,
            tooltipComment: <>
                {comment.removed && !h.checkJanny(this.props.userInfo) ?
                <motion.div transition={t.transition} exit={t.fade_out_minimize} animate={t.normalize} initial={t.fade_out_minimize}>
                    <MDBCard className={`mx-auto ${(comment.removed) ? 'card-removed' : ''}`}>
                        <MDBCardBody className="d-flex">
                            <MDBCardText className={`messages text-break ${(comment.removed) ? 'text-light' : ''}`}>Comment Removed</MDBCardText>
                        </MDBCardBody>
                    </MDBCard>
                </motion.div> :
                <motion.div transition={t.transition} exit={t.fade_out_minimize} animate={t.normalize} initial={t.fade_out_minimize}>
                    <MDBCard className={`mx-auto ${(comment.removed) ? 'card-removed' : ''}`}>
                        <MDBCardHeader className="d-flex align-items-end">
                            <MDBCardTitle className={`mb-0 ${(comment.removed) ? 'text-light' : ''}`}>
                            {comment.badge ? this.getBadge(comment) : 
                            <>
                                <span className={comment.removed ? "text-light" : "text-secondary"}>{comment.name}</span> (ID: <span className="text-light" style={{backgroundColor: `var(${comment.poster_css_color})`}}>{comment.poster_id}</span>)
                            </>
                            }
                            <span className="mb-0 ms-2">{h.makeDateHR(new Date(comment.timestamp))} at {h.getTimeHR(new Date(comment.timestamp))}</span>
                            </MDBCardTitle>
                            <MDBCardTitle className={`ms-2 mb-0 text-pkmn ${(comment.removed) ? 'text-light' : ''}`}>#{comment.comment_id}</MDBCardTitle>    
                        </MDBCardHeader>
                        <MDBCardBody className="d-flex">
                            {comment.avatar ? 
                            <div style={{cursor: 'pointer'}} className="d-flex justify-content-center align-items-center square-8 mx-2">
                                <div className="fit-images" style={{backgroundImage: `url("/api/image-id/${comment.avatar}")`}}></div>
                            </div> : <></>}
                            <MDBCardText className={`messages text-break ${(comment.removed) ? 'text-light' : ''}`}>{this.parseComment_displayOnly(comment.comment_text)}</MDBCardText>
                        </MDBCardBody>
                    </MDBCard>
                </motion.div>}
            </>
        });
        else this.setState({
            ...this.state,
            commentHover: postNumber,
            tooltipComment: <>
                <motion.div transition={t.transition} exit={t.fade_out_minimize} animate={t.normalize} initial={t.fade_out_minimize}>
                    <MDBCard className="mx-auto">
                        <MDBCardBody className="d-flex justify-content-center align-items-center">
                            <MDBSpinner color="success" grow size="lg"></MDBSpinner>
                        </MDBCardBody>
                    </MDBCard>
                </motion.div>
            </>
        }, () => axios.get(`/api/comment/${postNumber}`).then(res => {
            comment = res.data.comment;
            if (this.state.commentHover === postNumber) this.setState({
                ...this.state,
                tooltipComment: <>
                    {comment.removed && !h.checkJanny(this.props.userInfo) ?
                    <motion.div transition={t.transition} exit={t.fade_out_minimize} animate={t.normalize} initial={t.fade_out_minimize}>
                        <MDBCard className={`mx-auto ${(comment.removed) ? 'card-removed' : ''}`}>
                            <MDBCardBody className="d-flex">
                                <h5 className="text-center text-light p-0">Comment removed</h5>
                            </MDBCardBody>
                        </MDBCard>
                    </motion.div> :
                    <motion.div transition={t.transition} exit={t.fade_out_minimize} animate={t.normalize} initial={t.fade_out_minimize}>
                        <MDBCard className={`mx-auto ${(comment.removed) ? 'card-removed' : ''}`}>
                            <MDBCardHeader className="d-flex align-items-end">
                                <MDBCardTitle className={`mb-0 ${(comment.removed) ? 'text-light' : ''}`}>
                                {comment.badge ? this.getBadge(comment) : 
                                <>
                                    <span className={comment.removed ? "text-light" : "text-secondary"}>{comment.name}</span> (ID: <span className="text-light" style={{backgroundColor: `var(${comment.poster_css_color})`}}>{comment.poster_id}</span>)
                                </>
                                }
                                <span className="mb-0 ms-2">{h.makeDateHR(new Date(comment.timestamp))} at {h.getTimeHR(new Date(comment.timestamp))}</span>
                                </MDBCardTitle>
                                <MDBCardTitle className={`ms-2 mb-0 text-pkmn ${(comment.removed) ? 'text-light' : ''}`}>#{comment.comment_id}</MDBCardTitle>    
                            </MDBCardHeader>
                            <MDBCardBody className="d-flex">
                                {comment.avatar ? 
                                <div style={{cursor: 'pointer'}} className="d-flex justify-content-center align-items-center square-8 mx-2">
                                    <div className="fit-images" style={{backgroundImage: `url("/api/image-id/${comment.avatar}")`}}></div>
                                </div> : <></>}
                                <MDBCardText className={`messages text-break ${(comment.removed) ? 'text-light' : ''}`}>{this.parseComment_displayOnly(comment.comment_text)}</MDBCardText>
                            </MDBCardBody>
                        </MDBCard>
                    </motion.div>}
                </>
            })
        }).catch(err => {
            console.log(err);
            if (this.state.commentHover === postNumber){
                if (err.response.status === 404) this.setState({
                    ...this.state,
                    tooltipComment: <>
                        <motion.div transition={t.transition} exit={t.fade_out_minimize} animate={t.normalize} initial={t.fade_out_minimize}>
                            <MDBCard className="mx-auto">
                                <MDBCardBody className="d-flex justify-content-center align-items-center">
                                    <h5 className="text-center">Comment does not exist</h5>
                                </MDBCardBody>
                            </MDBCard>
                        </motion.div>
                    </>
                });
                else this.setState({
                    ...this.state,
                    tooltipComment: <>
                        <motion.div transition={t.transition} exit={t.fade_out_minimize} animate={t.normalize} initial={t.fade_out_minimize}>
                            <MDBCard className="mx-auto">
                                <MDBCardBody className="d-flex justify-content-center align-items-center">
                                    <h5 className="text-center">An error occurred while fetching the comment</h5>
                                </MDBCardBody>
                            </MDBCard>
                        </motion.div>
                    </>
                });
            }
        }));
    }

    /**
     * 
     * @param {String} text - The text of a comment
     * 
     * Performs the same task as parseComment, but without the links or tooltips. Visual effects only.
     * 
     * @returns JSX array with parsed comment
     */
    parseComment_displayOnly = text => {
        let returnArray = [];
        text.split('##').forEach(segment => {
            if (segment){
                let postNumber = '';
                let stop = false;
                segment.split('').forEach(character => {
                    if (!stop && h.isNumeric(character)) postNumber += character;
                    else stop = true;
                });
                if (postNumber){
                    returnArray.push(
                        <span className="text-primary links-generic-light text-decoration-none cursor-pointer fw-bold">{'##' + postNumber}</span>);
                    returnArray.push(segment.split(postNumber).filter((piece, i) => i !== 0).join(postNumber));
                } else returnArray.push(segment);
            }
        });
        return returnArray;
    }

    /**
     * 
     * @param {Event} e - Javascript click event or similar
     * @param {Number} commentID - ref Comments.comment_id
     * 
     * Fired when the user clicks on a quoted comment
     * If the comment is not in the comment section, route to /comment/commentID
     * If the comment is in the comment section and in the document, scroll to it
     * If the comment is in the comment section and not in the document, switch to the appropriate page and scroll to it
     */
    clickQuotedComment = (e, commentID) => {
        e.preventDefault();
        window.location.hash = `#comment-${commentID}`;
        commentID = Number(commentID);
        document.getElementById(`tooltip-comment-${commentID}`).classList.add('d-none');
        const comment = this.props.imageInfo.comments.find(c => c.comment_id === commentID);
        if (comment){
            const element = document.getElementById(`comment-${commentID}`);
            if (element) element.scrollIntoView();
            else {
                const index = this.props.imageInfo.comments.indexOf(comment) + 1;
                const page = Math.ceil(index / 50);
                const maxPages = Math.ceil(this.props.imageInfo.comments.length / 50);
                this.setState({
                    ...this.state,
                    rightButtonExit: (this.commentPage > page) ? t.normalize : (page === maxPages ? t.fade_out_minimize : t.bob_right),
                    leftButtonExit: (this.commentPage > page) ? (page === 1 ? t.fade_out_minimize : t.bob_left) : t.normalize,
                    contentExit: (this.commentPage > page) ? t.fade_out_right : t.fade_out_left
                }, () => {
                    this.props.set_comment_page(page);
                    this.scrollToComment(commentID);
                });
            }
        } else this.props.route(`/comment/${commentID}`);
    }

    /**
     * 
     * @param {Number} commentID - ref Comments.comment_id
     * 
     * Hit when the user clicks on a quoted comment
     * If it is already in the document, scroll to it
     * Else, wait and try again (could happen due to framer-motion transitions)
     */
    scrollToComment = commentID => setTimeout(() => {
        const comment = document.getElementById(`comment-${commentID}`);
        if (comment) comment.scrollIntoView();
        else this.scrollToComment(commentID);
    }, 400);


    /**
     * 
     * @param {String} text - The text of a manifesto
     * 
     * Parses the string for double hashes (##) which are used to quote comments
     * Replaces them with links to comments, and tooltips with previews
     * 
     * @returns JSX array with parsed comment
     */
    parseManifesto = text => {
        let returnArray = [];
        text.split('##').forEach(segment => {
            if (segment){
                let postNumber = '';
                let stop = false;
                segment.split('').forEach(character => {
                    if (!stop && h.isNumeric(character)) postNumber += character;
                    else stop = true;
                });
                if (postNumber){
                    returnArray.push(
                        <MDBTooltip 
                            tag="span" 
                            wrapperProps={{
                                className: "text-primary links-generic-light text-decoration-none cursor-pointer fw-bold"
                            }}
                            title={this.state.tooltipComment}
                            onShow={() => this.renderTooltipComment(Number(postNumber))}
                            className="comment-tooltips ms-2"
                            id={`tooltip-comment-${postNumber}`}
                        ><Link to={`/comment/${postNumber}`} onClick={(e) => this.clickQuotedComment(e, postNumber)}>##</Link><span style={{cursor: 'text'}}>{postNumber}</span></MDBTooltip>);
                    returnArray.push(segment.split(postNumber).filter((piece, i) => i !== 0).join(postNumber));
                } else returnArray.push(segment);
            }
        });
        return returnArray;
    }

    render(){
        return (
            <motion.div transition={t.transition} exit={this.props.contentExit} animate={t.normalize} initial={this.props.contentExit}>
                <RestoreModal socket={this.props.socket} modalShown={this.state.modalShown} setShowModal={this.setShowModal} toggleShowModal={this.toggleShowModal} />
                <MDBContainer className="mt-4">
                    {this.props.imageInfo.image_id === 4506 ?
                    <video id="img-main" className="d-block mx-auto my-2 max-w-95" src="/fatfuck5.mp4" controls></video>:
                    <img 
                        id="img-main" 
                        style={{maxHeight: '1000px'}} 
                        className="img-fluid mx-auto d-block cursor-pointer" 
                        onClick={() => window.open(`${process.env.REACT_APP_IMAGE_HOST}/img/${this.props.imageInfo.filename}`)} 
                        src={`${process.env.REACT_APP_IMAGE_HOST}/img/${this.props.imageInfo.filename}`}
                    />}
                    <hr></hr>
                    <h5 className="mt-2 text-center">Direct Link</h5>
                    {this.props.imageInfo.image_id === 4506 ?
                    <MDBTooltip wrapperClass="d-block mx-auto text-unset max-w-95 text-overflow-ellipsis" wrapperProps={{ color: 'success', size: 'lg', onClick: this.copyDirectLink }} title={this.state.directLinkCopied ? 'Copied' : 'Click to Copy'}>
                        <span id="direct-link">https://nanaimg.net/fatfuck5.mp4</span>
                    </MDBTooltip> :
                    <MDBTooltip wrapperClass="d-block mx-auto text-unset max-w-95 text-overflow-ellipsis" wrapperProps={{ color: 'success', size: 'lg', onClick: this.copyDirectLink }} title={this.state.directLinkCopied ? 'Copied' : 'Click to Copy'}>
                        <span id="direct-link">{process.env.REACT_APP_IMAGE_HOST}/img/{this.props.imageInfo.filename}</span>
                    </MDBTooltip>}
                    <hr></hr>
                    {this.props.imageInfo.image_id === 4506 ?
                    <>
                        <h5 className="mt-2 text-center display-6">Fatrick currently owes Quasi ${h.dolHR(this.state.payQuasiAmount)}</h5>
                        <h5 className="mt-2 text-center"><a href="https://payquasi.lol">https://payquasi.lol</a></h5>
                    </>: 
                    <>
                        <h5 className="mt-2 text-center">BBcode</h5>
                        <MDBTooltip wrapperClass="d-block mx-auto text-unset max-w-95 text-overflow-ellipsis" wrapperProps={{ color: 'light', size: 'lg', onClick: this.copyBBCode }} title={this.state.bbCopied ? 'Copied' : 'Click to Copy'}>
                            <span id="bb-code">[img]{process.env.REACT_APP_IMAGE_HOST}/img/{this.props.imageInfo.filename}[/img]</span>
                        </MDBTooltip>
                    </>}
                </MDBContainer>
                <MDBContainer fluid>
                    <div className="d-flex justify-content-between align-items-start my-4">
                        <h5>{h.numberWithCommas(this.props.imageInfo.views)} View{this.props.imageInfo.views !== 1 ? 's' : ''}</h5>
                        {!this.props.imageInfo.removed ?
                        <div>
                            {h.checkJanny(this.props.userInfo) || this.props.userInfo._id === this.props.imageInfo.userID ?
                            <>
                                <MDBBtn onClick={this.toggleRemoveForm} color="dark" className="d-block ms-auto">Remove Image</MDBBtn>
                                <Remove socket={this.props.socket} show={this.state.removeOpen} />
                            </> :
                            <>
                                {this.state.imageReported ? 
                                <motion.h5 transition={t.transition} exit={t.fade_out_minimize} animate={t.normalize} initial={t.fade_out_minimize} className="text-end text-danger">Image Reported</motion.h5> :
                                <>
                                    <MDBBtn onClick={this.toggleReportForm} color="dark" className="d-block ms-auto">Report</MDBBtn>
                                    <Report show={this.state.reportOpen} setImageReported={this.setImageReported}/>
                                </>}
                            </>}
                        </div> : 
                        <>
                            {h.checkJanny(this.props.userInfo) || this.props.userInfo._id === this.props.imageInfo.userID ?
                            <>
                                <MDBBtn onClick={() => this.setShowModal(true)} color="danger">Restore Image</MDBBtn>
                            </> : <></>}
                        </>}
                    </div>
                    <hr></hr>
                    <h5 className="text-center mb-4">Posted by {this.props.imageInfo.badge ? this.getBadge() : 
                    <>
                        <span className="text-secondary">{this.props.imageInfo.user}</span> <span className="text-nowrap">
                        (ID: <span className="text-light" style={{backgroundColor: `var(${this.props.imageInfo.poster_css_color})`}}>{this.props.imageInfo.poster_id}</span>)
                        </span>
                    </>
                    } on {h.makeDateHR(new Date(this.props.imageInfo.timestamp))} at {h.getTimeHR(new Date(this.props.imageInfo.timestamp))}</h5>
                </MDBContainer>
                
                {String(this.props.imageInfo.manifesto).length ?
                <MDBContainer>
                    <MDBCard className={`mt-4 shadow-3-strong ${this.props.imageInfo.manifesto_removed ? 'card-removed' : ''}`}>
                        <MDBCardHeader>
                            <MDBCardTitle className={`text-center ${this.props.imageInfo.manifesto_removed ? 'text-light' : ''}`}>Manifesto</MDBCardTitle>
                        </MDBCardHeader>
                        <MDBCardBody>
                            <MDBCardText className={`messages ${this.props.imageInfo.manifesto_removed ? 'text-light' : ''}`}>{this.parseManifesto(this.props.imageInfo.manifesto)}</MDBCardText>
                        </MDBCardBody>
                        {h.checkJanny(this.props.userInfo) || this.props.userInfo._id === this.props.imageInfo.userID ? 
                        <MDBCardFooter>
                            {!this.props.imageInfo.manifesto_removed ?
                            <div className="d-flex justify-content-end">
                                <div className="w-max-content">
                                    <MDBBtn size="sm" onClick={this.toggleRemoveManifestoForm} color="dark" className="d-block ms-auto">Remove Manifesto</MDBBtn>
                                    <RemoveManifesto socket={this.props.socket} show={this.state.removeManifestoOpen} />
                                </div>
                            </div> : 
                            <div className="d-flex justify-content-between">
                                <h5 className="text-light messages">Removed: {this.props.imageInfo.manifesto_removed_reason === 'other' ? this.props.imageInfo.manifesto_removed_details : h.getRemovedReason(this.props.imageInfo.manifesto_removed_reason)}</h5>
                                {this.state.manifestoRestoring ?
                                <MDBBtn disabled size="sm" style={{backgroundColor: 'var(--mdb-green)'}}><Spinner size="sm" className="me-2"></Spinner>Restoring</MDBBtn> :
                                <MDBBtn size="sm" onClick={this.restoreManifesto} style={{backgroundColor: 'var(--mdb-green)'}}>Restore Manifesto</MDBBtn> 
                                }
                            </div>}
                        </MDBCardFooter> : <></>}
                    </MDBCard>
                </MDBContainer> : <></>}
            </motion.div>
            
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
}

export default connect(mapStateToProps, { update_image, set_comment_page, route })(Content);
import React from 'react';
import {
    MDBNavbarItem,
    MDBNavbarLink,
    MDBRipple
} from 'mdb-react-ui-kit';


/**
 * 
 * @param {Function} nav - The navigation function
 * @param {Object} userInfo - Users document
 * @param {Object} history - react-router history object
 * @returns Menu items only present on mobile screens
 */
const MobileOnlyNavItems = ({ mobileNav, userInfo, history }) => (
    <>
        <span className="nav-mobile">
            <MDBNavbarItem className="nav-item nav-items-collapse">
                <MDBNavbarLink className={`nav-link nav-main-items mb-0`}>
                    <img className="nav-avatar" src={`/api/image-id/${userInfo.avatar}`}></img> 
                    <span className="text-default">{userInfo.username}</span>
                </MDBNavbarLink>
            </MDBNavbarItem>
        </span>
        <span className="nav-mobile">
            <MDBRipple rippleColor="light">
                <MDBNavbarItem onClick={() => mobileNav('/dashboard')} className="nav-item nav-items-collapse rounded">
                    <MDBNavbarLink id="nav-dashboard" className={`nav-link text-default  nav-main-items ${'dashboard' === history.location.pathname.split('/')[1] ? 'active' : ''}`}>
                        <i style={{color: 'dashboard' !== history.location.pathname.split('/')[1] ? 'var(--mdb-indigo)' : '#fff'}} className="fas fa-user fa-lg mx-auto d-block text-center mb-2"></i>
                        Dashboard
                    </MDBNavbarLink>
                </MDBNavbarItem>
            </MDBRipple>
        </span>
    </>
);

export default MobileOnlyNavItems;
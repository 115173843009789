import React from 'react';
import { user_schema_verified } from '../../../../../utilities/validations';
import axios from 'axios';
import data from './data/newUser';
import { route, purge_user } from '../../../../../redux/actions';
import { connect } from 'react-redux';
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBContainer,
    MDBInput,
    MDBValidation,
    MDBValidationItem
} from 'mdb-react-ui-kit';
import Spinner from '../../../../../components/Spinner';

/**
 * This is the New User modal for the Verified category in the Users tab of the dashboard
 * New Verified users are added here
 */

class NewVerifiedModal extends React.Component{
    constructor(){
        super();
        this.state = {
            /**
             * working: Boolean indicating whether a new user is in the process of being submitted to the server
             */
            working: false,
            inputs: data.map(field => ({
                id: field.id,
                error: '',
                invalid: false,
                value: ''
            }))
        }
    }

    componentDidMount(){
        //document.getElementById('form_new_user').addEventListener('submit', this.submit);
        this.changeHandler({
            target: {
                name: ''
            }
        });
    }

    submit = () => {
        /**
         * Fired when the user clicks Submit
         * Make sure changes to the user are not already in the process of being saved
         * Remove Invalid Feedback from previous unsuccessful attempts, if any
         * Create object from user input
         * Validate the object
         * If object is invalid, add Invalid Feedback for all invalid entries
         * Create form data object, send to server
         * If successful, set new user object into application state, then redirect to dashboard
         * If unsuccessful and error 403, alert user is locked out
         * If unsuccessful and not error 403, general error alert
         */
        document.getElementById('form_new_user').classList.add('was-validated');
         let invalidInputs = this.state.inputs.filter(input => input.invalid);
         invalidInputs.forEach(input => document.getElementById(input.id).setCustomValidity('hello'));
         if (!this.state.working && !invalidInputs.length) this.setState({
             ...this.state,
             working: true
         }, () => {
             const data = Object.fromEntries(this.state.inputs.map(input => [input.id, input.value]));
             try {
                user_schema_verified.validateSync(data, {
                    abortEarly: false
                });
                const fd = new FormData();
                for ( const key in data ) {
                    fd.append(key, data[key]);
                }
                fd.append('role', 'Verified');
                if (this.state.avatarFile) fd.append('avatar', this.state.avatarFile, this.state.avatarName);

                axios.post('/users/new-user', fd).then(res => {
                    if (!res.data.error) this.props.toggleNewModal();
                    this.setState({
                        ...this.state,
                        working: false
                    }, () => {
                        if (res.data.error) alert(res.data.error);
                        else {
                            const notificationInfo = {
                                icon: <i className="fas fa-user-plus me-2 text-success"></i>,
                                text: `${res.data.username} Added`
                            }
                            this.props.addUser(res.data, notificationInfo);
                        }
                    });
                }).catch(err => this.setState({
                    ...this.state,
                    working: false
                }, () => {
                    if (err.response.status === 401) {
                        this.props.toggleNewModal();
                        this.props.purge_user();
                        this.props.route('/login');
                        alert('Your session has expired. Please log in again.');
                    } else {
                        console.log(err);
                        alert('An error occurred. Please try again later');
                    }
                }));
             } catch(err){
                 this.setState({
                     ...this.state,
                     working: false
                 }, () => {
                     console.log(err);
                     alert('An error occurred. Please try again later');
                 });
             }
        });
    }

    changeHandler = e => this.setState({
        ...this.state,
        inputs: this.state.inputs.map(input => {
            if (input.id === e.target.name) return {
                ...input,
                value: e.target.value
            }
            else return input
        })
    }, () => {
        const data = Object.fromEntries(this.state.inputs.map(input => [input.id, input.value]));
        try {
            user_schema_verified.validateSync(data, {
                abortEarly: false
            });
            this.setState({
                ...this.state,
                inputs: this.state.inputs.map(input => {
                    document.getElementById(input.id + '-new').setCustomValidity('');
                    return {
                        ...input,
                        invalid: false,
                        error: ''
                    }
                })
            });
        } catch(err){
            let errorsAdded = [];
            this.setState({
                ...this.state,
                inputs: this.state.inputs.map(input => {
                    if (err.inner.find(error => error.path === input.id) && errorsAdded.indexOf(input.id) === -1){
                        errorsAdded.push(input.id);
                        return {
                            ...input,
                            invalid: true,
                            error: err.inner.find(error => error.path === input.id).message
                        }
                    } 
                    else return {
                        ...input,
                        invalid: false,
                        error: ''
                    };
                })
            }, () => {
                this.state.inputs.forEach(input => {
                    if (input.invalid) document.getElementById(input.id + '-new').setCustomValidity('hello');
                    else document.getElementById(input.id + '-new').setCustomValidity('');
                });
            });
        }
    });

    pressEnter = e => {
        // Submit the form if the user presses the enter key 
        if (e.key === 'Enter') this.submit();
    }

    render(){
        return (
            <>
              {typeof window !== 'undefined' && window.navigator ?
              <MDBModal staticBackdrop={this.state.working} show={this.props.newModalShown} setShow={this.props.setNewModal} tabIndex='-1'>
                <MDBModalDialog size="xl">
                  <MDBModalContent>
                    <MDBModalHeader>
                      <MDBModalTitle>New Verified User</MDBModalTitle>
                      <MDBBtn className='btn-close' color='none' onClick={this.props.toggleNewModal}></MDBBtn>
                    </MDBModalHeader>
                    <MDBModalBody>
                        <MDBContainer fluid className="px-0">
                            <MDBValidation className="row mx-0" id="form_new_user" method="dialog" name="form_new_user">
                                {data.map(i => (
                                    <MDBValidationItem key={i.id + '-new'} className="col-12 col-md-4 mt-4" feedback={this.state.inputs.find(input => input.id === i.id).error} invalid={true} >
                                        <MDBInput
                                            name={i.id}
                                            onChange={this.changeHandler}
                                            id={i.id + '-new'}
                                            label={i.text}
                                            size="lg"
                                            className={!this.state.inputs.find(input => input.id === i.id).invalid ? 'mb-0' : 0}
                                            onKeyPress={this.pressEnter}
                                        />
                                    </MDBValidationItem>
                                ))}
                            </MDBValidation>
                        </MDBContainer>
                    </MDBModalBody>
                    <MDBModalFooter>
                        {this.state.working ?
                        <MDBBtn size="lg" disabled color="primary"><Spinner size='sm' className='me-2' />Submitting</MDBBtn> :
                        <MDBBtn size="lg" onClick={this.submit} color="primary"><i className="fas fa-paper-plane me-2"></i>Submit</MDBBtn>}
                        <MDBBtn size="lg" onClick={this.props.toggleNewModal} style={{backgroundColor: 'var(--mdb-gray)'}} className="ms-2" data-bs-dismiss="modal">Close</MDBBtn>
                    </MDBModalFooter>
                  </MDBModalContent>
                </MDBModalDialog>
              </MDBModal> : <></>}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
  }
  
  export default connect(mapStateToProps, { route, purge_user })(NewVerifiedModal);
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { motion } from 'framer-motion';
import t from '../utilities/transitions';
import Menu from './dashboard/Menu';
import MenuMobile from './dashboard/MenuMobile';
import './dashboard/dashboard.css';
import options from './dashboard/options';
import { route, select_dashboard_item, set_show_image_toast, set_nsfw_modal } from '../redux/actions';
import { 
    MDBSpinner,
    MDBTabsContent,
    MDBTabsPane,
    MDBContainer
} from 'mdb-react-ui-kit';

/**
 * This is the user dashboard
 */

class Dashboard extends React.Component{
    constructor(props){
        super();
        this.state = {
            /**
             * loaded: Boolean indicating whether user information has been loaded
             * exit: framer-motion exit transition
             */
            loaded: false,
            exit: this.getExit(props)
        }
    }

    componentDidMount(){
        /**
         * Set exit transition to default value, then check whether user information has been loaded. If so, load the component
         */
        if (this.props.dashboardItemSelected === 'settings-from-user') this.props.select_dashboard_item('settings');
        this.setState({
            ...this.state,
            exit: t.fade_out
        }, () => {
            if (!this.state.loaded && this.props.userInfo.role) this.load();
        });
    }

    componentDidUpdate(){
        /**
         * Check whether user information has been loaded. If so, and component is not loaded, load the component
         */
        if (!this.state.loaded && this.props.userInfo.role) this.load();
    }

    componentWillUnmount(){
        /**
         * If user is exiting and not going to a contract or listing, reset to default dashboard preferences
         */
        if (this.state.exit !== t.fade_out_left){
            this.props.select_dashboard_item('settings'); // Reset selection to first menu option when the user leaves the dashboard
        } 
    }

    imageNav = route => {
        if (route.split('/')[1] === 'image') this.props.set_show_image_toast(true);
        this.setState({
            /**
             * Fired if the user is navigating from the dashboard to a contract or listing
             * Set the dashboard to fade out to the left, then navigate to the intended route
             */
            ...this.state,
            exit: t.fade_out_left
        }, () => this.props.route(route));
    }

    getExit = props => {
        /**
         * If the user is coming from a listing or contract, fade in from the left
         * Otherwise, fade in
         */
        const history = props.historyStack;
        if (history[history.length - 1].split('/')[1] === 'image' && ['images', 'reports', 'modlogs'].indexOf(props.dashboardItemSelected) !== -1) return t.fade_out_left;
        else if (['u', 'user'].indexOf(history[history.length - 1].split('/')[1]) !== -1 && ['settings-from-user', 'reports', 'modlogs'].indexOf(props.dashboardItemSelected) !== -1) return t.fade_out_left;
        else return t.fade_out;
    }

    load = () => this.setState({
        ...this.state,
        loaded: true
    });

    render(){
        return (
            <>
                <motion.div className="h-100" transition={t.transition} exit={this.state.exit} animate={t.normalize} initial={this.state.exit}>
                    <MDBContainer fluid className="h-100 px-0">
                        <div className="row mx-0 h-100">
                            {this.state.loaded ?
                            <div className="h-lg-100 col-12 col-lg-3 py-3 px-2">
                                <div className="dashboard-menu-desktop h-100">
                                    <Menu />
                                </div>
                                <div className="dashboard-menu-mobile">
                                    <MenuMobile />
                                </div>
                            </div> : <></>}
                            <div className="col-12 col-lg-9 h-100 px-0">
                                
                                <div className="tab-content h-100" id="pills-tabContent">
                                    {this.props.userInfo.role && this.state.loaded ?
                                    <MDBTabsContent className="h-100">
                                        {options.find(o => o.category === this.props.userInfo.role).options.map(option => (
                                            <MDBTabsPane className="h-100" show={option.id === this.props.dashboardItemSelected}>
                                                <option.component imageNav={this.imageNav}/>
                                            </MDBTabsPane>
                                        ))}
                                    </MDBTabsContent> :
                                    <div className="d-flex justify-content-center pt-5">
                                        <MDBSpinner color="primary" style={{ width: '3.5rem', height: '3.5rem' }} grow />
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </MDBContainer>
                </motion.div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
  }
  
  export default connect(mapStateToProps, { route, select_dashboard_item, set_show_image_toast, set_nsfw_modal })(withRouter(Dashboard));
import React from 'react';
import { 
    MDBBtn,
    MDBCollapse,
    MDBInput,
    MDBValidation,
    MDBValidationItem,
    MDBTextArea,
    MDBSpinner,
    MDBCheckbox
} from 'mdb-react-ui-kit';
import { comment_schema } from '../../../utilities/validations';
import axios from 'axios';
import h from '../../../utilities/helpers';
import { connect } from 'react-redux';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { add_comment } from '../../../redux/actions';
import AddCommentToast from './AddCommentToast';
import EmojiPicker from '../../../components/EmojiPicker';
import Spinner from '../../../components/Spinner';

const fields = [
    {
        text: 'Name (Optional)',
        id: 'name',
        type: 'text'
    },
    {
        text: 'Message',
        id: 'body',
        type: 'textarea'
    },
    {
        text: 'Avatar (Optional)',
        id: 'avatar',
        type: 'number'
    }
];

const allowedExtensions = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'image/bmp', 'image/webp', 'image/svg+xml'];

class AddCommentForm extends React.Component{
    constructor(){
        super();
        this.state = {
            /**
             * inputs: Array - The input data (values, errors, etc)
             * working: Boolean - Whether the comment is in the process of being submitted
             * avatarFetchTimeout - false | Timeout to fetch an avatar image if exists
             * fetchingAvatar: Boolean - Whether an avatar is in the process of being fetched from the server
             * avatarSrc: String, Path to avatar that was fetched after entering an image number into the avatar input
             * avatar: String, Path to the user's avatar
             * avatarName: String, "Click to Change", or the name of the file the user has selected, if any
             * avatarFile: false | File object that contains an avatar file that the user selected
             * commentFormOpen: Boolean - Whether the comment form is open
             * toastShown: Boolean - Whether the Add Comment MDB Toast is displayed
             * reset: Boolean - Used to force fix certain ui bugs
             * imageOptions: Object - The image options that the user can toggle when uploading an image with the comment
             */
            inputs: fields.map(field => ({
                id: field.id,
                error: '',
                invalid: false,
                value: ''
            })),
            working: false,
            avatarFetchTimeout: false,
            fetchingAvatar: false,
            avatarSrc: '',
            avatar: '/thumbnails/blank.webp',
            avatarName: 'Click to Change',
            avatarFile: false,
            commentFormOpen: false,
            toastShown: false,
            reset: false,
            imageOptions: {
                commentsDisabled: false,
                nsfw: false,
                hidden: false
            }
        }
        this.clickCommentID = this.clickCommentID.bind(this);
    }

    /**
     * Bind the clickCommentID function
     * Run empty changeHandler
     */
    componentDidMount(){
        this.props.setClickCommentID(this.clickCommentID);
        this.changeHandler({
            target: {
                value: '',
                name: ''
            }
        });
    }

    /**
     * 
     * @param {Number} id Comment number that was just clicked
     * 
     * This will add the comment into the comment body, to be quoted, and show the Add Comment toast
     */
    clickCommentID = id => {
        this.setState({
            ...this.state,
            inputs: this.state.inputs.map(input => {
                if (input.id === 'body') return {
                    ...input,
                    value: input.value + `##${id}\n`
                }
                else return input;
            }),
            toastShown: true
        });
    }

    /**
     * Fired when the user clicks their avatar
     * 
     * Creates a virtual file input
     * Adds a change event that sets the selected file into state
     * Appends to document body (necessary for iDevices and possibly others)
     * Clicks the input
     * Removes the input after the file is selected
     */
    selectAvatar = () => {
        let input = document.createElement('input');
        input.type = 'file';
        input.style.visibility = "hidden";
        input.style.position = "fixed";
        document.body.appendChild(input);
        input.onchange = e => {
            clearTimeout(this.state.avatarFetchTimeout);
            let file = e.target.files[0];
            if (allowedExtensions.indexOf(file.type) !== -1){
                if (file.size < 15000001){
                    this.setState({
                        ...this.state,
                        avatarName: e.target.files[0].name,
                        avatarFile: e.target.files[0],
                        avatar: URL.createObjectURL(e.target.files[0]),
                        inputs: this.state.inputs.map(input => {
                            if (input.id === 'avatar') return {
                                ...input,
                                value: ''
                            } 
                            else return input
                        })
                    }, () => document.body.removeChild(input));
                } else {
                    document.body.removeChild(input);
                    alert('Your file is too big (Max: 15MB)');
                }
            } else {
                document.body.removeChild(input)
                alert('Please select a valid image file (png, jpg, gif, bmp, webp)');
            }
        }
        input.click();
    }

    fetchAvatar = () => this.setState({
        ...this.state,
        fetchingAvatar: false,
        avatarSrc: `/api/image-id/${this.state.inputs.find(i => i.id === 'avatar').value}`
    });

    /**
     * 
     * @param {KeyboardEvent} e - Keyboard event triggered by text change in any of the text inputs
     * 
     * Sets the updated values into state
     * Validates the inputs
     * Updates the inputs with errors
     * Adds/removes custom validity as appropriate
     */
    changeHandler = e => {
        if (e.target.name === 'avatar') clearTimeout(this.state.avatarFetchTimeout);
        this.setState({
            ...this.state,
            inputs: this.state.inputs.map(input => {
                if (input.id === e.target.name) return {
                    ...input,
                    value: e.target.value
                }
                else return input
            }),
            fetchingAvatar: e.target.name === 'avatar',
            avatar: e.target.name === 'avatar' ? '/thumbnails/blank.webp' : this.state.avatar,
            avatarName: e.target.name === 'avatar' ? 'Click to Change' : this.state.avatarName,
            avatarFile: e.target.name === 'avatar' ? '' : this.state.avatarFile
        }, () => {
            const data = Object.fromEntries(this.state.inputs.map(input => [input.id, input.value]));
            this.state.inputs.forEach(input => {
                if (input.value){
                    document.getElementById(input.id + '-main').dispatchEvent(new Event('input'));
                    const toastInput = document.getElementById(input.id + '-toast');
                    if (toastInput) toastInput.dispatchEvent(new Event('input'));
                }
            });
            try {
                comment_schema.validateSync(data, {
                    abortEarly: false
                });
                this.setState({
                    ...this.state,
                    inputs: this.state.inputs.map(input => {
                        document.getElementById(input.id + '-main').setCustomValidity('');
                        const toastInput = document.getElementById(input.id + '-toast');
                        if (toastInput) toastInput.setCustomValidity('');
                        return {
                            ...input,
                            invalid: false,
                            error: ''
                        }
                    }),
                    avatarFetchTimeout: e.target.name === 'avatar' ? setTimeout(this.fetchAvatar, 250) : this.state.avatarFetchTimeout
                });
            } catch(err){
                let errorsAdded = [];
                this.setState({
                    ...this.state,
                    inputs: this.state.inputs.map(input => {
                        if (err.inner.find(error => error.path === input.id) && errorsAdded.indexOf(input.id) === -1){
                            errorsAdded.push(input.id);
                            return {
                                ...input,
                                invalid: true,
                                error: err.inner.find(error => error.path === input.id).message
                            }
                        } 
                        else return {
                            ...input,
                            invalid: false,
                            error: ''
                        };
                    }),
                    avatarFetchTimeout: e.target.name === 'avatar' ? setTimeout(this.fetchAvatar, 250) : this.state.avatarFetchTimeout
                }, () => {
                    this.state.inputs.forEach(input => {
                        if (input.invalid){
                            document.getElementById(input.id + '-main').setCustomValidity('hello');
                            const toastInput = document.getElementById(input.id + '-toast');
                            if (toastInput) toastInput.setCustomValidity('hello');
                        } 
                        else {
                            document.getElementById(input.id + '-main').setCustomValidity('');
                            const toastInput = document.getElementById(input.id + '-toast');
                            if (toastInput) toastInput.setCustomValidity('');
                        }
                    });
                });
            }
        });
    } 

    /**
     * Submit only if there isn't already a submission being sent
     * Validate inputs
     * Make request to server
     * Emit new-comment event via socket
     * Reset inputs
     * Hide submission forms
     */
    submit = () => {
        
        document.getElementById('comment_form_main').classList.add('was-validated');
        const toastForm = document.getElementById('comment_form_toast')
        if (toastForm) toastForm.classList.add('was-validated');
        let invalidInputs = this.state.inputs.filter(input => input.invalid);
        invalidInputs.forEach(input => {
            document.getElementById(input.id + '-main').setCustomValidity('hello');
            const toastInput = document.getElementById(input.id + '-toast');
            if (toastInput) toastInput.setCustomValidity('hello');
        });
        if (!this.state.working && !invalidInputs.length) this.setState({
            ...this.state,
            working: true
        }, async () => {
            const data = Object.fromEntries(this.state.inputs.map(input => [input.id, input.value]));
            try {
                comment_schema.validateSync(data, {
                    abortEarly: false
                });
                const captchaKey = await h.getRecaptcha(this.props.googleReCaptchaProps);
                const fd = new FormData();
                for ( const key in data ) {
                    fd.append(key, data[key]);
                }
                fd.append('captchaKey', captchaKey);
                fd.append('image_id', this.props.imageInfo.image_id);

                if (this.state.avatarFile){
                    fd.append('commentsDisabled', this.state.imageOptions.commentsDisabled);
                    fd.append('nsfw', this.state.imageOptions.nsfw);
                    fd.append('hidden', this.state.imageOptions.hidden);
                    fd.append('image', this.state.avatarFile, this.state.avatarName);
                }

                axios.post('/comments', fd).then(res => {
                    if (res.data.error) this.setState({
                        ...this.state,
                        working: false
                    }, () => alert(res.data.error))
                    else this.setState({
                        ...this.state,
                        working: false,
                        inputs: fields.map(field => ({
                            id: field.id,
                            error: '',
                            invalid: false,
                            value: ''
                        })),
                        avatarFetchTimeout: '',
                        fetchingAvatar: false,
                        avatarSrc: '',
                        avatar: '/thumbnails/blank.webp',
                        avatarName: 'Click to Change',
                        avatarFile: '',
                        toastShown: false,
                        imageOptions: {
                            commentsDisabled: false,
                            nsfw: false,
                            hidden: false
                        }
                    }, () => {
                        this.props.socket.emit('comment-send', res.data.comment);
                        document.getElementById('comment_form_main').classList.remove('was-validated');
                        const toastForm = document.getElementById('comment-form-toast');
                        if (toastForm) toastForm.classList.remove('was-validated');
                        this.props.add_comment(res.data.comment);
                        this.changeHandler({
                            target: {
                                value: '',
                                name: ''
                            }
                        });
                        [].slice.call(document.getElementsByClassName('comment-inputs')).forEach(e => e.value = '');
                        setTimeout(() => {
                            this.closeCommentForm();
                        }, 250);
                        setTimeout(() => {
                            document.getElementById(`comment-${res.data.comment.comment_id}`).scrollIntoView();
                        }, 500);
                    });
                }).catch(err => this.setState({
                    ...this.state,
                    working: false
                }, () => {
                    console.log(err);
                    alert('An error occurred. Please try again later');
                }));
            } catch(err){
                this.setState({
                    ...this.state,
                    working: false
                }, () => {
                    console.log(err);
                    alert('An error occurred. Please try again later');
                });
            }
        });
    }

    toggleCommentForm = () => this.setState({
        ...this.state,
        commentFormOpen: !this.state.commentFormOpen
    }, () => setTimeout(this.fixMDBInputs, 250));

    closeCommentForm = () => this.setState({
        ...this.state,
        commentFormOpen: false
    });

    hideToast = () => this.setState({
        ...this.state,
        toastShown: false
    });
    
    /**
     * Hacky fix for known mdb ui bug that will probably be fixed in later versions
     */
    fixMDBInputs = () => {
        [].slice.call(document.getElementsByClassName('comment-inputs')).forEach(e => {
            if (!e.value && e.classList.contains('active')) e.classList.remove('active');
            else if (e.value && !e.classList.contains('active')) e.classList.add('active');
        });
        this.setState({
            ...this.state,
            reset: !this.state.reset
        });
    }

    /**
     * Add emoji to the comment body if selected
     */
    selectEmoji = e => {
        if (!this.state.working) this.changeHandler({
            target: {
                value: this.state.inputs.find(i => i.id === 'body').value + e.char,
                name: 'body'
            }
        });
    }

    /**
     * 
     * @param {CheckboxEvent} e 
     * 
     * Fired when the user toggles one of the options when uploading a file with their comment
     */
    changeImageOptions = e => this.setState({
        ...this.state,
        imageOptions: {
            ...this.state.imageOptions,
            [e.target.name]: e.target.checked
        }
    });

    render(){
        return (
            <>
                <AddCommentToast 
                    changeHandler={this.changeHandler} 
                    inputs={this.state.inputs} 
                    fields={fields}
                    submit={this.submit}
                    working={this.state.working}
                    avatar={this.state.avatar}
                    avatarFile={this.state.avatarFile}
                    avatarSrc={this.state.avatarSrc}
                    selectAvatar={this.selectAvatar}
                    toastShown={this.state.toastShown}
                    hideToast={this.hideToast}
                    fixMDBInputs={this.fixMDBInputs}
                    selectEmoji={this.selectEmoji}
                    imageOptions={this.state.imageOptions}
                    changeImageOptions={this.changeImageOptions}
                    fetchingAvatar={this.state.fetchingAvatar}
                />
                <MDBBtn 
                    onClick={this.toggleCommentForm} 
                    color="primary"
                    size="lg" 
                    className="mt-4 d-block mx-auto">
                    <i className="fas fa-comments me-2"></i>
                    Add Comment
                </MDBBtn>
                <MDBCollapse 
                    show={this.state.commentFormOpen}>
                    <div className="form-containers mt-4">
                        <MDBValidation id="comment_form_main" method="dialog" name="comment_form_main">
                        {fields.map(i => (
                            <MDBValidationItem key={i.id + '-main'} className="pb-4" feedback={this.state.inputs.find(input => input.id === i.id).error} invalid={true} >
                                {(() => {
                                    switch(i.type){
                                        case 'text':
                                            return (
                                                <MDBInput
                                                    name={i.id}
                                                    onChange={this.changeHandler}
                                                    id={i.id + '-main'}
                                                    label={i.text}
                                                    size="lg"
                                                    type={i.type}
                                                    className={`comment-inputs ${!this.state.inputs.find(input => input.id === i.id).invalid ? 'mb-0' : 0}`}
                                                    value={this.state.inputs.find(input => input.id === i.id).value}
                                                />
                                            );
                                        case 'number':
                                            return (
                                                <div className="w-100 d-flex">
                                                    <div className="w-50">
                                                        <MDBInput
                                                            name={i.id}
                                                            onChange={this.changeHandler}
                                                            id={i.id + '-main'}
                                                            label={i.text}
                                                            size="lg"
                                                            type={i.type}
                                                            min="1"
                                                            className={`comment-inputs ${!this.state.inputs.find(input => input.id === i.id).invalid ? 'mb-0' : 0}`}
                                                            value={this.state.inputs.find(input => input.id === i.id).value}
                                                        />
                                                        {this.state.avatarFile ?
                                                        <div className="mt-2">
                                                            <MDBCheckbox 
                                                                onChange={this.changeImageOptions} 
                                                                checked={this.state.imageOptions.commentsDisabled} 
                                                                className="check-commentsDisabled" 
                                                                id="check-commentsDisabled-main" 
                                                                name="commentsDisabled" 
                                                                label="Disable Comments" 
                                                                labelClass="mb-0"
                                                            />
                                                            <MDBCheckbox 
                                                                className="check-nsfw" 
                                                                id="check-nsfw-main" 
                                                                onChange={this.changeImageOptions} 
                                                                checked={this.state.imageOptions.nsfw} 
                                                                name="nsfw" 
                                                                label="Mark NSFW" 
                                                                labelClass="mb-0"
                                                            />
                                                            <MDBCheckbox 
                                                                className="check-hidden" 
                                                                id="check-hidden-main" 
                                                                onChange={this.changeImageOptions} 
                                                                checked={this.state.imageOptions.hidden} 
                                                                name="hidden" 
                                                                label="Hide from Browse" 
                                                                labelClass="mb-0"
                                                            /> 
                                                        </div> :
                                                        <></>}
                                                    </div>
                                                    <div className="w-50">
                                                        {this.state.fetchingAvatar ? 
                                                        <div className="d-flex square-8 justify-content-center align-items-center mx-auto">
                                                            <MDBSpinner grow size="lg" color="primary"></MDBSpinner>
                                                        </div> : 
                                                        <div style={{cursor: 'pointer'}} onClick={this.selectAvatar} className="border border-dark p-2 d-flex justify-content-center align-items-center square-8 mx-auto">
                                                            <div className="fit-images" style={{backgroundImage: `url("${(this.state.avatarFile) ? this.state.avatar : ((this.state.avatarSrc) ? `${this.state.avatarSrc}` : '/avatars/blank.webp')}")`}}></div>
                                                        </div>}
                                                    </div>
                                                </div>
                                            );
                                        case 'textarea':
                                            return (
                                                <>
                                                    <MDBTextArea
                                                        name={i.id}
                                                        onChange={this.changeHandler}
                                                        id={i.id + '-main'}
                                                        label={i.text}
                                                        size="lg"
                                                        className={`comment-inputs ${!this.state.inputs.find(input => input.id === i.id).invalid ? 'mb-0' : 0}`}
                                                        style={{minHeight: '10rem'}}
                                                        value={this.state.inputs.find(input => input.id === i.id).value}
                                                    />
                                                    <div className="d-flex justify-content-between p-2">
                                                        <EmojiPicker emojiID={0} onEmojiSelect={this.selectEmoji} />
                                                        <p className="text-end m-0"><span className={this.state.inputs.find(i => i.id === 'body').value.length > 10000 ? 'text-danger' : ''}>{this.state.inputs.find(i => i.id === 'body').value.length}</span>/10000</p>
                                                    </div>
                                                </>
                                            );
                                        default:
                                            console.log('oob comment form', i.type);
                                            return <></>
                                    }
                                })()}
                            </MDBValidationItem>
                        ))}
                        </MDBValidation>
                        {this.state.working ?
                        <MDBBtn size="lg" block disabled color="success"><Spinner size='sm' className="me-2"></Spinner>Sending</MDBBtn> :
                        <MDBBtn onClick={this.submit} size="lg" block color="success"><i className="fas fa-paper-plane me-2"></i>Submit</MDBBtn>}
                    </div>
                </MDBCollapse>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
}

export default connect(mapStateToProps, { add_comment })(withGoogleReCaptcha(AddCommentForm));
import React from 'react';
import { connect } from 'react-redux';
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter
  } from 'mdb-react-ui-kit';
import { update_image } from '../../../redux/actions';
import axios from 'axios';
import Spinner from '../../../components/Spinner';
import h from '../../../utilities/helpers';


class RestoreModal extends React.Component{
    constructor(){
        super();
        this.state = {
            /**
             * working: Boolean indicating whether the comments are in the process of being restored
             * 
             */
            working: false
        }
    }

    /**
     * Fired when the user clicks Restore
     * 
     * Opens the comment section
     * Emits that action via socket to other users viewing the page
     * Applies update to application state 
     * Hides the modal
     */
    confirm = () => {
        if (!this.state.working) this.setState({
            ...this.state,
            working: true
        }, () => axios.post((h.checkJanny(this.props.userInfo)) ? '/support/open-comments/image' : '/support/open-comments/image-own', {
            commentSection: this.props.imageInfo.commentSection,
            imageID: this.props.imageInfo.image_id
        }).then(res => this.setState({
            ...this.state,
            working: false
        }, () => {
            this.props.socket.emit('mod-action');
            this.props.update_image(res.data.image);
            this.props.toggleShowModal();
        })).catch(err => this.setState({
            ...this.state,
            working: false
        }, () => {
            console.log(err);
            alert('An error occurred. Please try again later');
        })));
    }

    render(){
        return (
            <>
              {typeof window !== 'undefined' && window.navigator ?
              <MDBModal staticBackdrop={this.state.working} show={this.props.modalShown} setShow={this.props.setShowModal} tabIndex='-1'>
                <MDBModalDialog size="xl">
                  <MDBModalContent>
                    <MDBModalHeader>
                      <MDBModalTitle>Open Comment Section</MDBModalTitle>
                      <MDBBtn className='btn-close' color='none' onClick={this.props.toggleShowModal}></MDBBtn>
                    </MDBModalHeader>
                    <MDBModalBody>
                        <h2 className="my-5 text-center display-6">Are you sure you want to open the comment section for image {this.props.imageInfo.image_id}?</h2>
                    </MDBModalBody>
                    <MDBModalFooter>
                        {this.state.working ?
                        <MDBBtn size="lg" disabled color="danger"><Spinner size='sm' className='me-2' />Restoring</MDBBtn> :
                        <MDBBtn size="lg" onClick={this.confirm} color="danger">Restore</MDBBtn>}
                        <MDBBtn size="lg" style={{backgroundColor: 'var(--mdb-gray)'}} onClick={this.props.toggleShowModal}>
                            Close
                        </MDBBtn>
                    </MDBModalFooter>
                  </MDBModalContent>
                </MDBModalDialog>
              </MDBModal> : <></>}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
  }
  
  export default connect(mapStateToProps, { update_image })(RestoreModal);
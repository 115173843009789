import React from 'react';
import h from '../../../../../utilities/helpers';
import {
    MDBListGroupItem
} from 'mdb-react-ui-kit';
/**
 * This is the list of Janny users on the Users dashboard tab
 */

const JannyList = ({ users, selectUser, sortUsers, page, itemsPerPage }) => {
    if (users.length) return sortUsers(users.filter((user, i) => {
        if ((i >= (page * itemsPerPage) - 1 - itemsPerPage) && (i < (page * itemsPerPage))) return true;
        else return false
    })).map(user => (
        <MDBListGroupItem key={JSON.stringify(user)} onClick={() => selectUser(user)} action className="container-fluid mx-0 px-0 cursor-pointer">
            <div className="row mx-0">
                <div className="col-3 text-sm-sm text-break">{user.username}</div>
                <div className="col-3 text-center text-sm-sm text-break">{user.email}</div>
                <div className="col-3 text-center text-sm-sm text-break">{user.comments}</div>
                <div className="col-3 text-center text-sm-sm text-break">{h.makeDateHR(new Date(user.creationDate))}</div>
            </div>
        </MDBListGroupItem>
    ));
    else return <h4 className="text-center mt-3">There are no non-disabled janny users</h4>
} ;

export default JannyList;
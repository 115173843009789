import React from 'react';
import { connect } from 'react-redux';
import {
    MDBToast
} from 'mdb-react-ui-kit';
import { set_show_image_toast, dashboard_nav } from '../../redux/actions';

class ImageToast extends React.Component{
    constructor(){
        super();
        this.state = {
            dashboardItem: 'images'
        }
    }

    componentDidUpdate(){
        if (this.props.dashboardItemSelected !== this.state.dashboardItem && ['images', 'reports', 'modlogs'].indexOf(this.props.dashboardItemSelected) !== -1) this.setState({
            ...this.state,
            dashboardItem: this.props.dashboardItemSelected
        });
    }

    hideToast = e => {
        e.stopPropagation();
        this.props.set_show_image_toast(false);
    }

    render(){
        return (
            <>
                {typeof window !== 'undefined' ?
                <MDBToast
                    show={this.props.showImageToast}
                    position='top-left'
                    className="hover-generic cursor-pointer"
                    containerRef={this.props.containerRef}
                    onClick={() => this.props.dashboard_nav(this.state.dashboardItem)}
                    bodyContent={
                        <div className="toast-body d-flex justify-content-between p-2">
                            {(() => {
                                switch(this.state.dashboardItem){
                                    case 'images':
                                        return <h5 className="text-nowrap me-2"><i className="fas fa-chevron-left me-2"></i>Your Images<i style={{color: 'var(--mdb-success)'}} className="fas fa-images ms-2"></i></h5>
                                    case 'reports':
                                        return <h5 className="text-nowrap me-2"><i className="fas fa-chevron-left me-2"></i>Reports<i style={{color: 'var(--mdb-warning)'}} className="fas fa-flag ms-2"></i></h5>
                                    case 'modlogs':
                                        return <h5 className="text-nowrap me-2"><i className="fas fa-chevron-left me-2"></i>Mod Logs<i style={{color: 'var(--mdb-danger)'}} className="fas fa-gavel ms-2"></i></h5>
                                    default: <h5 className="text-nowrap me-2"><i className="fas fa-chevron-left me-2"></i>Your Images<i style={{color: 'var(--mdb-success)'}} className="fas fa-images ms-2"></i></h5>
                                }
                            })()}
                            <button onClick={this.hideToast} type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                        </div>
                    }
                /> : <></>}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
}

export default connect(mapStateToProps, { set_show_image_toast, dashboard_nav })(ImageToast);
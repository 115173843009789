import React from 'react';
import { connect } from 'react-redux';
import {
    MDBCollapse,
    MDBValidation,
    MDBValidationItem,
    MDBTextArea,
    MDBBtn
} from 'mdb-react-ui-kit';
import { report_schema } from '../../../utilities/validations';
import axios from 'axios';
import { update_image } from '../../../redux/actions';
import Spinner from '../../../components/Spinner';
import h from '../../../utilities/helpers';

const fields = [
    {
        text: 'Explain',
        id: 'details',
        type: 'text'
    }
];

class Remove extends React.Component{
    constructor(){
        super()
        this.state = {
            /**
             * inputs: Array - The input data (values, errors, etc)
             * otherSelected: Boolean - Whether the "Other" option is selected as the removal reason (will open up a text input)
             * working: Boolean - Whether the manifesto is in the process of being removed
             * removeReason: String - The reason for the manifesto removal
             * actionTimeout: false | Interval that adds collapse classes to MDBCollapse (fixes ui bug caused by collapse within collapse)
             */
            inputs: fields.map(field => ({
                id: field.id,
                error: '',
                invalid: true,
                value: ''
            })),
            otherSelected: false,
            working: false,
            submitted: false,
            removeReason: ''
        }
    }

    /**
     * Run a blank input change
     */
    componentDidMount(){
        this.changeHandler({
            target: {
                name: ''
            }
        });
    }

    /**
     * 
     * @param {KeyboardEvent} e - Keyboard event triggered by text change in the Other text input
     * 
     * Sets the updated values into state
     * Validates the inputs
     * Updates the inputs with errors
     * Adds/removes custom validity as appropriate
     */
    changeHandler = e => this.setState({
        ...this.state,
        inputs: this.state.inputs.map(input => {
            if (input.id === e.target.name) return {
                ...input,
                value: e.target.value
            }
            else return input
        })
    }, () => {
        const data = Object.fromEntries(this.state.inputs.map(input => [input.id, input.value]));
        try {
            report_schema.validateSync(data, {
                abortEarly: false
            });
            this.setState({
                ...this.state,
                inputs: this.state.inputs.map(input => {
                    document.getElementById(input.id + '-remove-manifesto-form').setCustomValidity('');
                    return {
                        ...input,
                        invalid: false,
                        error: ''
                    }
                })
            });
        } catch(err){
            let errorsAdded = [];
            this.setState({
                ...this.state,
                inputs: this.state.inputs.map(input => {
                    if (err.inner.find(error => error.path === input.id) && errorsAdded.indexOf(input.id) === -1){
                        errorsAdded.push(input.id);
                        return {
                            ...input,
                            invalid: true,
                            error: err.inner.find(error => error.path === input.id).message
                        }
                    } 
                    else return {
                        ...input,
                        invalid: false,
                        error: ''
                    };
                })
            }, () => this.state.inputs.forEach(input => {
                if (input.invalid) document.getElementById(input.id + '-remove-manifesto-form').setCustomValidity('hello');
                else document.getElementById(input.id + '-remove-manifesto-form').setCustomValidity('');
            }));
        }
    });

    /**
     * Submit only if there isn't already a submission being sent
     * Validate inputs
     * Make request to server
     * Reset inputs
     * Emit mod-action event via socket
     * Update manifesto in application state
     */
    submit = () => {
        document.getElementById('remove-manifesto-form').classList.add('was-validated');
        let invalidInputs = this.state.inputs.filter(input => input.invalid);
        invalidInputs.forEach(input => document.getElementById(input.id + '-remove-manifesto-form').setCustomValidity('hello'));
        if (!this.state.working && !invalidInputs.length) this.setState({
            ...this.state,
            working: true,
            removeReason: 'other'
        }, async () => {
            const data = Object.fromEntries(this.state.inputs.map(input => [input.id, input.value]));
            try {
                report_schema.validateSync(data, {
                    abortEarly: false
                });
                const fd = new FormData();
                for ( const key in data ) {
                    fd.append(key, data[key]);
                }
                fd.append('id', this.props.imageInfo.commentSection);
                fd.append('imageID', this.props.imageInfo.image_id);
                fd.append('reason', 'other');
                axios.post((h.checkJanny(this.props.userInfo)) ? '/support/remove/manifesto' : '/support/remove/manifesto-own', fd).then(res => this.setState({
                    ...this.state,
                    working: false,
                    removeReason: ''
                }, () => {
                    this.props.update_image(res.data.image);
                    this.props.socket.emit('mod-action');
                })).catch(err => this.setState({
                    ...this.state,
                    working: false,
                    removeReason: ''
                }, () => {
                    console.log(err);
                    alert('An error occurred. Please try again later');
                }));
            } catch(err){
                this.setState({
                    ...this.state,
                    working: false,
                    removeReason: ''
                }, () => {
                    console.log(err);
                    alert('An error occurred. Please try again later');
                });
            }
        });
    }

    /**
     * Hit when the user selects the Other option as the reason for removal
     * Must manually add/remove the MDBCollapse classes or else it will cause ui bugs (MDB issue)
     */
    toggleOtherSelected = () => {
        clearInterval(this.state.actionTimeout);
        if (!this.state.working) this.setState({
            ...this.state,
            otherSelected: !this.state.otherSelected,
        }, () => {
            if (this.state.otherSelected){
                setTimeout(() => {
                    const outer = document.getElementById(`collapse-outer-remove-manifesto`);
                    const inner = document.getElementById(`collapse-inner-remove-manifesto`);
                    outer.style.height = `${Number(outer.style.height.split('px')[0]) + Number(inner.style.height.split('px')[0])}px`;
                    outer.classList.remove('show');
                    outer.classList.remove('collapse');
                    outer.classList.add('collapsing');
                    this.setState({
                        ...this.state,
                        actionTimeout: setTimeout(() => {
                            if (this.props.show){
                                outer.classList.add('show');
                                outer.classList.add('collapse');
                                outer.classList.remove('collapsing');
                            } 
                        }, 1000)
                    });
                }, 50);
            } else {
                const outer = document.getElementById(`collapse-outer-remove-manifesto`);
                const inner = document.getElementById(`collapse-inner-remove-manifesto`);
                outer.style.height = `${Number(outer.style.height.split('px')[0]) - Number(inner.style.height.split('px')[0])}px`;
                outer.classList.remove('show');
                outer.classList.remove('collapse');
                outer.classList.add('collapsing');
                this.setState({
                    ...this.state,
                    actionTimeout: setTimeout(() => {
                        if (this.props.show){
                            outer.classList.add('show');
                            outer.classList.add('collapse');
                            outer.classList.remove('collapsing');
                        }
                    }, 1000)
                });
            }
        });
    }  

    /**
     * 
     * @param {String} reason - Reason for the manifesto removal
     * 
     * Fired when the user selects a removal reason that is not Other
     * 
     * Works only if there isn't already a submission being sent
     * Validate inputs
     * Make request to server
     * Emit mod-action event via socket
     * Update manifesto in application state
     */
    submitSimple = reason => {
        if (!this.state.working) this.setState({
            ...this.state,
            removeReason: reason,
            working: true
        }, async () => {
            axios.post((h.checkJanny(this.props.userInfo)) ? '/support/remove/manifesto' : '/support/remove/manifesto-own', {
                reason: reason,
                id: this.props.imageInfo.commentSection,
                imageID: this.props.imageInfo.image_id
            }).then(res => this.setState({
                ...this.state,
                working: false,
                removeReason: ''
            }, () => {
                this.props.update_image(res.data.image);
                this.props.socket.emit('mod-action');
            })).catch(err => this.setState({
                ...this.state,
                working: false,
                removeReason: ''
            }, () => {
                console.log(err);
                alert('An error occurred. Please try again later.');
            }))
        });
    }

    render(){
        return (
            <MDBCollapse id="collapse-outer-remove-manifesto" show={this.props.show || this.state.working}>
                <MDBBtn size="sm" onClick={() => this.submitSimple('fed')} disabled={this.state.working} className="d-block ms-auto" color="info">{this.state.removeReason === 'fed' ? <Spinner size="sm" className="me-2"></Spinner> : <></>}Terrorism/Fed</MDBBtn>
                <MDBBtn size="sm" onClick={() => this.submitSimple('spam')} disabled={this.state.working} className="d-block ms-auto" color="info">{this.state.removeReason === 'spam' ? <Spinner size="sm" className="me-2"></Spinner> : <></>}Spam</MDBBtn>
                <MDBBtn size="sm" disabled={this.state.working} onClick={this.toggleOtherSelected} className="d-block ms-auto" color={this.state.otherSelected ? 'warning' : 'info'}>Other</MDBBtn>
                <MDBCollapse id="collapse-inner-remove-manifesto" show={this.state.otherSelected}>
                    <MDBValidation className="mt-2" id="remove-manifesto-form" onSubmit={this.submit}>
                        {fields.map(i => (
                            <MDBValidationItem key={i.id} className="pb-4" feedback={this.state.inputs.find(input => input.id === i.id).error} invalid={true} >
                                <MDBTextArea
                                    name={i.id}
                                    onChange={this.changeHandler}
                                    id={i.id + '-remove-manifesto-form'}
                                    label={i.text}
                                    size="lg"
                                    className={!this.state.inputs.find(input => input.id === i.id).invalid ? 'mb-0' : 0}
                                    onKeyPress={this.pressEnter}
                                />
                            </MDBValidationItem>
                        ))}
                    </MDBValidation>
                    <div className="d-flex pb-4">
                        <MDBBtn size="sm" onClick={this.submit} disabled={this.state.working} className="d-block" color="dark">{this.state.removeReason === 'other' ? <>
                            <Spinner size="sm" className="me-2"></Spinner>
                            Sending
                        </> : <>Submit</>}</MDBBtn>
                        {this.state.working ?
                        <></>:
                        <MDBBtn disabled={this.state.working} size="sm" onClick={this.toggleOtherSelected} className="d-block ms-2" color="light">Cancel</MDBBtn>}
                    </div>
                </MDBCollapse>
            </MDBCollapse>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
  }
  
  export default connect(mapStateToProps, { update_image })(Remove);
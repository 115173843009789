import React from 'react';
import { connect } from 'react-redux';
import { motion } from 'framer-motion';
import t from '../../../../utilities/transitions';
import h from '../../../../utilities/helpers';

const itemsPerPage = 40;

class ImagePage extends React.Component{
    constructor(props){
        super();
        /**
         * Page number is passed in as props, declared here, and never changes
         */
        this.page = props.imagePage;
    }

    sortImages = images => {
        /**
         * Receives the images array, then sorts the images based on the sort basis
         */
        switch(this.props.imageSortBy){
            case 'date':
                if (this.props.imageSortDirection === 'ascending') return images.sort((a, b) => a.image_id - b.image_id);
                else return images.sort((b, a) => a.image_id - b.image_id);
            case 'comments':
                if (this.props.imageSortDirection === 'ascending') return images.sort((a, b) => a.comments - b.comments);
                else return images.sort((b, a) => a.comments - b.comments);
            case 'views':
                if (this.props.imageSortDirection === 'ascending') return images.sort((a, b) => a.views - b.views);
                else return images.sort((b, a) => a.views - b.views);
            default: 
                return images;
        }
    }

    /**
     * 
     * @param {Number} image - ref Images.image_id
     */
    selectImage = image => {
        this.props.imageNav(`/image/${image.image_id}`);
    }

    render(){
        return (
            <motion.tbody style={{overflowX: 'hidden'}} transition={t.transition} exit={this.props.contentExit} animate={t.normalize} initial={this.props.contentEnter}>
                {this.sortImages(this.props.images_dash).filter((image, i) => {
                    if ((i >= (this.props.imagePage * itemsPerPage) - 1 - itemsPerPage) && (i < (this.props.imagePage * itemsPerPage))) return true;
                    else return false;
                }).map(image => (
                    <tr onClick={() => this.selectImage(image)} className="table-row-clickable">
                        <th scope="row">
                            <div style={{cursor: 'pointer'}} className="d-flex justify-content-center align-items-center square-4">
                                <div className="fit-images" style={{backgroundImage: `url("/api/image-id/${image.image_id}")`}}></div>
                            </div>
                        </th>
                        <td style={{verticalAlign: 'middle'}}>
                            <h5 className="m-0 text-center table-text-sm mobile-px-1"><span className="text-pkmn">{image.image_id}</span> - {h.makeDateHR(new Date(image.timestamp))}</h5>
                        </td>
                        <td style={{verticalAlign: 'middle'}}>
                            <h5 className="m-0 text-center table-text-sm mobile-px-1">{image.views}</h5>
                        </td>
                        <td style={{verticalAlign: 'middle'}}>
                            <h5 className="display-6 m-0 text-center table-text-sm mobile-px-1">{image.comments}</h5>
                        </td>
                    </tr>
                ))}
            </motion.tbody>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
  }
  
  export default connect(mapStateToProps, {  })(ImagePage);
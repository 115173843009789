export default [
    {
        id: 'username',
        text: 'Username',
        type: 'text'
    },
    {
        id: 'email',
        text: 'Email Address',
        type: 'text'
    },
    {
        id: 'comments',
        text: 'Comments',
        type: 'text'
    }
]
import React from 'react';
import { motion } from 'framer-motion';
import t from '../../utilities/transitions';
import {
    MDBBtn,
    MDBContainer
} from 'mdb-react-ui-kit';
import { connect } from 'react-redux';
import { route } from '../../redux/actions';
import h from '../../utilities/helpers';


class Header extends React.Component{
    constructor(){
        super();
        this.state = {
            ready: 0
        }
    }

    /**
     * This is dumb and needs to be replaced
     * 
     */
    checkLastImage = () => {
        if (typeof document !== undefined){
            let metadata = document.getElementById('p-metadata');
            if (metadata){
                if (metadata.textContent){
                    metadata = JSON.parse(metadata.textContent);
                    if (metadata.lastImage) return true;
                } else return false;
            } else return false; 
        } else return false;
    }


    /**
     * Wait for animations to complete before triggering next/back. Was crashing the app previously
     * 
     */
    next = () => {
        if (this.state.ready && Number(window.location.href.split('/image/')[1].split('#comment-')[0]) === this.props.imageInfo.image_id) this.props.next();
    }

    back = () => {
        if (this.state.ready && Number(window.location.href.split('/image/')[1].split('#comment-')[0]) === this.props.imageInfo.image_id) this.props.back();
    }

    animated = () => this.setState({
        ...this.state,
        ready: true
    });

    render(){
        return (
            <>
                {Number(this.props.match.params.id.split('#comment-')[0]) === 4506 ?
                <h1 className="text-center text-pkmn mt-4 image-numbers messages">Josiah Part 5</h1>:
                <h1 className="text-center text-pkmn mt-4 image-numbers messages">#{this.props.match.params.id.split('#comment-')[0]}</h1>}
                <MDBContainer style={{paddingLeft:'calc(((100vw - 100%) / 2) + var(--mdb-gutter-x,.75rem))'}}>
                    <div style={{width: '800px', maxWidth: '90%'}} className={`d-flex justify-content-${this.props.imageCount && this.props.imageNumber !== this.props.imageCount && !this.checkLastImage() ? 'between' : 'end'} mx-auto`}>
                        {this.props.imageCount && this.props.imageNumber !== this.props.imageCount && !this.checkLastImage() ?
                        <motion.span onAnimationComplete={this.animated} transition={t.transition} exit={this.props.leftButtonExit} animate={t.normalize} initial={this.props.leftButtonExit} >
                            <MDBBtn size="lg" onClick={this.next} style={{background: 'var(--mdb-indigo)'}} className="d-block image-buttons"><i className="fas fa-chevron-left me-2"></i><i className="fas fa-chevron-left image-button-mobile"></i><span className="image-button-text">Next</span></MDBBtn>
                        </motion.span> : <></>}
                        {Number(this.props.imageNumber) !== 1 ?
                        <motion.span onAnimationComplete={this.animated} transition={t.transition} exit={this.props.rightButtonExit} animate={t.normalize} initial={this.props.rightButtonExit}>
                            <MDBBtn size="lg" onClick={this.back} style={{background: 'var(--mdb-indigo)'}} className="d-block image-buttons"><span className="image-button-text">Previous</span><i className="fas fa-chevron-right image-button-mobile"></i><i className="fas fa-chevron-right ms-2"></i></MDBBtn>
                        </motion.span>
                        : <></>}
                    </div>
                </MDBContainer>
                {this.props.imageInfo.removed && this.props.loaded ? 
                <motion.div transition={t.transition} exit={t.fade_out_minimize} animate={t.normalize} initial={t.fade_out_minimize}>
                    <h4 className="text-center my-2 display-6 text-danger">Removed</h4>
                    <h5 className="text-center mb-2">{this.props.imageInfo.removed_reason === 'other' ? this.props.imageInfo.removed_details : h.getRemovedReason(this.props.imageInfo.removed_reason)}</h5>
                </motion.div> : <></>}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
}

export default connect(mapStateToProps, { route })(Header);
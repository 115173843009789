import React from 'react';
import { connect } from 'react-redux';
import { set_nsfw_modal, route, set_user } from '../redux/actions';
import {
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBBtn,
    MDBModalBody
} from 'mdb-react-ui-kit';
import axios from 'axios';

class NsfwModal extends React.Component{
    constructor(){
        super();
        this.state = {

        }
    }

    /**
     * TO DO: Make this one redux function
     * 
     * Fired when the user clicks "I am 18 years old or over"
     * Hides the model, signals to the server that the user has accepted, then updates the user's info in application state
     * 
     * 
     */
    accept = () => {
        this.props.set_nsfw_modal(false);
        this.acceptServer();
        this.props.set_user({
            ...this.props.userInfo,
            nsfwAccepted: true
        });
    }

    /**
     * Signals to the server that the user has indicated that they are 18 years of age or over.
     */
    acceptServer = () => axios.get('/auth/nsfw-accept').then(res => {
        this.props.set_user(res.data);
    }).catch(err => {
        console.log(err);
        setTimeout(this.acceptServer, 1500);
    });

    /**
     * TODO: Make this a single redux function
     * 
     * Fired when the user clicks "I am under 18 years old"
     * Navigates the user to the previous page, then hides the modal
     * 
     */
    reject = () => {
        let lastPage = this.props.historyStack[this.props.historyStack.length - 1];
        if (!lastPage) lastPage = '/';
        else lastPage = lastPage.split('null')[0];
        this.props.route(lastPage);
        this.props.set_nsfw_modal(false);
    }

    render(){
        return (
            <>
                {typeof window !== 'undefined' && window.navigator ?
                <MDBModal 
                    tabIndex='-1' 
                    show={this.props.showNsfwModal} 
                    setShow={() => undefined}
                    staticBackdrop
                    nonInvasive={true}
                    id="nsfw-modal"
                    className="position-absolute"
                >
                    <MDBModalDialog size='fullscreen'>
                        <MDBModalContent>
                            <MDBModalBody>
                                <h3 className="display-6 mt-5 text-center">Potentially adult content ahead. Please confirm that you are over 18.</h3>
                                <div className="mt-5 d-flex justify-content-center">
                                    <MDBBtn onClick={this.accept} className="d-block me-3" size="lg" color="dark">I am 18 years old or over</MDBBtn>
                                    <MDBBtn onClick={this.reject} className="d-block ms-3" style={{backgroundColor: '#607D8B'}} size="lg">I am under 18 years old</MDBBtn>
                                </div>
                            </MDBModalBody>
                        </MDBModalContent>
                    </MDBModalDialog>
                </MDBModal> : <></>}
            </>
            
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
}
  
export default connect(mapStateToProps, { set_nsfw_modal, route, set_user })(NsfwModal);
import NavBar from "./components/NavBar";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  Redirect,
} from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Home from "./pages/Home";
import React, { useEffect, useRef } from "react";
import { set_history, set_user } from "./redux/actions";
import { connect } from "react-redux";
import Contact from "./pages/Contact";
import About from "./pages/About";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import Resets from "./pages/Resets";
import Cancel from "./pages/Cancel";
import Message from "./pages/Message";
import CreateAccount from "./pages/CreateAccount";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import UploadProgress from "./components/notifications/UploadProgress";
import Image from "./pages/Image";
import AlreadyPostedToast from "./components/notifications/AlreadyPostedToast";
import AlreadyPostedMultipleToast from "./components/notifications/AlreadyPostedMultipleToast";
import Series from "./pages/Series";
import Browse from "./pages/Browse";
import { select_dashboard_item, route } from "./redux/actions";
import ImageToast from "./components/notifications/ImageToast";
import User from "./pages/User";
import NsfwModal from "./components/NsfwModal";
import Comment from "./pages/Comment";
import 卐 from "./pages/卐";

const App = (props) => {
  let location = useLocation();
  let history = useHistory();
  const imageRef = useRef(null);

  useEffect(() => {
    document.addEventListener("onautocomplete", (e) => {
      if (e.target.classList.contains("form-control"))
        e.target.classList.add("active");
    });
    props.set_history(location, history); // Set history into redux store as soon as app is rendered on the client
  }, []);

  /**
   * Currently using React Router 5
   * React Router 6 recently released; stupidly not made backwards-compatible and removes numerous features with no replacement
   */

  return (
    /**
     * AnimatePresence tells the child components to use framer motion page transitions
     * exitBeforeEnter flag makes sure the exit transition completes before the entrance on the next page initiates
     * The "key" in the Switch is necessary for animations to work properly. Needs to be unique on every page change, hence setting it to location.pathname
     */
    <>
      <NavBar history={history} location={location} />
      <UploadProgress />
      <AlreadyPostedToast />
      <AlreadyPostedMultipleToast />
      <div className="h-100 d-flex flex-column" ref={imageRef}>
        <NsfwModal />
        <ImageToast containerRef={imageRef} />
        <AnimatePresence exitBeforeEnter>
          <Switch location={location} key={location.pathname}>
            <Route exact path="/">
              <GoogleReCaptchaProvider
                reCaptchaKey={process.env.REACT_APP_CAPTCHA_KEY}
              >
                <Home history={history} location={location} />
              </GoogleReCaptchaProvider>
            </Route>
            <Route exact path="/jackiesingh">
              <GoogleReCaptchaProvider
                reCaptchaKey={process.env.REACT_APP_CAPTCHA_KEY}
              >
                <卐 history={history} location={location} />
              </GoogleReCaptchaProvider>
            </Route>
            <Route exact path="/info">
              <About history={history} location={location} />
            </Route>
            <Route exact path="/browse">
              <Browse history={history} location={location} />
            </Route>
            <Route exact path="/contact">
              <GoogleReCaptchaProvider
                reCaptchaKey={process.env.REACT_APP_CAPTCHA_KEY}
              >
                <Contact history={history} location={location} />
              </GoogleReCaptchaProvider>
            </Route>
            <Route exact path="/login">
              <GoogleReCaptchaProvider
                reCaptchaKey={process.env.REACT_APP_CAPTCHA_KEY}
              >
                <Login history={history} location={location} />
              </GoogleReCaptchaProvider>
            </Route>
            <Route exact path="/set-password/:id">
              <Resets history={history} location={location} />
            </Route>
            <Route exact path="/forgot-password">
              <GoogleReCaptchaProvider
                reCaptchaKey={process.env.REACT_APP_CAPTCHA_KEY}
              >
                <ForgotPassword history={history} location={location} />
              </GoogleReCaptchaProvider>
            </Route>
            <Route exact path="/user/:username">
              <GoogleReCaptchaProvider
                reCaptchaKey={process.env.REACT_APP_CAPTCHA_KEY}
              >
                <User history={history} location={location} />
              </GoogleReCaptchaProvider>
            </Route>
            <Route exact path="/u/:username">
              <GoogleReCaptchaProvider
                reCaptchaKey={process.env.REACT_APP_CAPTCHA_KEY}
              >
                <User history={history} location={location} />
              </GoogleReCaptchaProvider>
            </Route>
            <Route exact path="/series/:id">
              <Series history={history} location={location} />
            </Route>
            <Route exact path="/dashboard">
              <div className="fg-1">
                <Dashboard history={history} location={location} />
              </div>
            </Route>
            <Route exact path="/cancel/:id">
              <Cancel history={history} location={location} />
            </Route>
            <Route exact path="/check-email">
              <Message
                message="An email has been sent with a link to reset your password."
                history={history}
                location={location}
              />
            </Route>
            <Route exact path="/create-account">
              <GoogleReCaptchaProvider
                reCaptchaKey={process.env.REACT_APP_CAPTCHA_KEY}
              >
                <CreateAccount history={history} location={location} />
              </GoogleReCaptchaProvider>
            </Route>
            <Route exact path="/image/:id">
              <GoogleReCaptchaProvider
                reCaptchaKey={process.env.REACT_APP_CAPTCHA_KEY}
              >
                <Image history={history} location={location} />
              </GoogleReCaptchaProvider>
            </Route>
            <Route
              exact
              path="/images/:id"
              render={({ match }) => (
                <Redirect to={`/image/${match.params.id}`} />
              )}
            ></Route>
            <Route exact path="/comment/:id">
              <Comment history={history} location={location} />
            </Route>
            <Route
              exact
              path="/comments/:id"
              render={({ match }) => (
                <Redirect to={`/comments/${match.params.id}`} />
              )}
            ></Route>
            <Route exact path="/received">
              <Message
                message="Thank you. Your message has been received. We will reach out to you shortly."
                history={history}
                location={location}
              />
            </Route>
            <Route exact path="/fatrick">
              <Redirect to="/image/4506" />
            </Route>
            <Route exact path="/null">
              <Redirect to="/" />
            </Route>
            <Route path="*">
              <div className="flex-grow-1">
                <Message
                  message="Not Found"
                  history={history}
                  location={location}
                />
              </div>
            </Route>
          </Switch>
        </AnimatePresence>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  set_history,
  set_user,
  select_dashboard_item,
  route,
})(App);

import React from 'react';
import { connect } from 'react-redux';
import { motion } from 'framer-motion';
import t from '../utilities/transitions';
import { user_schema } from '../utilities/validations';
import axios from 'axios';
import { route, set_user } from '../redux/actions';
import {
    MDBValidation,
    MDBValidationItem,
    MDBInput,
    MDBBtn,
    MDBContainer,
    MDBTextArea,
    MDBSpinner,
    MDBCheckbox,
    MDBRipple
} from 'mdb-react-ui-kit';
import h from '../utilities/helpers';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import Spinner from '../components/Spinner';

/**
 * Create Account
 */

 const fields = [
    {
        text: 'Username',
        id: 'username',
        type: 'text'
    },
    {
        text: 'Email Address',
        id: 'email',
        type: 'text'
    },
    {
        text: 'Password',
        id: 'password1',
        type: 'password'
    },
    {
        text: 'Re-Enter Password',
        id: 'password2',
        type: 'password'
    },
    {
        text: 'Bio',
        id: 'bio',
        type: 'textarea'
    },
    {
        text: 'Avatar',
        id: 'avatar',
        type: 'text'
    }
];

const allowedExtensions = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'image/bmp', 'image/webp', 'image/svg+xml'];

class CreateAccount extends React.Component{
    constructor(){
        super();
        this.state = {
            /**
             * working: Boolean indicating whether the new account is in the process of being created
             * inputs: Array - The input data (values, errors, etc)
             * avatar: String, Path to the user's avatar
             * avatarName: String, "Click to Change", or the name of the file the user has selected, if any
             * avatarFile: false | File object that contains an avatar file that the user selected
             * avatarFetchTimeout - false | Timeout to fetch an avatar image if exists
             * imageOptions: Object - The image options that the user can toggle when uploading an image with the comment
             * fetchingAvatar: Boolean - Whether an avatar is in the process of being fetched from the server
             * avatarSrc: String, Path to avatar that was fetched after entering an image number into the avatar input
             */
            working: false,
            inputs: fields.map(field => ({
                id: field.id,
                error: '',
                invalid: true,
                value: (field.id === 'avatar') ? '7856' : ''
            })),
            avatar: '/thumbnails/blank.webp',
            avatarName: 'Click to Change',
            avatarFile: '',
            imageOptions: {
                commentsDisabled: false,
                nsfw: false,
                hidden: false
            },
            avatarFetchTimeout: '',
            fetchingAvatar: false,
            avatarSrc: '/api/image-id-full/7856',
        }
    }

    /**
     * Run blank changeHandler
     */
    componentDidMount(){
        this.changeHandler({
            target: {
                name: ''
            }
        });
    }

    componentDidUpdate(){
        h.floatLabels();
    }

    /**
     * 
     * @param {KeyboardEvent} e - Keyboard event triggered by text change in any of the text inputs
     * 
     * Sets the updated values into state
     * Validates the inputs
     * Updates the inputs with errors
     * Adds/removes custom validity as appropriate
     */
    changeHandler = e => {
        if (e.target.name === 'avatar') clearTimeout(this.state.avatarFetchTimeout);
        this.setState({
            ...this.state,
            inputs: this.state.inputs.map(input => {
                if (input.id === e.target.name) return {
                    ...input,
                    value: e.target.value
                }
                else return input
            }),
            fetchingAvatar: e.target.name === 'avatar',
            avatar: e.target.name === 'avatar' ? '/thumbnails/blank.webp' : this.state.avatar,
            avatarName: e.target.name === 'avatar' ? 'Click to Change' : this.state.avatarName,
            avatarFile: e.target.name === 'avatar' ? '' : this.state.avatarFile
        }, () => {
            const data = Object.fromEntries(this.state.inputs.map(input => [input.id, input.value]));
            try {
                user_schema.validateSync(data, {
                    abortEarly: false
                });
                this.setState({
                    ...this.state,
                    inputs: this.state.inputs.map(input => {
                        document.getElementById(input.id).setCustomValidity('');
                        return {
                            ...input,
                            invalid: false,
                            error: ''
                        }
                    }),
                    avatarFetchTimeout: e.target.name === 'avatar' ? setTimeout(this.fetchAvatar, 250) : this.state.avatarFetchTimeout
                });
            } catch(err){
                let errorsAdded = [];
                this.setState({
                    ...this.state,
                    inputs: this.state.inputs.map(input => {
                        if (err.inner.find(error => error.path === input.id) && errorsAdded.indexOf(input.id) === -1){
                            errorsAdded.push(input.id);
                            return {
                                ...input,
                                invalid: true,
                                error: err.inner.find(error => error.path === input.id).message
                            }
                        } 
                        else return {
                            ...input,
                            invalid: false,
                            error: ''
                        };
                    }),
                    avatarFetchTimeout: e.target.name === 'avatar' ? setTimeout(this.fetchAvatar, 250) : this.state.avatarFetchTimeout
                }, () => this.state.inputs.forEach(input => {
                    if (input.invalid) document.getElementById(input.id).setCustomValidity('hello');
                    else document.getElementById(input.id).setCustomValidity('');
                }));
            }
        });
    } 

    fetchAvatar = () => this.setState({
        ...this.state,
        fetchingAvatar: false,
        avatarSrc: `/api/image-id/${this.state.inputs.find(i => i.id === 'avatar').value}`
    });


    /**
     * Submit only if there isn't already a submission being sent
     * Validate inputs
     * Make request to server
     * Set user
     * Navigate to dashboard
     */
    submit = () => {

        document.getElementById('form').classList.add('was-validated');
        let invalidInputs = this.state.inputs.filter(input => input.invalid);
        invalidInputs.forEach(input => document.getElementById(input.id).setCustomValidity('hello'));
        if (!this.state.working && !invalidInputs.length) this.setState({
            ...this.state,
            working: true
        }, async () => {
            const data = Object.fromEntries(this.state.inputs.map(input => [input.id, input.value]));
            try {
                user_schema.validateSync(data, {
                    abortEarly: false
                });
                const captchaKey = await h.getRecaptcha(this.props.googleReCaptchaProps);
                const fd = new FormData();
                for ( const key in data ) {
                    fd.append(key, data[key]);
                }
                if (this.state.avatarFile){
                    fd.append('commentsDisabled', this.state.imageOptions.commentsDisabled);
                    fd.append('nsfw', this.state.imageOptions.nsfw);
                    fd.append('hidden', this.state.imageOptions.hidden);
                    fd.append('image', this.state.avatarFile, this.state.avatarName);
                }
                fd.append('captchaKey', captchaKey);
                axios.post('/auth/create_account', fd).then(res => {
                    if (res.data.error) this.setState({
                        ...this.state,
                        working: false
                    }, () => alert(res.data.error))
                    else {
                        this.props.set_user(res.data)
                        this.props.route('/dashboard')
                    }
                }).catch(err => this.setState({
                    ...this.state,
                    working: false
                }, () => {
                    console.log(err);
                    alert('An error occurred. Please try again later');
                }));
            } catch(err){
                this.setState({
                    ...this.state,
                    working: false
                }, () => {
                    console.log(err);
                    alert('An error occurred. Please try again later');
                });
            }
        });
    }

    /**
     * 
     * @param {CheckboxEvent} e 
     * 
     * Fired when the user toggles one of the options when uploading a file with their comment
     */
    changeImageOptions = e => this.setState({
        ...this.state,
        imageOptions: {
            ...this.state.imageOptions,
            [e.target.name]: e.target.checked
        }
    });

    /**
     * Fired when the user clicks their avatar
     * 
     * Creates a virtual file input
     * Adds a change event that sets the selected file into state
     * Appends to document body (necessary for iDevices and possibly others)
     * Clicks the input
     * Removes the input after the file is selected
     */
    selectAvatar = () => {
        let input = document.createElement('input');
        input.type = 'file';
        input.style.visibility = "hidden";
        input.style.position = "fixed";
        input.onchange = e => {
            clearTimeout(this.state.avatarFetchTimeout);
            let file = e.target.files[0];
            if (allowedExtensions.indexOf(file.type) !== -1){
                if (file.size < 15000001){
                    this.setState({
                        ...this.state,
                        avatarName: e.target.files[0].name,
                        avatarFile: e.target.files[0],
                        avatar: URL.createObjectURL(e.target.files[0]),
                        inputs: this.state.inputs.map(input => {
                            if (input.id === 'avatar') return {
                                ...input,
                                value: ''
                            } 
                            else return input
                        })
                    });
                } else {
                    alert('Your file is too big (Max: 15MB)');
                }
            } else {
                alert('Please select a valid image file (png, jpg, gif, bmp, webp)');
            }
        }
        input.click();
    }

    pressEnter = e => {
        /**
         * Submit the form if the user presses the enter key while in one of the inputs
         */
        if (e.key === 'Enter') this.submit();
    }

    render(){
        return (
            <motion.div className="py-4" transition={t.transition} exit={t.fade_out} animate={t.normalize} initial={t.fade_out}>
                <MDBContainer>
                    <h1 className="display-4 text-center">Create Account</h1>
                    <hr></hr>
                    <div className="mx-auto mt-2 form-containers">
                        <MDBValidation id="form" name="form" method="dialog">
                            {fields.filter(field => ['bio', 'avatar'].indexOf(field.id) === -1).map(i => (
                                <MDBValidationItem key={i.id} className="pb-4" feedback={this.state.inputs.find(input => input.id === i.id).error} invalid={true} >
                                    <MDBInput
                                        name={i.id}
                                        onChange={this.changeHandler}
                                        id={i.id}
                                        label={i.text}
                                        size="lg"
                                        className={!this.state.inputs.find(input => input.id === i.id).invalid ? 'mb-0' : 0}
                                        type={i.type}
                                        onKeyPress={this.pressEnter}
                                    />
                                </MDBValidationItem>
                            ))}
                            <MDBContainer fluid>
                                <div className="row">
                                    <div className="col-12 col-md-4">
                                        {this.state.fetchingAvatar ? 
                                        <div className="d-flex square-8 justify-content-center align-items-center mx-auto">
                                            <MDBSpinner grow size="lg" color="primary"></MDBSpinner>
                                        </div> : 
                                        <MDBRipple tag="span" rippleColor="primary">
                                            <div style={{cursor: 'pointer'}} onClick={this.selectAvatar} className="border border-dark p-2 d-flex justify-content-center align-items-center square-8 mx-auto">
                                                <div className="fit-images" style={{backgroundImage: `url("${(this.state.avatarFile) ? this.state.avatar : ((this.state.avatarSrc) ? `${this.state.avatarSrc}` : '/api/image-id-full/7856')}")`}}></div>
                                            </div>
                                        </MDBRipple>
                                        }
                                        <MDBInput
                                            name={'avatar'}
                                            onChange={this.changeHandler}
                                            id='avatar'
                                            label='avatar'
                                            min="1"
                                            type="number"
                                            className={`comment-inputs mt-4 ${!this.state.inputs.find(input => input.id === 'avatar').invalid ? 'mb-0' : 0}`}
                                            value={this.state.inputs.find(input => input.id === 'avatar').value}
                                        />
                                        {this.state.avatarFile ?
                                        <div className="mt-2">
                                            <MDBCheckbox 
                                                onChange={this.changeImageOptions} 
                                                checked={this.state.imageOptions.commentsDisabled} 
                                                className="check-commentsDisabled" 
                                                id="check-commentsDisabled-main" 
                                                name="commentsDisabled" 
                                                label="Disable Comments" 
                                                labelClass="mb-0"
                                            />
                                            <MDBCheckbox 
                                                className="check-nsfw" 
                                                id="check-nsfw-main" 
                                                onChange={this.changeImageOptions} 
                                                checked={this.state.imageOptions.nsfw} 
                                                name="nsfw" 
                                                label="Mark NSFW" 
                                                labelClass="mb-0"
                                            />
                                            <MDBCheckbox 
                                                className="check-hidden" 
                                                id="check-hidden-main" 
                                                onChange={this.changeImageOptions} 
                                                checked={this.state.imageOptions.hidden} 
                                                name="hidden" 
                                                label="Hide from Browse" 
                                                labelClass="mb-0"
                                            /> 
                                        </div> :
                                        <></>}
                                    </div>
                                    <div id="bio-create-account" className="col-12 col-md-8">
                                        <MDBValidationItem key={'bio'} className="pb-4" feedback={this.state.inputs.find(input => input.id === 'bio').error} invalid={true} >
                                            <MDBTextArea
                                                name={'bio'}
                                                onChange={this.changeHandler}
                                                id={'bio'}
                                                label={'Bio (Optional)'}
                                                size="lg"
                                                className={!this.state.inputs.find(input => input.id === 'bio').invalid ? 'mb-0' : 0}
                                            />
                                        </MDBValidationItem>
                                    </div>
                                </div>
                            </MDBContainer>
                        </MDBValidation>
                        {this.state.working ?
                        <MDBBtn color="success" size="lg" className="w-100 mt-4" block disabled><Spinner size='sm' className='me-2' />Working</MDBBtn> :
                        <MDBBtn color="success" onClick={this.submit} size="lg" block className="w-100 mt-4"><i className="fas fa-paper-plane me-2"></i>Submit</MDBBtn>}
                        <p onClick={() => this.props.route('/login')} className="mt-4 mb-0 text-center text-primary cursor-pointer">Already Have an Account? Login<i className="fas fa-sign-in-alt ms-2"></i></p>
                    </div>
                </MDBContainer>
            </motion.div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
  }
  
  export default connect(mapStateToProps, { route, set_user })(withGoogleReCaptcha(CreateAccount));
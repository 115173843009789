import React from 'react';
import { connect } from 'react-redux';
import {
    MDBCollapse,
    MDBValidation,
    MDBValidationItem,
    MDBTextArea,
    MDBBtn
} from 'mdb-react-ui-kit';
import h from '../../../utilities/helpers';
import { report_schema } from '../../../utilities/validations';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import axios from 'axios';
import Spinner from '../../../components/Spinner';

const fields = [
    {
        text: 'Explain',
        id: 'details',
        type: 'text'
    }
];

class Report extends React.Component{
    constructor(){
        super()
        this.state = {
            /**
             * inputs: Array - The input data (values, errors, etc)
             * otherSelected: Boolean - Whether the "Other" option is selected as the report reason (will open up a text input)
             * working: Boolean - Whether the comment is in the process of being reported
             * submitted: Boolean indicating whether a report has already been submitted
             * reportReason: String - The reason for the comment report
             * actionTimeout: false | Interval that adds collapse classes to MDBCollapse (fixes ui bug caused by collapse within collapse)
             */
            inputs: fields.map(field => ({
                id: field.id,
                error: '',
                invalid: true,
                value: ''
            })),
            otherSelected: false,
            working: false,
            submitted: false,
            reportReason: '',
            actionTimeout: ''
        }
    }

    /**
     * Run a blank input change
     */
    componentDidMount(){
        this.changeHandler({
            target: {
                name: ''
            }
        });
    }

    /**
     * 
     * @param {KeyboardEvent} e - Keyboard event triggered by text change in the Other text input
     * 
     * Sets the updated values into state
     * Validates the inputs
     * Updates the inputs with errors
     * Adds/removes custom validity as appropriate
     */
    changeHandler = e => this.setState({
        ...this.state,
        inputs: this.state.inputs.map(input => {
            if (input.id === e.target.name) return {
                ...input,
                value: e.target.value
            }
            else return input
        })
    }, () => {
        const data = Object.fromEntries(this.state.inputs.map(input => [input.id, input.value]));
        try {
            report_schema.validateSync(data, {
                abortEarly: false
            });
            this.setState({
                ...this.state,
                inputs: this.state.inputs.map(input => {
                    document.getElementById(input.id + `-report_comment_form-${this.props.commentID}`).setCustomValidity('');
                    return {
                        ...input,
                        invalid: false,
                        error: ''
                    }
                })
            });
        } catch(err){
            let errorsAdded = [];
            this.setState({
                ...this.state,
                inputs: this.state.inputs.map(input => {
                    if (err.inner.find(error => error.path === input.id) && errorsAdded.indexOf(input.id) === -1){
                        errorsAdded.push(input.id);
                        return {
                            ...input,
                            invalid: true,
                            error: err.inner.find(error => error.path === input.id).message
                        }
                    } 
                    else return {
                        ...input,
                        invalid: false,
                        error: ''
                    };
                })
            }, () => this.state.inputs.forEach(input => {
                if (input.invalid) document.getElementById(input.id + `-report_comment_form-${this.props.commentID}`).setCustomValidity('hello');
                else document.getElementById(input.id + `-report_comment_form-${this.props.commentID}`).setCustomValidity('');
            }));
        }
    });

    /**
     * Submit only if there isn't already a submission being sent
     * Validate inputs
     * Make request to server
     * Reset inputs
     */
    submit = () => {
        document.getElementById(`report_comment_form-${this.props.commentID}`).classList.add('was-validated');
        let invalidInputs = this.state.inputs.filter(input => input.invalid);
        invalidInputs.forEach(input => document.getElementById(input.id + `-report_comment_form-${this.props.commentID}`).setCustomValidity('hello'));
        if (!this.state.working && !invalidInputs.length) this.setState({
            ...this.state,
            working: true,
            reportReason: 'other'
        }, async () => {
            const data = Object.fromEntries(this.state.inputs.map(input => [input.id, input.value]));
            try {
                report_schema.validateSync(data, {
                    abortEarly: false
                });
                const captchaKey = await h.getRecaptcha(this.props.googleReCaptchaProps);
                const fd = new FormData();
                for ( const key in data ) {
                    fd.append(key, data[key]);
                }
                fd.append('captchaKey', captchaKey);
                fd.append('commentID', this.props.commentID);
                if (this.props.profile) fd.append('user', this.props.profileInfo.username);
                else fd.append('imageID', this.props.imageInfo.image_id);
                fd.append('reason', 'other');
                axios.post('/support/report/comment', fd).then(() => this.setState({
                    ...this.state,
                    working: false,
                    reportReason: ''
                }, () => this.props.setCommentReported(this.props.commentID))).catch(err => this.setState({
                    ...this.state,
                    working: false,
                    reportReason: ''
                }, () => {
                    console.log(err);
                    alert('An error occurred. Please try again later');
                }));
            } catch(err){
                console.log('error')
                this.setState({
                    ...this.state,
                    working: false,
                    reportReason: ''
                }, () => {
                    console.log(err);
                    alert('An error occurred. Please try again later');
                });
            }
        });
    }

    /**
     * Hit when the user selects the Other option as the reason for removal
     * Must manually add/remove the MDBCollapse classes or else it will cause ui bugs (MDB issue)
     */
    toggleOtherSelected = () => {
        clearInterval(this.state.actionTimeout);
        if (!this.state.working) this.setState({
            ...this.state,
            otherSelected: !this.state.otherSelected,
        }, () => {
            if (this.state.otherSelected){
                setTimeout(() => {
                    const outer = document.getElementById(`collapse-outer-comment-${this.props.commentID}`);
                    const inner = document.getElementById(`collapse-inner-comment-${this.props.commentID}`);
                    outer.style.height = `${Number(outer.style.height.split('px')[0]) + Number(inner.style.height.split('px')[0])}px`;
                    outer.classList.remove('show');
                    outer.classList.remove('collapse');
                    outer.classList.add('collapsing');
                    this.setState({
                        ...this.state,
                        actionTimeout: setTimeout(() => {
                            if (this.props.show){
                                outer.classList.add('show');
                                outer.classList.add('collapse');
                                outer.classList.remove('collapsing');
                            } 
                        }, 1000)
                    });
                }, 50);
            } else {
                const outer = document.getElementById(`collapse-outer-comment-${this.props.commentID}`);
                const inner = document.getElementById(`collapse-inner-comment-${this.props.commentID}`);
                outer.style.height = `${Number(outer.style.height.split('px')[0]) - Number(inner.style.height.split('px')[0])}px`;
                outer.classList.remove('show');
                outer.classList.remove('collapse');
                outer.classList.add('collapsing');
                this.setState({
                    ...this.state,
                    actionTimeout: setTimeout(() => {
                        if (this.props.show){
                            outer.classList.add('show');
                            outer.classList.add('collapse');
                            outer.classList.remove('collapsing');
                        }
                    }, 1000)
                });
            }
        });
    } 

    /**
     * 
     * @param {String} reason - Reason for the comment report
     * 
     * Fired when the user selects a report reason that is not Other
     * 
     * Works only if there isn't already a submission being sent
     * Validate inputs
     * Make request to server
     */
    submitSimple = reason => {
        if (!this.state.working) this.setState({
            ...this.state,
            reportReason: reason,
            working: true
        }, async () => {
            try {
                const captchaKey = await h.getRecaptcha(this.props.googleReCaptchaProps);
                let submission;
                if (this.props.profile) submission = {
                    reason: reason,
                    commentID: this.props.commentID,
                    user: this.props.profileInfo.username,
                    captchaKey: captchaKey
                }
                else submission = {
                    reason: reason,
                    commentID: this.props.commentID,
                    imageID: this.props.imageInfo.image_id,
                    captchaKey: captchaKey
                }
                axios.post('/support/report/comment', submission).then(() => this.setState({
                    ...this.state,
                    working: false,
                    reportReason: ''
                }, () => this.props.setCommentReported(this.props.commentID))).catch(err => this.setState({
                    ...this.state,
                    working: false,
                    reportReason: ''
                }, () => {
                    console.log(err);
                    alert('An error occurred. Please try again later.');
                }))
            } catch(err){
                console.log(err);
                this.setState({
                    ...this.state,
                    working: false,
                    reportReason: ''
                });
            }
        });
    }

    render(){
        return (
            <MDBCollapse id={`collapse-outer-comment-${this.props.commentID}`} show={this.props.show || this.state.working}>
                <MDBBtn size="sm" onClick={() => this.submitSimple('fed')} disabled={this.state.working} className="d-block ms-auto" color="info">{this.state.reportReason === 'fed' ? <Spinner size="sm" className="me-2"></Spinner> : <></>}Terrorism/Fed</MDBBtn>
                <MDBBtn size="sm" onClick={() => this.submitSimple('spam')} disabled={this.state.working} className="d-block ms-auto" color="info">{this.state.reportReason === 'spam' ? <Spinner size="sm" className="me-2"></Spinner> : <></>}Spam</MDBBtn>
                <MDBBtn size="sm" disabled={this.state.working} onClick={this.toggleOtherSelected} className="d-block ms-auto" color={this.state.otherSelected ? 'warning' : 'info'}>Other</MDBBtn>
                <MDBCollapse id={`collapse-inner-comment-${this.props.commentID}`} show={this.state.otherSelected}>
                    <MDBValidation className="mt-2" id={`report_comment_form-${this.props.commentID}`} name={`report_comment_form-${this.props.commentID}`} method="dialog">
                        {fields.map(i => (
                            <MDBValidationItem key={i.id} className="pb-2" feedback={this.state.inputs.find(input => input.id === i.id).error} invalid={true} >
                                <MDBTextArea
                                    name={i.id}
                                    onChange={this.changeHandler}
                                    id={i.id + `-report_comment_form-${this.props.commentID}`}
                                    label={i.text}
                                    size="lg"
                                    className={!this.state.inputs.find(input => input.id === i.id).invalid ? 'mb-0' : 0}
                                />
                            </MDBValidationItem>
                        ))}
                    </MDBValidation>
                    <div className="d-flex pb-4">
                        <MDBBtn size="sm" onClick={this.submit} disabled={this.state.working} className="d-block" color="dark">{this.state.reportReason === 'other' ? <>
                            <Spinner size="sm" className="me-2"></Spinner>
                            Sending
                        </> : <>Submit</>}</MDBBtn>
                        {this.state.working ?
                        <></>:
                        <MDBBtn size="sm" onClick={this.toggleOtherSelected} className="d-block ms-2" color="light">Cancel</MDBBtn>}
                    </div>
                </MDBCollapse>
            </MDBCollapse>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
  }
  
  export default connect(mapStateToProps, {  })(withGoogleReCaptcha(Report));
import React from 'react';
import { MDBDropdown, MDBDropdownMenu, MDBDropdownToggle, MDBDropdownItem, MDBTooltip } from 'mdb-react-ui-kit';

/**
 * 
 * @param {String} theme - The current CSS theme - "default" | "dark"
 * @returns 
 */
const getColor = theme => {
    switch(theme){
        case 'default':
            return "rgb(201, 191, 177, var(--mdb-bg-opacity))";
        case 'dark':
            return "rgb(171, 168, 168, var(--mdb-bg-opacity))";
        default:
            return "rgb(201, 191, 177, var(--mdb-bg-opacity))";
    }
}

/**
 * 
 * @param {Function} nav - The navigation function 
 * @param {Function} logout - The logout function 
 * @param {Object} userInfo - Users document 
 * @param {Boolean} loggingout - Whether the user is in the process of logging out 
 * @returns The dropdown menu on desktop screens (Tooltip with avatar - User clicks to open additional navigation and options)
 */
const DropdownMenuDesktop = ({ mobileNav, logout, userInfo }) => (
    /**
     * This is the dropdown menu on the right side of the page that appears only on the desktop when the user clicks their display picture
     */
    <MDBDropdown className="nav-item float-end me-4 navbar-user-desktop">
        <MDBDropdownToggle 
            style={{boxShadow: 'none'}} 
            color={(userInfo.userSettings && userInfo.userSettings.theme) ? getColor(userInfo.userSettings.theme) : "rgb(201, 191, 177, var(--mdb-bg-opacity))"} className={`nav-link links-generic rounded-pill`} id="navbarDropdownMenuLink"
        >
            <MDBTooltip 
                className="me-1" 
                wrapperClass="p-0" 
                wrapperProps={{ color: (userInfo.userSettings && userInfo.userSettings.theme) ? getColor(userInfo.userSettings.theme) : "rgb(201, 191, 177, var(--mdb-bg-opacity))" }} 
                placement='left' 
                title={userInfo.username}
            >
                <img className="nav-avatar" src={`/api/image-id/${userInfo.avatar}`}></img> 
            </MDBTooltip>
        </MDBDropdownToggle>
        <MDBDropdownMenu>
            <MDBDropdownItem onClick={() => mobileNav('/dashboard')} link ><p className="dropdown-items m-0">Dashboard</p></MDBDropdownItem>
            <MDBDropdownItem onClick={() => logout(true)} link ><p className="text-danger dropdown-items m-0">Logout</p></MDBDropdownItem>
        </MDBDropdownMenu>
    </MDBDropdown>
);

export default DropdownMenuDesktop;
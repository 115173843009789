import React from 'react';
import { connect } from 'react-redux';
import {
    MDBContainer,
    MDBBtn
} from 'mdb-react-ui-kit';
import { motion, AnimatePresence } from 'framer-motion';
import h from '../../utilities/helpers';
import t from '../../utilities/transitions';
import AddCommentForm from './comments/AddCommentForm'
import RemoveCommentSectionForm from './comments/RemoveCommentSectionForm';
import OpenCommentsModal from './comments/OpenCommentsModal';
import { Route, Switch, StaticRouter } from 'react-router-dom';
import CommentSection from './comments/CommentSection';

class Comments extends React.Component{
    constructor(){
        super();
        this.state = {
            /**
             * commentsLoaded: Boolean indicating whether the comments have been loaded (possibly unneccessary)
             * removeCommentSectionFormOpen: Boolean indicating whether the Remove Comment Section form is open
             * modalShown: Boolean indicating whether the modal used to confirm the removal of the comment section is shown
             */
            commentsLoaded: false,
            removeCommentSectionFormOpen: false,
            modalShown: false
        }
    }

    componentDidMount(){
        this.setState({
            ...this.state,
            commentsLoaded: true
        });
    }

    toggleRemoveCommentSectionForm = () => this.setState({
        ...this.state,
        removeCommentSectionFormOpen: !this.state.removeCommentSectionFormOpen
    });

    setShowModal = option => this.setState({
        ...this.state,
        modalShown: option
    });

    toggleShowModal = () => this.setState({
        ...this.state,
        modalShown: !this.state.modalShown
    });

    render(){
        return (
            <motion.div transition={t.transition} exit={this.props.commentExit} animate={t.normalize} initial={this.props.commentExit}>
                <MDBContainer fluid className="mt-4">
                    {this.props.imageInfo.comments_disabled ?
                    <>
                        <hr></hr>
                        {h.checkJanny(this.props.userInfo) || this.props.userInfo._id === this.props.imageInfo.userID ?
                        <>
                            <MDBBtn 
                                color="success"
                                onClick={this.toggleShowModal}
                            >Open Comment Section</MDBBtn>
                            <OpenCommentsModal 
                                modalShown={this.state.modalShown}
                                setShowModal={this.setShowModal}
                                toggleShowModal={this.toggleShowModal}
                                socket={this.props.socket}
                            />
                        </> : <></>}
                        <h2 className="display-6 text-center">Comments Disabled</h2>
                        {this.props.imageInfo.comments_disabled_details ? 
                        <h5 className="mt-2 text-center messages">{this.props.imageInfo.comments_disabled_details}</h5> : <></>}
                    </>
                     : 
                    <>
                        <h5>Comments ({h.numberWithCommas(this.props.imageInfo.comments.length)})</h5>
                        {h.checkJanny(this.props.userInfo) || this.props.userInfo._id === this.props.imageInfo.userID ?
                        <>
                            <MDBBtn 
                                color="danger"
                                onClick={this.toggleRemoveCommentSectionForm}
                            >Disable Comments</MDBBtn>
                            <RemoveCommentSectionForm
                            socket={this.props.socket}
                            toggleRemoveCommentSectionForm={this.toggleRemoveCommentSectionForm} 
                            show={this.state.removeCommentSectionFormOpen} />
                        </> : <></>}
                        <AddCommentForm
                            socket={this.props.socket}
                            googleReCaptchaProps={this.props.googleReCaptchaProps} 
                            setClickCommentID={click => this.clickCommentID = click}/>
                        {this.props.imageInfo.comments.length ? 
                        <StaticRouter location={`/${this.props.imageInfo.commentPage}`}>
                            <AnimatePresence exitBeforeEnter>
                                <Switch key={this.props.imageInfo.commentPage}>
                                    <Route exact path="/:id">
                                        <CommentSection clickCommentID={this.clickCommentID} key={this.props.imageInfo.commentPage} socket={this.props.socket} commentsLoaded={this.state.commentsLoaded}/>
                                    </Route>
                                </Switch>
                            </AnimatePresence>
                        </StaticRouter> :
                        <h2 className="display-6 text-center mt-4">No Comments Yet</h2>}
                    </>}
                </MDBContainer>
            </motion.div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
}

export default connect(mapStateToProps, {  })(Comments);
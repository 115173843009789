import React from 'react';
import { connect } from 'react-redux';
import { motion } from 'framer-motion';
import t from '../../../../utilities/transitions';
import h from '../../../../utilities/helpers';
import { 
    MDBBtn
} from 'mdb-react-ui-kit';
import Spinner from '../../../../components/Spinner';
import axios from 'axios';
import { set_max_report_pages, set_reports, set_max_mod_log_pages, set_mod_logs } from '../../../../redux/actions';

const itemsPerPage = 40;

class ReportPage extends React.Component{
    constructor(props){
        super();
        /**
         * this.page: Number, the mod log page that the user is currently on
         */
        this.page = props.reportPage;
        this.state = {
            /**
             * manifestosRemoving: Array - List of manifestos that are in the process of being removed
             * imagesRemoving: Array - List of images that are in the process of being removed
             * commentsRemoving: Array - List of comments that are in the process of being removed
             * dismissing: Array - List of reports that are in the process of being dismissed
             */
            manifestosRemoving: [],
            imagesRemoving: [],
            commentsRemoving: [],
            dismissing: []
        }
    }

    sortReports = reports => {
        /**
         * Receives the reports array, then sorts the reports based on the sort basis
         */
        switch(this.props.reportSortBy){
            case 'imageID':
                if (this.props.reportSortDirection === 'ascending') return reports.sort((a, b) => a.imageID - b.imageID);
                else return reports.sort((b, a) => a.imageID - b.imageID);
            case 'type':
                if (this.props.reportSortDirection === 'ascending') return reports.sort((a, b) => a.type.localeCompare(b.type));
                else return reports.sort((b, a) => a.type.localeCompare(b.type));
            case 'id':
                if (this.props.reportSortDirection === 'ascending') return reports.sort((a, b) => {
                    let aCompare = (a.type === 'comment') ? a.commentID : a.imageID;
                    let bCompare = (b.type === 'comment') ? b.commentID : b.imageID;
                    return aCompare - bCompare;
                });
                else return reports.sort((b, a) => {
                    let aCompare = (a.type === 'comment') ? a.commentID : a.imageID;
                    let bCompare = (b.type === 'comment') ? b.commentID : b.imageID;
                    return aCompare - bCompare;
                });
            case 'reason':
                if (this.props.reportSortDirection === 'ascending') return reports.sort((a, b) => (a.reason + a.details).localeCompare(b.reason + b.details));
                else return reports.sort((b, a) => (a.reason + a.details).localeCompare(b.reason + b.details));
            case 'timestamp':
                if (this.props.reportSortDirection === 'ascending') return reports.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
                else return reports.sort((b, a) => new Date(a.timestamp) - new Date(b.timestamp));
            default:
                return reports;
        }
    }

    /**
     * 
     * @param {Number} image ref Images.image_id
     */
    selectReport = image => {
        this.props.imageNav(`/image/${image.image_id}`);
    }

    /**
     * 
     * @param {Object} report - Reports document
     * 
     * Hit when the user clicks Remove Comment on a comment report
     * 
     * Removes the comment and report
     * Updates the report and mod log list, and number of report and mod log pages
     * 
     * TO DO: Do this with a single redux action
     */
    removeComment = report => {
        if (this.state.commentsRemoving.indexOf(report._id) === -1) this.setState({
            ...this.state,
            commentsRemoving: [
                ...this.state.commentsRemoving,
                report._id
            ]
        }, () => axios.post(`/support/remove/comment-report`, {
            reason: report.reason,
            details: report.details,
            commentID: report.commentID,
            imageID: report.imageID
        }).then(res => {
            this.props.set_max_report_pages(Math.ceil(res.data.reports.length / itemsPerPage));
            this.props.set_reports(res.data.reports);
            this.setState({
                ...this.state,
                commentsRemoving: this.state.commentsRemoving.filter(r => r !== report._id)
            }, () => {
                this.props.set_max_mod_log_pages(Math.ceil(res.data.modLogs.length / itemsPerPage));
                this.props.set_mod_logs(res.data.modLogs);
            });
        }).catch(err => this.setState({
            ...this.state,
            commentsRemoving: this.state.commentsRemoving.filter(r => r !== report._id)
        }, () => {
            console.log(err);
            alert('An error occurred. Please try again later.');
        })));
    }

    /**
     * 
     * @param {Object} report - Reports document
     * 
     * Hit when the user clicks Remove Image on a image report
     * 
     * Removes the image and report
     * Updates the report and mod log list, and number of report and mod log pages
     * 
     * TO DO: Do this with a single redux action
     */
    removeImage = report => {
        if (this.state.imagesRemoving.indexOf(report._id) === -1) this.setState({
            ...this.state,
            imagesRemoving: [
                ...this.state.imagesRemoving,
                report._id
            ]
        }, () => axios.post(`/support/remove/image-report`, {
            reason: report.reason,
            details: report.details,
            imageID: report.imageID
        }).then(res => {
            this.props.set_max_report_pages(Math.ceil(res.data.reports.length / itemsPerPage));
            this.props.set_reports(res.data.reports);
            this.setState({
                ...this.state,
                imagesRemoving: this.state.imagesRemoving.filter(r => r !== report._id)
            }, () => {
                this.props.set_max_mod_log_pages(Math.ceil(res.data.modLogs.length / itemsPerPage));
                this.props.set_mod_logs(res.data.modLogs);
            });
        }).catch(err => this.setState({
            ...this.state,
            imagesRemoving: this.state.imagesRemoving.filter(r => r !== report._id)
        }, () => {
            console.log(err);
            alert('An error occurred. Please try again later.');
        })));
    }

    /**
     * 
     * @param {Object} report - Reports document
     * 
     * Hit when the user clicks Remove Manifesto on a manifesto report
     * 
     * Removes the manifesto and report
     * Updates the report and mod log list, and number of report and mod log pages
     * 
     * TO DO: Do this with a single redux action
     */
    removeManifesto = report => {
        if (this.state.manifestosRemoving.indexOf(report._id) === -1) this.setState({
            ...this.state,
            manifestosRemoving: [
                ...this.state.manifestosRemoving,
                report._id
            ]
        }, () => axios.post(`/support/remove/manifesto-report`, {
            reason: report.reason,
            details: report.details,
            commentSection: report.commentSection,
            imageID: report.imageID
        }).then(res => {
            this.props.set_max_report_pages(Math.ceil(res.data.reports.length / itemsPerPage));
            this.props.set_reports(res.data.reports);
            this.setState({
                ...this.state,
                manifestosRemoving: this.state.manifestosRemoving.filter(r => r !== report._id)
            }, () => {
                this.props.set_max_mod_log_pages(Math.ceil(res.data.modLogs.length / itemsPerPage));
                this.props.set_mod_logs(res.data.modLogs);
            });
        }).catch(err => this.setState({
            ...this.state,
            manifestosRemoving: this.state.manifestosRemoving.filter(r => r !== report._id)
        }, () => {
            console.log(err);
            alert('An error occurred. Please try again later.');
        })));
    }

    /**
     * 
     * @param {Object} report Reports document
     * 
     * Fired when the user clicks Dismiss Report on a report
     * 
     * Removes the report
     * Updates the report list and page numbers
     * 
     * TODO: Do this with a single redux action
     */
    dismiss = report => {
        if (this.state.dismissing.indexOf(report._id) === -1) this.setState({
            ...this.state,
            dismissing: [
                ...this.state.dismissing,
                report._id
            ]
        }, () => axios.post('/support/report/dismiss', report).then(res => {
            this.props.set_max_report_pages(Math.ceil(res.data.reports.length / itemsPerPage));
            this.props.set_reports(res.data.reports);
            this.setState({
                ...this.state,
                dismissing: this.state.dismissing.filter(r => r !== report._id)
            });
        }).catch(err => this.setState({
            ...this.state,
            dismissing: this.state.dismissing.filter(r => r !== report._id)
        }, () => {
            console.log(err);
            alert('An error occurred. Please try again later.');
        })));
    }

    /**
     * 
     * @param {Object} action ModLogs document
     * 
     * Navigates to the content in question
     */
    contentNav = report => {
        if (report.image) this.props.imageNav(`/image/${report.imageID}#comment-${report.commentID}`);
        else this.props.imageNav(`/u/${report.user}#comment-${report.commentID}`);
    }

    render(){
        return (
            <motion.tbody style={{overflowX: 'hidden'}} transition={t.transition} exit={this.props.contentExit} animate={t.normalize} initial={this.props.contentEnter}>
                {this.sortReports(this.props.reports_dash).filter((report, i) => {
                    if ((i >= (this.props.reportPage * itemsPerPage) - 1 - itemsPerPage) && (i < (this.props.reportPage * itemsPerPage))) return true;
                    else return false;
                }).map(report => (
                    <tr>
                        <th className="mobile-px-1" style={{verticalAlign: 'middle'}} scope="row">
                            <div style={{cursor: 'pointer'}} className="d-flex justify-content-center align-items-center square-6 report-images">
                                <div className="fit-images" style={{backgroundImage: `url("${report.image ? `/thumbnails/${report.image}` : `/api/image-id/${report.userInfo.avatar}`}")`}}></div>
                            </div>
                        </th>
                        <td className="text-center table-text-sm mobile-px-1" style={{verticalAlign: 'middle'}}>
                            {report.type === 'comment' ?
                            <>
                                {report.image ? 'Image ' : 'Profile '}
                            </> : <></>
                            }
                            <span style={{textTransform: 'capitalize'}}>{report.type}</span>
                        </td>
                        <td className="text-center text-break table-text-sm mobile-px-1" style={{verticalAlign: 'middle'}}><span style={{textTransform: 'capitalize'}}>{report.reason}</span>{report.reason === 'other' ? ' / ' + report.details : ''}</td>
                        <td className="text-center table-text-sm mobile-px-1" style={{verticalAlign: 'middle'}}>{report.type === 'comment' ? report.commentID : report.imageID}</td>
                        <td className="text-center table-text-sm mobile-px-1" style={{verticalAlign: 'middle'}}>
                            {h.makeDateHR(new Date(report.timestamp))}
                            <br/>
                            {h.getTimeHR(new Date(report.timestamp))}
                        </td>
                        <td className="table-text-sm mobile-px-1 table-button-container" style={{verticalAlign: 'middle'}}>
                            {this.state.dismissing.indexOf(report._id) !== -1 ?
                            <MDBBtn disabled className="d-block mx-auto report-buttons" style={{background: '#607D8B'}}><Spinner size="sm" className="me-2" />Dismissing</MDBBtn> :
                            <MDBBtn onClick={() => this.dismiss(report)} className="d-block mx-auto report-buttons" style={{background: '#607D8B'}}>Dismiss</MDBBtn>}
                            {report.type === 'comment' ?
                            <>
                                {this.state.commentsRemoving.indexOf(report._id) !== -1 ?
                                <MDBBtn className="d-block mx-auto report-buttons mt-2" disabled color="dark"><Spinner size="sm" className="me-2" />Removing</MDBBtn>:
                                <MDBBtn onClick={() => this.removeComment(report)} color="dark" className="d-block mx-auto report-buttons mt-2">Remove Comment</MDBBtn>}
                                <MDBBtn color="primary" onClick={() => this.contentNav(report)} className="d-block mx-auto report-buttons mt-2">Visit<i className="fas fa-chevron-right ms-2"></i></MDBBtn>
                            </> : 
                            <>
                                {this.state.imagesRemoving.indexOf(report._id) !== -1 ?
                                <MDBBtn className="d-block mx-auto report-buttons mt-2" disabled color="dark"><Spinner size="sm" className="me-2" />Removing</MDBBtn> :
                                <MDBBtn onClick={() => this.removeImage(report)} color="dark" className="d-block mx-auto report-buttons mt-2">Remove Image</MDBBtn>}
                                {this.state.manifestosRemoving.indexOf(report._id) !== -1 ?
                                <MDBBtn className="d-block mx-auto report-buttons mt-2" disabled color="dark"><Spinner size="sm" className="me-2" />Removing</MDBBtn> :
                                <MDBBtn onClick={() => this.removeManifesto(report)} color="dark" className="d-block mx-auto report-buttons mt-2">Remove Manifesto</MDBBtn>}
                                <MDBBtn color="primary" onClick={() => this.props.imageNav(`/image/${report.imageID}`)} className="d-block mx-auto report-buttons mt-2">Visit<i className="fas fa-chevron-right ms-2"></i></MDBBtn>
                            </>}
                        </td>
                    </tr>
                ))}
            </motion.tbody>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
  }
  
  export default connect(mapStateToProps, { set_max_report_pages, set_reports, set_max_mod_log_pages, set_mod_logs })(ReportPage);